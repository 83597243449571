// const STAGE = process.env.STAGE
const FRONTEND_DOMAIN = process.env.FRONTEND_DOMAIN
const API_URL = process.env.API_URL
const AWS_ACCOUNT_ID = process.env.AWS_ACCOUNT_ID
const COGNITO_REGION = process.env.COGNITO_REGION
const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID
const COGNITO_APP_CLIENT_ID = process.env.COGNITO_APP_CLIENT_ID
const COGNITO_IDENTITY_POOL_ID = process.env.COGNITO_IDENTITY_POOL_ID
const AUTH_DOMAIN = process.env.AUTH_DOMAIN

const config = {
  awsAccountId: AWS_ACCOUNT_ID,
  cognito: {
    REGION: COGNITO_REGION,
    USER_POOL_ID: COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: COGNITO_IDENTITY_POOL_ID,
    DOMAIN_NAME: AUTH_DOMAIN,
    OAUTH_CALLBACK_URL:
      (/localhost/.test(FRONTEND_DOMAIN) ? 'http://' : 'https://') +
      FRONTEND_DOMAIN +
      '/callback',
    OAUTH_SIGNOUT_URL:
      (/localhost/.test(FRONTEND_DOMAIN) ? 'http://' : 'https://') +
      FRONTEND_DOMAIN +
      '/signout'
  },
  apiAccount: {
    URL: API_URL
  }
}
config.apiAccount.URL = API_URL

export default config
