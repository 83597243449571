import React, { useState, useEffect } from 'react'
import { getCurrentUser } from 'StratticApi/index'

const AdminContext = React.createContext()

const AdminContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ id: -1 })
  const hasPermission = (permission) => {
    if (currentUser.id === -1) {
      return false
    }

    return currentUser.role.permissions.includes(permission)
  }

  useEffect(() => {
    getCurrentUser().then((response) => {
      setCurrentUser(response)
    })
  }, [])
  return (
    <AdminContext.Provider value={{ currentUser, hasPermission }}>{children}</AdminContext.Provider>
  )
}

export { AdminContext, AdminContextProvider }
