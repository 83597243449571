export const downloadResponse = (url) => {
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    /* eslint-disable */
    const blobData = new Blob([url])
    /* eslint-enable */
    window.navigator.msSaveOrOpenBlob(blobData)
  } else {
    // chrome (before v71)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.setAttribute('href', url)
    document.body.appendChild(link)
    link.click()
    link.parentNode && link.parentNode.removeChild(link)
  }
}
