import {
  GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS,
  GET_ACCOUNT_ID_SUBSCRIPTIONS,
  VALIDATE_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_ACCOUNT_ID_SUBSCRIPTION,
  GET_ACCOUNT_ID_CONTACT_SUCCESS,
  UPDATE_AUTO_RENEW,
  UPDATE_AUTO_BILL,
  UPDATE_CDN_EDGE_LOCATIONS,
  UPDATE_CDN_EDGE_LOCATIONS_SUCCESS,
  UPDATE_CDN_EDGE_LOCATIONS_ERROR,
  UPDATE_SUBSCRIPTION_MAX_SITES,
  UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS,
  UPDATE_SUBSCRIPTION_MAX_SITES_ERROR,
  UPDATE_SUBSCRIPTION_BY_ID,
  UPDATE_SUBSCRIPTION_BY_ID_SUCCESS,
  UPDATE_SUBSCRIPTION_BY_ID_ERROR
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  updateCdnEdgeLocationsLoader: false,
  updateCdnEdgeLocationsSuccess: false,
  updateSubscriptionMaxSitesLoader: false,
  updateSubscriptionMaxSitesSuccess: false,
  updateSubscriptionLoader: false,
  updateSubscriptionSuccess: false,
  subscriptions: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNT_ID_SUBSCRIPTIONS:
      return {
        ...state,
        loader: true
      }
    case GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loader: false,
        subscriptions: action.payload
      }
    case VALIDATE_SUBSCRIPTION:
      return {
        ...state,
        subLoader: true
      }
    case VALIDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subLoader: false,
        validation: action.payload
      }
    case UPDATE_ACCOUNT_ID_SUBSCRIPTION:
    case UPDATE_AUTO_RENEW:
    case UPDATE_AUTO_BILL:
      return {
        ...state,
        updateSubLoader: true
      }
    case GET_ACCOUNT_ID_CONTACT_SUCCESS:
      return {
        ...state,
        updateSubLoader: false
      }
    case UPDATE_CDN_EDGE_LOCATIONS:
      return {
        ...state,
        updateCdnEdgeLocationsLoader: true,
        updateCdnEdgeLocationsSuccess: false
      }
    case UPDATE_CDN_EDGE_LOCATIONS_SUCCESS:
      return {
        ...state,
        updateCdnEdgeLocationsLoader: false,
        updateCdnEdgeLocationsSuccess: true
      }
    case UPDATE_CDN_EDGE_LOCATIONS_ERROR:
      return {
        ...state,
        updateCdnEdgeLocationsLoader: false
      }
    case UPDATE_SUBSCRIPTION_MAX_SITES:
      return {
        ...state,
        updateSubscriptionMaxSitesLoader: true,
        updateSubscriptionMaxSitesSuccess: false
      }
    case UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS:
      return {
        ...state,
        updateSubscriptionMaxSitesLoader: false,
        updateSubscriptionMaxSitesSuccess: true
      }
    case UPDATE_SUBSCRIPTION_MAX_SITES_ERROR:
      return {
        ...state,
        updateSubscriptionMaxSitesLoader: false,
        updateSubscriptionMaxSitesSuccess: false
      }
    case UPDATE_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        updateSubscriptionLoader: true,
        updateSubscriptionSuccess: false
      }
    case UPDATE_SUBSCRIPTION_BY_ID_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoader: false,
        updateSubscriptionSuccess: true
      }
    case UPDATE_SUBSCRIPTION_BY_ID_ERROR:
      return {
        ...state,
        updateSubscriptionLoader: false,
        updateSubscriptionSuccess: false
      }
    default:
      return state
  }
}
