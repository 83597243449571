import {
  GET_SITE_ID_BACKUPS,
  GET_SITE_ID_BACKUPS_SUCCESS,
  GET_SITE_ID_BACKUP_URL,
  RESTORE_SITE_BACKUP,
  TRIGGER_SITE_BACKUP,
  DELETE_SITE_BACKUP,
  SET_CONTAINER_UPTIME,
  GET_SITE_PUBLISHES,
  GET_SITE_PUBLISHES_SUCCESS,
  BACKUP_FROM_ZIP,
  BACKUP_FROM_ZIP_SUCCESS,
  UPDATE_SITE,
  UPDATE_SITE_DISTRIBUTION,
  REFRESH_STATUSCAKE,
  START_CONTAINER,
  STOP_CONTAINER,
  UPDATE_STATUSCAKE,
  GET_SITE_ID_BACKUP_URL_SUCCESS,
  IS_SITE_UP,
  UPDATE_SITE_NOTES,
  UPDATE_SITE_IP_WHITELIST,
  UPDATE_SITE_FIREWALL,
  CREATE_API_KEY,
  PUBLISH,
  CANCEL_SCRAPE_JOB_ID,
  UPDATE_SITE_EXTENDED,
  RESET_SITE_PASSWORD
} from 'constants/ActionTypes'

export const publish = (siteId, payload) => {
  return {
    type: PUBLISH,
    payload,
    siteId
  }
}

export const updateSiteIpWhitelist = (siteId, payload) => {
  return {
    type: UPDATE_SITE_IP_WHITELIST,
    payload,
    siteId
  }
}

export const updateSiteFirewall = (siteId, payload) => {
  return {
    type: UPDATE_SITE_FIREWALL,
    payload,
    siteId
  }
}

export const resetSitePassword = (siteId, payload) => {
  return {
    type: RESET_SITE_PASSWORD,
    payload,
    siteId
  }
}

export const restoreBackupFromZip = payload => {
  return {
    type: BACKUP_FROM_ZIP,
    payload
  }
}

export const restoreBackupFromZipSuccess = payload => {
  return {
    type: BACKUP_FROM_ZIP_SUCCESS,
    payload
  }
}

export const getSiteIdBackups = payload => {
  return {
    type: GET_SITE_ID_BACKUPS,
    payload
  }
}

export const getSiteIdBackupsSuccess = payload => {
  return {
    type: GET_SITE_ID_BACKUPS_SUCCESS,
    payload
  }
}

export const getSiteIdBackupUrl = payload => {
  return {
    type: GET_SITE_ID_BACKUP_URL,
    payload
  }
}

export const getSiteIdBackupUrlSuccess = backupURL => {
  return {
    type: GET_SITE_ID_BACKUP_URL_SUCCESS,
    backupURL
  }
}
export const restoreSiteIdBackup = (siteId, body) => {
  return {
    type: RESTORE_SITE_BACKUP,
    siteId,
    body
  }
}
export const triggerSiteIdBackup = payload => {
  return {
    type: TRIGGER_SITE_BACKUP,
    payload
  }
}

export const deleteBackup = payload => {
  return {
    type: DELETE_SITE_BACKUP,
    payload
  }
}

export const getSitePublishes = (payload, siteId) => {
  return {
    type: GET_SITE_PUBLISHES,
    payload,
    siteId
  }
}

export const getSitePublishesSuccess = (payload) => {
  return {
    type: GET_SITE_PUBLISHES_SUCCESS,
    payload
  }
}

export const setShutdown = (siteId, shutdownMinutes, shutdownReset) => {
  return {
    type: SET_CONTAINER_UPTIME,
    siteId,
    shutdownMinutes,
    shutdownReset
  }
}

export const updateSite = (siteId, body) => {
  return {
    type: UPDATE_SITE,
    siteId,
    body
  }
}

export const updateSiteExtended = (siteId, body) => {
  return {
    type: UPDATE_SITE_EXTENDED,
    siteId,
    body
  }
}

export const updateSiteNotes = (siteId, body) => {
  return {
    type: UPDATE_SITE_NOTES,
    siteId,
    body
  }
}

export const updateSiteDistribution = (siteId, distributionId, body) => {
  return {
    type: UPDATE_SITE_DISTRIBUTION,
    siteId,
    distributionId,
    body
  }
}

export const updateStatusCake = (distId, status) => {
  return {
    type: UPDATE_STATUSCAKE,
    distId,
    status
  }
}

export const refreshStatusCake = distId => {
  return {
    type: REFRESH_STATUSCAKE,
    distId
  }
}

export const startContainer = siteId => {
  return {
    type: START_CONTAINER,
    siteId
  }
}

export const stopContainer = siteId => {
  return {
    type: STOP_CONTAINER,
    siteId
  }
}

export const isSiteUp = (stagingUrl) => {
  return {
    type: IS_SITE_UP,
    stagingUrl
  }
}

export const createApiKey = (siteId, params) => {
  return {
    type: CREATE_API_KEY,
    siteId,
    params
  }
}

export const cancelScrapeJob = (scrapeJobId) => {
  return {
    type: CANCEL_SCRAPE_JOB_ID,
    scrapeJobId
  }
}
