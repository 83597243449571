import {
  ACCOUNT_UPDATE_TYPE,
  ACCOUNT_DELETE,
  UPDATE_ACCOUNT_NOTES
} from 'constants/ActionTypes'

export const updateAccountType = (accountId, payload) => {
  return {
    type: ACCOUNT_UPDATE_TYPE,
    payload,
    accountId
  }
}

export const updateAccountNotes = (accountId, payload) => {
  return {
    type: UPDATE_ACCOUNT_NOTES,
    accountId,
    payload
  }
}

export const deleteAccount = (accountId) => {
  return {
    type: ACCOUNT_DELETE,
    accountId
  }
}
