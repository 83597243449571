const errors = {
  error: 'An error has occurred.',
  too_many_users:
    'You cannot add any more users. Contact support to upgrade your plan.',
  invitation_exists: 'This invitation has already been sent',
  missing_invitation_id: 'Missing invitation ID',
  invalid_invitation: 'Invalid invitation',
  invalid_user: 'Invalid user',
  invalid_critical_contact: "Can't delete critical contact",
  invalid_permission_to_view_invitation: "can't view invitation",
  invalid_user_self: "Can't update own permissions",
  no_init_type: 'no initiation type',
  no_billing_cycle: 'no billing cycle',
  invalid_type: 'invalid type',
  missing_name_parameter: 'Missing name parameter',
  missing_price: 'Missing price',
  missing_currency_parameter: 'Missing currency parameter',
  invalid_input_value: 'Invalid input value',
  missing_add_on_allowances: 'Missing add-on allowances',
  add_on_not_found: 'addon not found',
  missing_email: 'Missing email for new user',
  missing_password: 'Missing password for new user',
  missing_attributes: 'Missing attributes for new user',
  invalid_email: "Can't delete logged in user",
  missing_invalidation_webhook: 'missing invalidationWebhook in body',
  invalid_webhook_url: 'invalid webhook url',
  invalidation_webhook_not_defined: 'no webhook assigned',
  no_custom_domain: 'no custom domain set',
  missing_domain_name: 'missing domainName in body',
  invalid_domain_name: 'invalid domain name',
  s3_sync_enabled: "can't configure domain with s3 sync enabled",
  missing_accept_root_domain: 'missing accept root domain',
  pending_certificate_exists: 'pending requested certificate',
  enabled_property_missing: 'Missing enabled property',
  invalid_form_id: 'invalid form id',
  attempt_delete_nonexistent_code: 'Attempt to delete nonexistent code',
  delete_failed: 'Delete failed',
  invitation_expired: 'Invitation expired',
  invalid_invitation_status: 'Invalid invitation status',
  missing_plan_group_id: 'Missing planGroupId',
  missing_promo_code_name: 'Missing name',
  missing_discount_type: 'Missing discount type (amountOff or percentageOff)',
  one_discount_type_allowed:
    'Only one discount type is allowed (amountOff or percentageOff)',
  name_too_short: 'Name needs a minimum of 3 chars',
  missing_publish_id: 'Missing publishId',
  invalid_publish_id: 'Invalid publishId',
  invalid_delete_confirmation:
    'Invalid confirmation. Please type DELETE to confirm deletion',
  failed_to_find_backup: 'Failed to find backup to delete',
  backup_already_deleted: 'Backup already deleted',
  missing_site_id: 'Missing siteId',
  failed_to_find_site: 'Failed to find site',
  backup_id_missing: 'No backup id provided',
  site_not_found: 'site was not found',
  from_day_not_valid: 'fromDay is not a valid date',
  to_day_not_valid: 'toDay is not a valid date',
  to_day_not_after_from_day: 'toDay must be after fromDay',
  month_not_provided: 'month not provided',
  year_not_provided: 'year not provided',
  publishing_disabled:
    'Publishing system is updating: will be available shortly',
  busy_scraping: 'Site busy scraping',
  invalid_request: 'set sftp and/or http to 1',
  busy_restoring: 'Site busy restoring',
  site_display_name_required: 'siteDisplayName is required',
  site_display_name_does_not_match: 'siteDisplayName does not match',
  zip_path_missing: 'Missing path to zip file',
  missing_param: 'missing param',
  backup_description_missing: 'Error: Backup description is missing',
  backup_description_too_short:
    'Error: Backup description must be at least 5 characters',
  missing_input: 'input parameters were not supplied',
  missing_account_id: 'Missing accountId',
  wrong_account_id: 'Wrong accountId',
  backup_not_found: 'No backup found',
  too_many_backups:
    'You are only allowed 200 backups on your site. Please contact support if you need more.',
  no_sites_for_metrics: 'no sites provided',
  missing_plugin_id: 'missing plugin id',
  invalid_request_all_sites_permissions:
    "Can't accept allSites and permissions",
  invalid_request_role_permissions: "Can't accept this role and permissions",
  missing_permission_site_id: 'Missing siteId in permission',
  invalid_permission_site_id: 'Invalid siteId in permission',
  missing_permission_role_type: 'Missing roleType in permission',
  invalid_permission_role_type: 'Invalid roleType in permission',
  invalid_permission_role_type_elevated:
    "Invalid roleType in permission: site permission can't be greater than account permission",
  invalid_role_type: 'Invalid roleType',
  invalid_user_role: 'Invalid user role',
  invalid_user_role_permissions: "Can't manage user with role owner",
  invalid_user_invitation_allsites:
    "Can't set all sites: user doesn't have all sites",
  code_generation_duplicate: 'Email already registered',
  code_generation_attempts_exceeded: 'Code generation attempts exceeded',
  create_secret_failed: 'Unknown error creating secret',
  create_config_map_failed: 'Unknown error creating config map',
  plan_group_creation_failed: 'planGroup creation failed',
  duplicate_plan_group_name: 'planGroup with the supplied name already exists',
  plan_group_not_found: 'planGroup not found',
  wrong_plan_group_id: 'wrong planGroupId',
  plan_creation_failed: 'Plan creation failed',
  promo_code_creation_failed: 'promoCode creation failed',
  duplicate_promo_code_name: 'Promo code with the supplied name already exists',
  wrong_deletion_type: "Wrong deletionType: must be 'soft' or 'hard'",
  cannot_charge_free_trial: 'Cannot charge a free trial subscription',
  missing_start_moment:
    'Cannot schedule a future subscription that is not in the future',
  invalid_start_moment:
    'Cannot schedule a future subscription that is not in the future',
  missing_processor_idempotency_key:
    'Cannot create a purchased subscription without a processorIdempotencyKey',
  no_current_subscription: 'No current subscription was found',
  cannot_upgrade_subscription_false_attempt:
    'cannot upgrade subscription because of false attempt',
  cannot_upgrade_subscription_payment_not_completed:
    'cannot upgrade subscription because the payment was not completed',
  client_secret_missing: 'missing clientSecret',
  unauthorized: 'Unauthorized Request',
  unauthorized_stripe_customer: 'unathorized stripe customer',
  invalid_intent_supplied: 'the supplied intent is not valid',
  unauthorized_client_secret: 'unathorized client_secret',
  payment_attempt_error:
    'an error has occured during an attempt to pay: please contact support',
  no_payment_intent_id: 'No paymentIntentId was provided',
  payment_intent_not_found: 'PaymentIntent not found',
  no_current_subscriptions: 'No current subscription was found',
  processor_idempotency_key_mismatch:
    "the current subscription's processorIdempotencyKey does not match",
  plan_id_mismatch: "subscription's plan ID does not match the incoming one",
  billing_cycle_id_mismatch:
    "subscription's billing does not match the incoming one",
  existing_account_card: 'this account already has an associated card',
  account_no_cards: 'no cards are associated with this account',
  plan_not_found: 'plan not found',
  invalid_billing_cycle: 'invalid billing cycle',
  processor_customer_id_exists: 'processorCustomerId already exists',
  invalid_account_cards_removal_failed:
    'failed to remove invalid cards from the account',
  invalid_item_type: 'invalid item type was provided for pagination iteration',
  invoice_item_not_associated_with_account:
    'invoiceItem not associated with accountId',
  invoice_not_associated_with_account: 'invoice not associated with accountId',
  no_payment_sources: 'no payment sources to charge from for this account',
  missing_parameter: 'missing siteDistributionId parameter',
  'distribution is busy updating': 'Updating site distribution',
  site_distribution_not_found: 'Site Distribution not found',
  site_distribution_busy_creating: 'Site Distribution not finished creating',
  paths_not_array: 'Specified paths are not an array',
  paths_non_selective_publish:
    'Paths can only be specified for Selective Publish',
  paths_list_format: 'Improperly formatted paths list',
  paths_not_strings: 'Paths must be strings',
  paths_start_slashes: 'Paths must start with forward slashes',
  no_paths_selective: 'No paths sent for Selective Publish',
  publish_id_error: 'Publish not found',
  account_not_allowed_to_charge_customer_manually:
    'this account is not allowed to charge a customer manually',
  distribution_being_created: 'distribution still in creation',
  site_creating: 'site still in creation',
  site_updating: 'site is busy updating',
  no_subscription_on_account: 'Account does not have a subscription',
  current_subscription_sites_limit_reached:
    'Current subscription sites limit has been reached',
  future_subscription_sites_limit_reached:
    'Adding this site pushes you past the limit of your future subscription. If you want to add a new site: please cancel your subscription change.',
  invalid_site_type: 'Invalid Site Type',
  account_id_missing: 'Missing accountId parameter',
  account_not_found: 'Account not found',
  error_setting_sftp_user: 'Failed to set SFTP name due to a collision',
  missing_bucket_name_param: 'missing bucketName param',
  invalid_bucket_name_param: 'invalid bucketName param',
  invalid_bucket_prefix_param: 'invalid bucketPrefix param',
  missing_domain_name_param: 'missing domainName param',
  custom_domain_enabled: "can't enable with custom domain",
  invalid_distribution_status:
    'StatusCake Monitoring not supported without custom domain or publishDomainOverride',
  site_status_not_active: 'site not in active status',
  site_start_failed: 'site failed to start',
  site_stop_failed: 'site failed to stop',
  missing_site_url_param: "Site URL can't be empty",
  site_url_contains_spaces: "Site URL can't contain spaces",
  site_url_too_short: 'Site URL is too short',
  site_url_too_long: 'Site URL is too long',
  site_url_ends_with_dash: 'Site name cannot end with a -',
  site_url_contains_invalid_characters:
    'Only lowercase letters, numbers and hyphens are allowed',
  site_url_taken: 'Site URL is already taken',
  missing_site_name_param: "Site Name can't be empty",
  site_name_is_empty: 'Site Name must not be an empty string',
  site_name_too_short: 'Site Name is too short',
  site_name_too_long: 'Site Name is too long',
  site_name_taken: 'Site Name is already taken',
  missing_fullname_param: "Full name can't be empty",
  fullname_too_short: 'Full name is too short',
  fullname_too_long: 'Full name is too long',
  fullname_cotains_numbers: "Full name can't contain numbers",
  fullname_contains_invalid_characters: 'Full name contains invalid characters',
  missing_account_name_param: "Company name can't be empty",
  company_name_too_short: 'Company name is too short',
  company_name_too_long: 'Company name is too long',
  company_name_contains_invalid_characters:
    'Company name contains invalid characters',
  missing_email_param: "Email can't be empty",
  email_invalid: 'Email is invalid',
  email_taken: 'Email is taken',
  missing_code_param: 'Missing code parameter',
  code_contains_invalid_characters: 'Code contains invalid characters',
  invalid_code: 'Invalid invitation code',
  invite_code_used: 'Invitation code is already used',
  no_custom_domain_temp_site: 'Cannot set custom domain on a temp site',
  no_active_plan: 'current subscription has no plan',
  no_custom_domain_allowed: 'current plan does not allow custom domains',
  invalid_addon_count: 'Invalid Add-On Count',
  future_subscription_already_exists:
    'Account already has a future subscription',
  subscription_name_too_short: 'A subscription name needs a minimum of 3 chars',
  subscription_name_too_long:
    'A subscription name must have a maximum of 45 chars',
  missing_account: 'Cannot create a subscription without an account',
  missing_plan: 'Cannot create a subscription without a plan',
  missing_options: 'Missing subscription options',
  wrong_promo_code_id: 'Wrong promoCodeId',
  missing_billing_cycle: 'no billingCycle provided for a non-free plan',
  missing_custom_price: 'no price for a custom non-free plan',
  missing_plan_id: 'Missing planId',
  wrong_plan_id: 'Wrong planId',
  missing_subscription_id: 'Missing subscriptionId',
  subscription_id_not_found: 'SubscriptionId not found',
  no_subscriptions_found: 'No subscriptions were found',
  payment_destroy_error: 'Error destroying payments',
  subscription_destroy_error: 'Error destroying subscriptions',
  proration_higher_than_new_plan_price:
    'prorated calculation is higher than the new plan price: please choose a different base subscription',
  plan_id_not_supplied: 'planId was not supplied',
  incorrect_billing_cycle: 'Incorrect billingCycle',
  error_no_subscription_for_account:
    'There seem to be a problem with your current account subscription: please contact support',
  future_subscription_exists:
    'Cannot change plan with a scheduled future subscription',
  error_custom_price_customer_cannot_self_change_plan:
    'Only Strattic Support Team can change a plan for customers with a custom plan',
  error_same_plan_same_billing_cycle:
    'cannot change to the same plan and the same billingCycle',
  no_future_subscription:
    'Account does not have a future subscription to activate',
  no_valid_future_subscription_found:
    'Failed to find valid future subscription',
  start_date_not_here_yet:
    'Cannot activate future subscription. StartDate has not arrived yet.',
  missing_transaction: 'Transaction required to create subscription',
  simultaneous_subscription_creation:
    'This subscription was created simultaneously with another subscription',
  cannot_renew_free_plan: 'Cannot renew auto-billing a free plan',
  cannot_chance_time_limited_plan_end_date:
    'Cannot update exipry date on a time-limited plan',
  account_subscription_mismatch:
    'Cannot cancel subscription. Subscription and account do not match.',
  account_expired:
    'Your trial has expired. To continue using Strattic, please contact your account owner.',
  email_not_owner: 'Email needs to belong to an owner on this account',
  site_already_backing_up: 'Site already has a backup in progress'
}
export default errors
