import React, { useState, useEffect } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { Hub, Auth } from 'aws-amplify'
import { Spin } from 'antd'
import { connect } from 'react-redux'

import {
  userSignInSuccess
} from 'appRedux/actions/Auth'

import './index.less'

const OauthCallbackPage = ({ userSignInSuccess, authUser }) => {
  const [redirect, setRedirect] = useState(null)
  const location = useLocation()
  const history = useHistory()

  function handleLinkedError (errorMessage) {
    const decodedError = decodeURIComponent(errorMessage)
    const regex = /.*linked:(.*):(.*).*/
    const match = decodedError.match(regex)
    if (match?.length > 1) {
      const providerName = match[1]
      // window.localStorage.setItem('inital-signup-provider', providerName)
      Auth.federatedSignIn({ provider: providerName })
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const error = queryParams.get('error_description')
    if (error) {
      handleLinkedError(error)
    }
    if (!location.search.length) {
      setRedirect('/')
    }
  }, [location])
  useEffect(() => {
    if (authUser) {
      history.push('/')
    }
  }, [authUser])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRedirect('/')
    }, 5000)

    const HUB_CHANNEL = 'auth'
    const hubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          clearTimeout(timeout)
          Hub.remove(HUB_CHANNEL, hubCallback)
          const session = await Auth.currentSession()
          window.localStorage.setItem('user_id', data.username)
          window.localStorage.setItem('id_token', session.idToken.jwtToken)
          userSignInSuccess(data.username)

          break
        }
        case 'signIn_failure': {
          const errorMessage = data?.message
          if (errorMessage) {
            handleLinkedError(errorMessage)
          } else {
            console.error('errorMessage', errorMessage)
          }
          break
        }
        default:
          console.log('event not handled', event)
      }
    }
    Hub.listen(HUB_CHANNEL, hubCallback)
    return () => {
      Hub.remove(HUB_CHANNEL, hubCallback)
      clearTimeout(timeout)
    }
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }
  return (
    <div className='spinContainer'>
      <Spin spinning size='large' className='spin' />
    </div>
  )
}
const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, authUser } = auth
  return { loader, alertMessage, authUser }
}

export default connect(mapStateToProps, {
  userSignInSuccess
})(OauthCallbackPage)
