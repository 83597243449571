import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Settings'
import Auth from './Auth'
import Data from './Data'
import Domain from './Domain'
import Users from './Users'
import Billing from './Billing'
import Sites from './Sites.js'
import Subscriptions from './Subscriptions'
import Payments from './Payments'
import Account from './Account'

const reducers = history => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  data: Data,
  domain: Domain,
  users: Users,
  billing: Billing,
  subscriptions: Subscriptions,
  sites: Sites,
  payments: Payments,
  account: Account
})

export default reducers
