import React, { Component } from 'react'
import { LockOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, message, Form } from 'antd'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess
} from 'appRedux/actions/Auth'
import CircularProgress from 'components/CircularProgress/index'

const FormItem = Form.Item

class ValidateMFA extends Component {
  async verify2fa (code) {
    try {
      const authUser = this.props.cognito2faUser
      const signInUser = await Auth.confirmSignIn(authUser, code, 'SOFTWARE_TOKEN_MFA')
      this.props.userSignInSuccess(signInUser.username)
      window.localStorage.setItem('user_id', signInUser.username)
      const session = await Auth.currentSession()
      window.localStorage.setItem('id_token', session.idToken.jwtToken)
      this.props.history.push('/accounts')
    } catch (error) {
      console.log('error', error)
      message.error(error.message)
      if (error.code === 'NotAuthorizedException') {
        this.props.history.push('/signin')
      }
    }
  }

  handleSubmit = (values) => {
    this.verify2fa(values.code)
  }

  render () {
    const { loader } = this.props
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 }
      }
    }
    return (
      <div className='gx-app-login-wrap'>
        <div className='gx-app-login-container'>
          <div className='gx-app-login-main-content'>
            <div className='gx-app-logo-content'>
              <div className='gx-app-logo-wid' />
              <div className='gx-app-logo'>
                <img alt='example' src={require('assets/images/logo-white.png').default} />
              </div>
            </div>
            <div className='gx-app-login-content'>
              <div style={{ textAlign: 'center' }}>
                <h2>Validate 2FA</h2>
                <p>Please enter your 2fa code.</p>
              </div>
              <Form onFinish={this.handleSubmit} className='gx-signin-form gx-form-row0'>
                <FormItem
                  {...formItemLayout}
                  name='code'
                  rules={[{ required: true, message: 'Please input your 2FA Code!' }]}
                >
                  <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} autoFocus type='number' placeholder='2FA Code' />
                </FormItem>

                <FormItem {...formItemLayout}>
                  <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%' }}>
                    Login
                  </Button>
                </FormItem>

              </Form>
            </div>

            {loader ? <div className='gx-loader-view'><CircularProgress /></div> : null}

          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, cognito2faUser } = auth
  return { loader, alertMessage, cognito2faUser }
}

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess
})(ValidateMFA)
