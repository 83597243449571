import React from 'react'
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Input, Form } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userSignInOkta

} from 'appRedux/actions/Auth'

import CircularProgress from 'components/CircularProgress/index'
import { handleAlertMessage, MESSAGE_TYPE } from 'appUtil/adminHelpers'

const FormItem = Form.Item

class SignIn extends React.Component {
  componentDidUpdate (prevProps) {
    const { alertMessage, hideMessage } = this.props
    const { alertMessage: prevMessage } = prevProps
    handleAlertMessage(MESSAGE_TYPE.ERROR, alertMessage, prevMessage, hideMessage)

    if (this.props.authUser !== null) {
      this.props.history.push('/')
    }
  }

  handleSubmit = (values) => {
    this.props.showAuthLoader()
    this.props.userSignIn(values)
  }

  handleSiginInWithOkta = () => {
    this.props.userSignInOkta()
  }

  render () {
    const { loader } = this.props
    return (
      <div className='gx-app-login-wrap'>
        <div className='gx-app-login-container'>
          <div className='gx-app-login-main-content'>
            <div className='gx-app-logo-content'>
              <div className='gx-app-logo'>
                <img alt='example' src={require('assets/images/logo-white.png').default} />
              </div>
            </div>

            <div className='gx-app-login-content'>
              <div className='gx-app-login-okta' style={{ marginBottom: '10px' }}>
                <Button type='primary' className='gx-mb-0 login-okta sl-okta' htmlType='submit' onClick={this.handleSiginInWithOkta}>
                  Sign In with Okta
                </Button>
                <br />
                Or
                <br />
              </div>
              <Form onFinish={this.handleSubmit} className='gx-signin-form gx-form-row0'>

                <FormItem
                  name='email'
                  rules={[{
                    required: true, message: 'The input is not valid E-mail!'
                  }]}
                >
                  <Input placeholder='Email' />
                </FormItem>
                <FormItem
                  name='password'
                  rules={
                    [{ required: true, message: 'Please input your Password!' }]
                  }
                >
                  <Input type='password' placeholder='Password' />
                </FormItem>
                <FormItem>
                  <Button type='primary' className='gx-mb-0 sl-signin-button' htmlType='submit'>
                    Sign In
                  </Button>

                </FormItem>
                <div>
                  <Link to='/resetpassword'>
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            </div>

            {loader &&
              <div className='gx-loader-view'>
                <CircularProgress />
              </div>}
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, authUser } = auth
  return { loader, alertMessage, authUser }
}

export default withRouter(connect(mapStateToProps, {
  userSignIn,
  userSignInOkta,
  hideMessage,
  showAuthLoader
})(SignIn))
