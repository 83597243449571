import {
  GET_SITE_ID_BACKUPS,
  GET_SITE_ID_BACKUPS_SUCCESS,
  GET_SITE_ID_BACKUPS_ERROR,
  GET_SITE_PUBLISHES_SUCCESS,
  GET_SITE_PUBLISHES,
  BACKUP_FROM_ZIP_SUCCESS,
  BACKUP_FROM_ZIP,
  UPDATE_SITE,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_EXTENDED,
  UPDATE_SITE_EXTENDED_SUCCESS,
  UPDATE_SITE_EXTENDED_ERROR,
  UPDATE_SITE_DISTRIBUTION,
  UPDATE_SITE_DISTRIBUTION_ERROR,
  UPDATE_SITE_DISTRIBUTION_SUCCESS,
  UPDATE_SITE_ERROR,
  REFRESH_STATUSCAKE,
  REFRESH_STATUSCAKE_ERROR,
  REFRESH_STATUSCAKE_SUCCESS,
  UPDATE_STATUSCAKE,
  UPDATE_STATUSCAKE_ERROR,
  UPDATE_STATUSCAKE_SUCCESS,
  GET_SITE_ID_BACKUP_URL,
  GET_BACKUP_URL_ERROR,
  GET_SITE_ID_BACKUP_URL_SUCCESS,
  IS_SITE_UP,
  START_CONTAINER,
  STOP_CONTAINER,
  IS_SITE_UP_SUCCESS,
  UPDATE_SITE_NOTES_ERROR,
  UPDATE_SITE_NOTES_SUCCESS,
  UPDATE_SITE_NOTES,
  UPDATE_SITE_IP_WHITELIST,
  UPDATE_SITE_IP_WHITELIST_SUCCESS,
  UPDATE_SITE_IP_WHITELIST_ERROR,
  UPDATE_SITE_FIREWALL,
  UPDATE_SITE_FIREWALL_SUCCESS,
  UPDATE_SITE_FIREWALL_ERROR,
  CREATE_API_KEY,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_ERROR,
  PUBLISH,
  PUBLISH_SUCCESS,
  PUBLISH_ERROR,
  CANCEL_SCRAPE_JOB_ID,
  CANCEL_SCRAPE_JOB_ID_SUCCESS,
  CANCEL_SCRAPE_JOB_ID_ERROR,
  RESET_SITE_PASSWORD_SUCCESS,
  RESET_SITE_PASSWORD,
  RESET_SITE_PASSWORD_ERROR
} from 'constants/ActionTypes'

const INIT_STATE = {
  backups: null,
  getPublishesLoader: false,
  restoreSuccess: null,
  restoreLoading: false,
  siteUpdateLoader: false,
  siteDistributionUpdateLoader: false,
  siteDistributionUpdateSuccess: false,
  statusCakeLoader: false,
  urlLoader: false,
  backupURL: null,
  backupURLError: null,
  updateSiteNotesSuccess: false,
  updateSiteIpWhitelistLoader: false,
  updateSiteIpWhitelistSuccess: false,
  updateSiteFirewallLoader: false,
  updateSiteFirewallSuccess: false,
  publishLoader: false,
  publishSuccess: false,
  cancelScrapeJobLoader: false,
  backupsLoader: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PUBLISH: {
      return {
        ...state,
        publishLoader: true,
        publishSuccess: false
      }
    }
    case PUBLISH_SUCCESS: {
      return {
        ...state,
        publishLoader: false,
        publishSuccess: true
      }
    }
    case PUBLISH_ERROR: {
      return {
        ...state,
        publishLoader: false,
        publishSuccess: false
      }
    }
    case CREATE_API_KEY: {
      return {
        ...state,
        createApiKeyLoader: true,
        createApiKeySuccess: false
      }
    }
    case CREATE_API_KEY_SUCCESS: {
      return {
        ...state,
        createApiKeyLoader: false,
        createApiKeySuccess: true
      }
    }
    case CREATE_API_KEY_ERROR: {
      return {
        ...state,
        createApiKeyLoader: false,
        createApiKeySuccess: false
      }
    }
    case UPDATE_SITE_IP_WHITELIST: {
      return {
        ...state,
        updateSiteIpWhitelistLoader: true,
        updateSiteIpWhitelistSuccess: false
      }
    }
    case UPDATE_SITE_IP_WHITELIST_SUCCESS: {
      return {
        ...state,
        updateSiteIpWhitelistLoader: false,
        updateSiteIpWhitelistSuccess: action.response
      }
    }
    case UPDATE_SITE_IP_WHITELIST_ERROR: {
      return {
        ...state,
        updateSiteIpWhitelistLoader: false,
        updateSiteIpWhitelistSuccess: false
      }
    }
    case UPDATE_SITE_FIREWALL: {
      return {
        ...state,
        updateSiteFirewallLoader: true,
        updateSiteFirewallSuccess: false
      }
    }
    case UPDATE_SITE_FIREWALL_SUCCESS: {
      return {
        ...state,
        updateSiteFirewallLoader: false,
        updateSiteFirewallSuccess: action.response
      }
    }
    case UPDATE_SITE_FIREWALL_ERROR: {
      return {
        ...state,
        updateSiteFirewallLoader: false,
        updateSiteFirewallSuccess: false
      }
    }
    case BACKUP_FROM_ZIP: {
      return {
        ...state,
        restoreLoading: true,
        restoreSuccess: null
      }
    }
    case BACKUP_FROM_ZIP_SUCCESS: {
      return {
        ...state,
        restoreSuccess: action.payload,
        restoreLoading: false
      }
    }
    case GET_SITE_ID_BACKUPS: {
      return {
        ...state,
        backupsLoader: true
      }
    }
    case GET_SITE_ID_BACKUPS_ERROR: {
      return {
        ...state,
        backupsLoader: false
      }
    }
    case GET_SITE_ID_BACKUPS_SUCCESS: {
      return {
        ...state,
        backups: action.payload,
        backupsLoader: false
      }
    }
    case GET_SITE_PUBLISHES: {
      return {
        ...state,
        getPublishesLoader: true
      }
    }
    case GET_SITE_PUBLISHES_SUCCESS: {
      return {
        ...state,
        publishHistory: action.payload,
        getPublishesLoader: false
      }
    }
    case UPDATE_SITE:
    case RESET_SITE_PASSWORD: {
      return {
        ...state,
        siteUpdateLoader: true
      }
    }
    case RESET_SITE_PASSWORD_SUCCESS:
    case RESET_SITE_PASSWORD_ERROR:
    case UPDATE_SITE_ERROR:
    case UPDATE_SITE_SUCCESS: {
      return {
        ...state,
        siteUpdateLoader: false
      }
    }
    case UPDATE_SITE_EXTENDED: {
      return {
        ...state,
        siteUpdateLoader: true
      }
    }
    case UPDATE_SITE_EXTENDED_ERROR:
    case UPDATE_SITE_EXTENDED_SUCCESS: {
      return {
        ...state,
        siteUpdateLoader: false
      }
    }
    case UPDATE_SITE_DISTRIBUTION: {
      return {
        ...state,
        siteDistributionUpdateLoader: true,
        siteDistributionUpdateSuccess: false
      }
    }
    case UPDATE_SITE_DISTRIBUTION_ERROR: {
      return {
        ...state,
        siteDistributionUpdateLoader: false,
        siteDistributionUpdateSuccess: false
      }
    }
    case UPDATE_SITE_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        siteDistributionUpdateLoader: false,
        siteDistributionUpdateSuccess: true
      }
    }
    case UPDATE_SITE_NOTES: {
      return {
        ...state,
        siteUpdateLoader: true,
        updateSiteNotesSuccess: false
      }
    }
    case UPDATE_SITE_NOTES_ERROR: {
      return {
        ...state,
        siteUpdateLoader: false
      }
    }
    case UPDATE_SITE_NOTES_SUCCESS: {
      return {
        ...state,
        siteUpdateLoader: false,
        updateSiteNotesSuccess: true
      }
    }
    case UPDATE_STATUSCAKE:
    case REFRESH_STATUSCAKE: {
      return {
        ...state,
        statusCakeLoader: true
      }
    }
    case UPDATE_STATUSCAKE_ERROR:
    case UPDATE_STATUSCAKE_SUCCESS:
    case REFRESH_STATUSCAKE_SUCCESS:
    case REFRESH_STATUSCAKE_ERROR: {
      return {
        ...state,
        statusCakeLoader: false
      }
    }
    case GET_SITE_ID_BACKUP_URL: {
      return {
        ...state,
        urlLoader: true,
        backupURL: null
      }
    }
    case GET_SITE_ID_BACKUP_URL_SUCCESS: {
      return {
        ...state,
        urlLoader: false,
        backupURL: action.backupURL
      }
    }
    case GET_BACKUP_URL_ERROR: {
      return {
        ...state,
        urlLoader: false,
        backupURLError: action.payload
      }
    }
    case IS_SITE_UP:
      return {
        ...state,
        healthCheckLoader: true
      }
    case START_CONTAINER:
    case STOP_CONTAINER:
    case IS_SITE_UP_SUCCESS:
      return {
        ...state,
        healthCheckLoader: false
      }
    case CANCEL_SCRAPE_JOB_ID:
      return {
        ...state,
        cancelScrapeJobSuccess: false,
        cancelScrapeJobLoader: true
      }
    case CANCEL_SCRAPE_JOB_ID_SUCCESS:
      return {
        ...state,
        cancelScrapeJobSuccess: true,
        cancelScrapeJobLoader: false
      }
    case CANCEL_SCRAPE_JOB_ID_ERROR:
      return {
        ...state,
        cancelScrapeJobLoader: false
      }
    default:
      return state
  }
}
