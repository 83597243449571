import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import {
  GET_ACCOUNT_ID_SITES,
  GET_ACCOUNT_ID_CONTACT,
  SET_ACCOUNT_TYPE,
  GET_METRICS,
  ADMIN_USER_DATA_REQUESTED,
  ADMIN_MFA_RESET
} from 'constants/ActionTypes'
import { getAccountIdSitesSuccess, getAccountIdContactSuccess, getMetricsSuccess, getAccountIdContact } from '../../appRedux/actions/Data'
import { showAuthMessage, userSignOut, showErrorCode } from '../../appRedux/actions/Auth'

async function getAccountSitesCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}/sites`)
}

async function getAccountContactCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}`)
}

async function getMetricsCall ({ siteId, body }) {
  // console.log(`making api call sites/${siteId}/metrics`, body)
  return await API.post('stratticAPI', `sites/${siteId}/metrics`, { body })
}
async function setAccountTypeCall (payload) {
  return await API.patch('stratticAPI', `accounts/${payload.accountId}/setTest`, { body: payload.body })
}

async function adminMFAResetCall (body) {
  return await API.post('stratticAPI', 'admin/users/resetMfa', { body })
}

function * adminMFAResetRequest ({ body }) {
  try {
    yield call(adminMFAResetCall, body)
    yield put({ type: ADMIN_USER_DATA_REQUESTED })
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
    console.error(error.response.data.message)
  }
}

function * getAccountSitesRequest ({ accountId }) {
  try {
    const getAccountSitesResponse = yield call(getAccountSitesCall, accountId)
    yield put(getAccountIdSitesSuccess(getAccountSitesResponse))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      yield put(showErrorCode(error.response.status))
      yield put(showAuthMessage(error.message))
      console.error(error.message)
    }
  }
}

function * getAccountContactRequest ({ accountId }) {
  try {
    const getAccountContactResponse = yield call(getAccountContactCall, accountId)
    yield put(getAccountIdContactSuccess(getAccountContactResponse))
  } catch (error) {
    yield put(showErrorCode(error.response.status))
    yield put(showAuthMessage(error.message))
  }
}

function * getMetricsRequest ({ payload }) {
  try {
    const getMetricsResponse = yield call(getMetricsCall, payload)
    yield put(getMetricsSuccess(getMetricsResponse))
  } catch (error) {
    console.error(error.message)
    yield put(showAuthMessage(error.message))
  }
}
function * setAccountTypeRequest ({ payload }) {
  try {
    const setAccountTypeResponse = yield call(setAccountTypeCall, payload)
    if (setAccountTypeResponse.result.success === 1) {
      yield put(getAccountIdContact(payload.accountId))
    }
  } catch (error) {
    console.error(error)
    yield put(showAuthMessage(error.message))
  }
}

export function * watchGetAccountSites () {
  yield takeEvery(GET_ACCOUNT_ID_SITES, getAccountSitesRequest)
}

export function * watchGetAccountContact () {
  yield takeEvery(GET_ACCOUNT_ID_CONTACT, getAccountContactRequest)
}

export function * watchGetMetrics () {
  yield takeEvery(GET_METRICS, getMetricsRequest)
}
export function * watchSetAccountType () {
  yield takeEvery(SET_ACCOUNT_TYPE, setAccountTypeRequest)
}

export function * watchAdminMFAReset () {
  yield takeLatest(ADMIN_MFA_RESET, adminMFAResetRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchGetAccountSites),
    fork(watchGetAccountContact),
    fork(watchGetMetrics),
    fork(watchSetAccountType),
    fork(watchAdminMFAReset)
  ])
}
