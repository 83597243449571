import React from 'react'
import ReactDOM from 'react-dom'

import NextApp from './NextApp'

import Amplify, { Auth } from 'aws-amplify'

import config from './config'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.DOMAIN_NAME,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: config.cognito.OAUTH_CALLBACK_URL,
      redirectSignOut: config.cognito.OAUTH_SIGNOUT_URL,
      responseType: 'code'
    }
  },

  API: {
    endpoints: [

      {
        name: 'stratticAPI',
        endpoint: config.apiAccount.URL,
        custom_header: async () => {
          return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
        }
      }
    ]
  }
})

ReactDOM.render(<NextApp />, document.getElementById('root'))
