import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_OKTA,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  SET_COGNITO_2FA_USER,
  ACCOUNTS_DATA_FETCH_REQUESTED,
  ACCOUNTS_DATA_FETCH_SUCCESS,
  SITES_DATA_FETCH_REQUESTED,
  SITES_DATA_FETCH_SUCCESS,
  SITE_DATA_FETCH_REQUESTED,
  SITE_DATA_FETCH_SUCCESS,
  SITE_DATA_FETCH_END,
  ADD_NEW_ACCOUNT,
  ADD_NEW_ACCOUNT_SUCCESS,
  LOG_SINGLE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_REQUEST,
  RESET_REQUESTED,
  SUBMIT_NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  RESET_CODE_SEND,
  CHANGE_PASSWORD,
  ADD_SITE_REQUESTED,
  ADD_SITE_SUCCESS,
  ADMIN_USER_DATA_SUCCESS,
  ADMIN_USER_DATA_REQUESTED,
  DELETE_SITE,
  DELETE_SITE_SUCCESS,
  ADMIN_SET_SELF_MFA,
  SHOW_ERROR_CODE,
  HIDE_ERROR_CODE,
  DELETE_ADMIN_ID
} from 'constants/ActionTypes'

export const userSignUp = (values) => {
  return {
    type: SIGNUP_USER,
    payload: values
  }
}
export const verifyUser = (values) => {
  return {
    type: VERIFY_USER,
    payload: values
  }
}

export const adminSetSelfMfa = () => {
  return {
    type: ADMIN_SET_SELF_MFA
  }
}

export const verifyUserSuccess = () => {
  return {
    type: VERIFY_USER_SUCCESS
  }
}

export const requestReset = (values) => {
  return {
    type: RESET_REQUESTED,
    payload: values
  }
}

export const submitNewPassword = (values) => {
  return {
    type: SUBMIT_NEW_PASSWORD,
    payload: values
  }
}

export const newPasswordSuccess = () => {
  return {
    type: NEW_PASSWORD_SUCCESS
  }
}

export const changePassword = (values) => {
  return {
    type: CHANGE_PASSWORD,
    payload: values
  }
}

export const resetCodeSend = () => {
  return {
    type: RESET_CODE_SEND
  }
}

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  }
}
export const userSignInOkta = () => {
  return {
    type: SIGNIN_USER_OKTA
  }
}
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  }
}
export const userSignUpSuccess = (newUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: newUser
  }
}

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
}
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  }
}

export const setCognito2FAUser = (payload) => {
  return {
    type: SET_COGNITO_2FA_USER,
    payload
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
}

export const showErrorCode = payload => {
  return {
    type: SHOW_ERROR_CODE,
    payload
  }
}

export const accountsDataFetch = payload => ({
  type: ACCOUNTS_DATA_FETCH_REQUESTED,
  payload
})

export const accountsDataFetchSuccess = (data) => {
  return {
    type: ACCOUNTS_DATA_FETCH_SUCCESS,
    payload: data
  }
}

export const sitesDataFetch = payload => {
  return {
    type: SITES_DATA_FETCH_REQUESTED,
    payload
  }
}

export const sitesDataFetchSuccess = (data) => {
  return {
    type: SITES_DATA_FETCH_SUCCESS,
    payload: data
  }
}

export const siteDataFetch = (siteId) => {
  return {
    type: SITE_DATA_FETCH_REQUESTED,
    siteId
  }
}

export const siteDataFetchEnd = () => {
  return {
    type: SITE_DATA_FETCH_END
  }
}

export const siteDataFetchSuccess = ({ result }) => {
  return {
    type: SITE_DATA_FETCH_SUCCESS,
    payload: result
  }
}
export const deleteSite = (siteId, confirm) => {
  return {
    type: DELETE_SITE,
    siteId,
    confirm
  }
}

export const deleteSiteSuccess = ({ result }) => {
  return {
    type: DELETE_SITE_SUCCESS,
    payload: result
  }
}

export const addNewAccount = (values) => {
  return {
    type: ADD_NEW_ACCOUNT,
    payload: values
  }
}
export const addNewAccountSuccess = () => {
  return {
    type: ADD_NEW_ACCOUNT_SUCCESS

  }
}

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  }
}

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  }
}

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
}

export const hideErrorCode = () => {
  return {
    type: HIDE_ERROR_CODE
  }
}

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  }
}

export const logSingleAccount = (record) => {
  return {
    type: LOG_SINGLE_ACCOUNT,
    payload: record
  }
}

export const updateSingleAccount = (id, values) => {
  return {
    type: UPDATE_ACCOUNT_REQUEST,
    id,
    values
  }
}
export const updateAccountSuccess = (id, values) => {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    id,
    values
  }
}

export const addSite = (values) => {
  return {
    type: ADD_SITE_REQUESTED,
    payload: values
  }
}

export const addSiteSuccess = (values) => {
  return {
    type: ADD_SITE_SUCCESS,
    payload: values
  }
}

export const adminUserDataFetch = () => {
  return {
    type: ADMIN_USER_DATA_REQUESTED
  }
}

export const adminUserDataSuccess = (data) => {
  return {
    type: ADMIN_USER_DATA_SUCCESS,
    payload: data
  }
}

export const deleteAdminId = adminEmail => {
  return {
    type: DELETE_ADMIN_ID,
    adminEmail
  }
}
