import React, { Component } from 'react'
import { LockOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, message, Form } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  adminSetSelfMfa
} from 'appRedux/actions/Auth'
import CircularProgress from 'components/CircularProgress/index'
import { Auth } from 'aws-amplify'
import QRCode from 'qrcode.react'
import './index.css'
const FormItem = Form.Item

class SetupMFA extends Component {
  constructor (props) {
    super(props)
    this.state = { mfaCode: null, username: null }
    this.getMfaCode()
  }

  async getMfaCode () {
    const authUser = await Auth.currentUserPoolUser()
    const code = await Auth.setupTOTP(authUser)
    const qrString = `otpauth://totp/Strattic:${authUser.attributes.email}?secret=${code}&issuer=strattic.com`
    this.setState({
      mfaCode: code,
      qrString
    })
  }

  async validateMfaCode (code) {
    const authUser = await Auth.currentUserPoolUser()
    this.setState({
      username: authUser.username
    })
    try {
      await Auth.verifyTotpToken(authUser, code)
      this.props.adminSetSelfMfa()
    } catch (error) {
      console.log('error', error)
      message.error(error.message)
    }
  }

  componentDidUpdate (prevProps) {
    const { adminSetSelfMfaSuccess } = this.props
    const { adminSetSelfMfaSuccess: adminSetSelfMfaSuccessPrev } = prevProps

    if (adminSetSelfMfaSuccess !== adminSetSelfMfaSuccessPrev) {
      if (adminSetSelfMfaSuccess) {
        this.props.userSignInSuccess(this.state.username)
        this.props.history.push('/')
      }
    }
  }

  handleSubmit = (values) => {
    this.validateMfaCode(values?.code).then((success) => {
    })
  }

  render () {
    const { loader } = this.props
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 }
      }
    }
    const { qrString } = this.state
    return (
      <div className='gx-app-login-wrap'>
        <div className='gx-app-login-container'>
          <div className='gx-app-login-main-content'>
            <div className='gx-app-logo-content'>
              <div className='gx-app-logo-wid' />
              <div className='gx-app-logo'>
                <img alt='example' src={require('assets/images/logo-white.png').default} />
              </div>
            </div>
            <div className='gx-app-login-content'>
              <div style={{ textAlign: 'center' }}>
                <h2>Setup 2FA!</h2>
                <p>Please scan the QA code.</p>
              </div>
              {qrString &&
                <div className='qrCodeWrapper'>
                  <QRCode className='qrCode' value={qrString} />
                </div>}

              <Form onFinish={this.handleSubmit} className='gx-signin-form gx-form-row0'>
                <FormItem
                  {...formItemLayout}
                  name='code'
                  rules={[{ required: true, message: 'Please input your 2FA code!' }]}
                >
                  <Input autoFocus prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='text' placeholder='2FA Code' />
                </FormItem>

                <FormItem {...formItemLayout}>
                  <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%' }}>
                    Setup
                  </Button>
                </FormItem>

              </Form>
            </div>

            {loader &&
              <div className='gx-loader-view'>
                <CircularProgress />
              </div>}

          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, adminSetSelfMfaSuccess } = auth
  return { loader, alertMessage, adminSetSelfMfaSuccess }
}

export default withRouter(connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  adminSetSelfMfa
})(SetupMFA))
