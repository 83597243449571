import {
  GET_ALL_USERS,
  ALL_USERS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_SITES,
  GET_USER_SITES_SUCCESS,
  GET_ACCOUNT_USERS,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_SIGNUP_CODE,
  GET_SIGNUP_CODE_SUCCESS,
  DELETE_USER,
  RESET_USER_MFA
} from 'constants/ActionTypes'
import { } from '../../constants/ActionTypes'

export const getAllUsers = payload => ({
  type: GET_ALL_USERS,
  payload
})

export const allUsersSuccess = usersList => ({
  type: ALL_USERS_SUCCESS,
  payload: usersList
})

export const getUser = userId => ({
  type: GET_USER,
  payload: userId
})

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user
})

export const deleteUser = userId => ({
  type: DELETE_USER,
  userId
})

export const getUserSites = userId => ({
  type: GET_USER_SITES,
  payload: userId
})

export const getUserSitesSuccess = userSites => ({
  type: GET_USER_SITES_SUCCESS,
  payload: userSites
})

export const getAccountUsers = accountId => ({
  type: GET_ACCOUNT_USERS,
  payload: accountId
})

export const getAccountUsersSuccess = accountUsers => ({
  type: GET_ACCOUNT_USERS_SUCCESS,
  payload: accountUsers
})

export const getSignupCode = () => ({
  type: GET_SIGNUP_CODE
})

export const getSignupCodeSuccess = (payload) => ({
  type: GET_SIGNUP_CODE_SUCCESS,
  payload
})

export const resetUserMfa = body => ({
  type: RESET_USER_MFA,
  body
})
