import React from 'react'
import { Layout, Row, Button, Card } from 'antd'

const { Content } = Layout

const Success = () => {
  return (
    <Layout>
      <Content style={{ background: '#fff' }}>
        <Row type='flex' justify='center'>
          <div style={{ background: '#fff', width: '50%' }}>
            <Card style={{ padding: 10, marginTop: '10%' }}>
              <div style={{ textAlign: 'center' }}>
                <h2>Your password has been reset successfully!</h2>
                <Button href='signin' type='primary'>Log in</Button>
              </div>
            </Card>
          </div>
        </Row>
      </Content>
    </Layout>
  )
}

export default Success
