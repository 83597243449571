import {
  GET_ACCOUNT_ID_CARDS,
  GET_ACCOUNT_ID_CARDS_SUCCESS,
  ACCOUNT_ID_CHARGE_SUBSCRIPTION,
  GET_ACCOUNT_ID_INVOICE,
  GET_ACCOUNT_ID_INVOICE_SUCCESS
} from '../../constants/ActionTypes'

export const getAccountIdCards = accountId => ({
  type: GET_ACCOUNT_ID_CARDS,
  accountId
})

export const getAccountIdCardsSuccess = payload => ({
  type: GET_ACCOUNT_ID_CARDS_SUCCESS,
  payload
})

export const accountIdChargeSubscription = accountId => ({
  type: ACCOUNT_ID_CHARGE_SUBSCRIPTION,
  accountId
})
export const getAccountIdInvoice = accountId => ({
  type: GET_ACCOUNT_ID_INVOICE,
  accountId
})

export const getAccountIdInvoiceSuccess = payload => ({
  type: GET_ACCOUNT_ID_INVOICE_SUCCESS,
  payload
})
