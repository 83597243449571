import React from 'react'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Input, Layout, Row, Button, Card, Form } from 'antd'
import { connect } from 'react-redux'
import {
  hideMessage,
  showAuthLoader,
  verifyUser
} from 'appRedux/actions/Auth'

import CircularProgress from 'components/CircularProgress/index'
import { MESSAGE_TYPE } from 'appUtil/adminHelpers'
import { Link } from 'react-router-dom'
import useAlertMessage from 'src/hooks/useAlertMessage'

const FormItem = Form.Item
const { Content } = Layout

const Validate = ({ loader, alertMessage, hideMessage, showAuthLoader, verifyUser }) => {
  const [form] = Form.useForm()
  useAlertMessage(null, alertMessage, hideMessage, (alertMessage) => {
    if (alertMessage === 'USER_VERIFIED') {
      return {
        type: MESSAGE_TYPE.SUCCESS,
        messageText: 'Successfully Verified'
      }
    }
    return {
      type: MESSAGE_TYPE.ERROR,
      messageText: alertMessage
    }
  })

  const handleSubmit = (values) => {
    showAuthLoader()
    verifyUser(values)
  }

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 }
    }
  }
  return (
    <div>
      <Layout>
        <Content style={{ background: '#fff' }}>
          <Row type='flex' justify='center'>

            <div style={{ background: '#fff', width: '50%' }}>

              <Card style={{ padding: 10, marginTop: '10%' }}>
                <div style={{ textAlign: 'center' }}>
                  <h2>Confirmation Code</h2>
                  <p>Please check your email for the code.</p>
                </div>
                <div>
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                  >
                    <FormItem
                      {...formItemLayout}
                      name='username'
                      rules={[
                        {
                          type: 'email', message: 'The input is not a valid E-mail!'
                        },
                        { required: true, message: 'Enter a valid email!' }
                      ]}
                    >
                      <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='email' placeholder='Email address' />
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      name='code'
                      rules={
                        [{ required: true, message: 'Please input your Confirmation Code!' }]
                      }
                    >
                      <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='number' placeholder='Confirmation Code' />
                    </FormItem>

                    <FormItem {...formItemLayout}>
                      <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%' }}>
                        VALIDATE ACCOUNT
                      </Button>

                    </FormItem>

                  </Form>
                  {loader &&
                    <div className='gx-loader-view'>
                      <CircularProgress />
                    </div>}
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Link to='/'>Back to login</Link>
                </div>
              </Card>
            </div>
          </Row>
        </Content>
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage } = auth
  return { loader, alertMessage }
}

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  verifyUser
})(Validate)
