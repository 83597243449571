import {
  all, call, fork, put, takeEvery, takeLatest
} from 'redux-saga/effects'
import { API } from 'aws-amplify'

import {
  GET_ALL_USERS,
  GET_USER,
  GET_USER_SITES,
  GET_ACCOUNT_USERS,
  GET_SIGNUP_CODE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESET_USER_MFA,
  RESET_USER_MFA_SUCCESS,
  RESET_USER_MFA_ERROR
} from 'constants/ActionTypes'

import {
  allUsersSuccess,
  getUserSuccess,
  getUserSitesSuccess,
  getAccountUsersSuccess,
  getSignupCodeSuccess
} from '../actions/Users'
import { showAuthMessage, showErrorCode, userSignOut } from '../actions/Auth'

async function getUsersCall (userId) {
  return await API.get('stratticAPI', `users/${userId}`)
}

async function deleteUserCall (userId) {
  return API.del('stratticAPI', `users/${userId}`)
}

async function resetUserMfaCall (body) {
  return API.post('stratticAPI', 'users/resetMfa', { body })
}

async function getUserSitesCall (userId) {
  return await API.get('stratticAPI', `users/${userId}/sites`)
}

async function allUsersCall ({ statuses, records, pageNum, order, byColumn, search }) {
  const byColumnParam = byColumn === undefined ? '' : `&byColumn=${byColumn}`
  const orderParam = order === undefined ? '' : `&order=${order}`
  const searchQuery = (search === undefined || search === '') ? '' : `&search=${encodeURIComponent(search)}`
  const pageNumParam = pageNum === undefined ? '' : `&page=${pageNum}`
  const statusesParam = statuses === undefined ? 'all' : statuses
  const recordsParam = records === undefined ? '' : `&records=${records}`
  const query = `users?statuses=${statusesParam}${recordsParam}${pageNumParam}${byColumnParam}${orderParam}${searchQuery}`
  return await API.get('stratticAPI', query)
}

async function accountUsersCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}/invitations`)
}

async function signupCodeCall () {
  return await API.post('stratticAPI', 'invitation-codes')
}

export function * getUserRequest ({ payload: userId }) {
  try {
    const users = yield call(getUsersCall, userId)
    yield put(getUserSuccess(users.result))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error)
      yield put(userSignOut())
    } else {
      yield put(showErrorCode(error.response.status))
      yield put(showAuthMessage(error.message))
      console.error(error)
    }
  }
}

export function * deleteUserRequest ({ userId }) {
  try {
    yield call(deleteUserCall, userId)
    yield put({ type: DELETE_USER_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_USER_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * resetUserMfaRequest ({ body }) {
  try {
    yield call(resetUserMfaCall, body)
    yield put({ type: RESET_USER_MFA_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: RESET_USER_MFA_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getUserSitesRequest ({ payload: userId }) {
  try {
    const userSites = yield call(getUserSitesCall, userId)
    yield put(getUserSitesSuccess(userSites.result))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error)
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.message))
      console.error(error)
    }
  }
}

export function * allUsersRequest ({ payload }) {
  try {
    const allUsers = yield call(allUsersCall, payload)
    yield put(allUsersSuccess(allUsers))
  } catch (error) {
    console.error(error)
  }
}

export function * accountUsersRequest ({ payload: accountId }) {
  try {
    const users = yield call(accountUsersCall, accountId)
    yield put(getAccountUsersSuccess(users.result))
  } catch (error) {
    console.error(error)
  }
}

export function * signupCodeRequest () {
  try {
    const signupCode = yield call(signupCodeCall)
    yield put(getSignupCodeSuccess(signupCode.result))
  } catch (error) {
    console.error(error)
  }
}

export function * watchDeleteUser () {
  yield takeLatest(DELETE_USER, deleteUserRequest)
}

export function * watchGetUsers () {
  yield takeEvery(GET_USER, getUserRequest)
}

export function * watchGetUserSites () {
  yield takeEvery(GET_USER_SITES, getUserSitesRequest)
}

export function * watchAllUsers () {
  yield takeEvery(GET_ALL_USERS, allUsersRequest)
}

export function * watchResetUserMfa () {
  yield takeEvery(RESET_USER_MFA, resetUserMfaRequest)
}

export function * watchGetAccountUsers () {
  yield takeEvery(GET_ACCOUNT_USERS, accountUsersRequest)
}

export function * watchGetSignupCode () {
  yield takeEvery(GET_SIGNUP_CODE, signupCodeRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchAllUsers),
    fork(watchGetUsers),
    fork(watchGetAccountUsers),
    fork(watchGetUserSites),
    fork(watchGetSignupCode),
    fork(watchDeleteUser),
    fork(watchResetUserMfa)
  ])
}
