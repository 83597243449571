import {
  GET_ALL_PLANS_SUCCESS,
  SET_NEW_PLAN_SUCCESS,
  GET_USER_ID_BILLING,
  GET_USER_ID_BILLING_SUCCESS,
  SET_ID_SUBSCRIPTION_SUCCESS,
  GET_ACCOUNT_ID_BILLING,
  GET_ACCOUNT_ID_BILLING_SUCCESS,
  GET_ALL_PLAN_FAMILIES_SUCCESS,
  GET_ALL_PROMO_CODES_SUCCESS,
  GET_ALL_ADD_ONS_SUCCESS,
  SET_NEW_ADD_ON_SUCCESS,
  GET_DEFAULT_PLANS,
  GET_DEFAULT_PLANS_SUCCESS,
  UPDATE_DEFAULT_PLANS,
  UPDATE_DEFAULT_PLANS_SUCCESS,
  UPDATE_DEFAULT_PLANS_ERROR
} from 'constants/ActionTypes'
import { GET_ACCOUNTID_CUSTOMER_SUCCESS, DELETE_ACCOUNTID_CUSTOMER_SUCCESS, CLEAR_BILLING } from '../../constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  allPlans: [],
  userBillingInfo: [],
  customer: null,
  defaultPlansIds: null,
  updateDefaultPlans: {
    loader: false,
    success: false
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEFAULT_PLANS:
      return {
        ...state,
        updateDefaultPlans: {
          loader: true
        }
      }
    case GET_DEFAULT_PLANS_SUCCESS:
      return {
        ...state,
        defaultPlansIds: action.payload,
        updateDefaultPlans: {
          loader: false
        }
      }
    case UPDATE_DEFAULT_PLANS:
      return {
        ...state,
        updateDefaultPlans: {
          loader: true,
          success: false
        }
      }
    case UPDATE_DEFAULT_PLANS_SUCCESS:
      return {
        ...state,
        updateDefaultPlans: {
          loader: false,
          success: true
        }
      }
    case UPDATE_DEFAULT_PLANS_ERROR:
      return {
        ...state,
        updateDefaultPlans: {
          loader: false,
          success: false
        }
      }
    case GET_USER_ID_BILLING:
    case GET_ACCOUNT_ID_BILLING:
    {
      return {
        ...state,
        loader: true
      }
    }
    case GET_ALL_PLANS_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        plansList: action.payload
      }
    }
    case GET_ALL_PLAN_FAMILIES_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        planFamilies: action.payload
      }
    }
    case GET_ALL_ADD_ONS_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        addOnsList: action.payload.result,
        addOnsCount: action.payload.count
      }
    }
    case SET_NEW_PLAN_SUCCESS:
    case SET_NEW_ADD_ON_SUCCESS:
    case SET_ID_SUBSCRIPTION_SUCCESS:
    {
      return {
        ...state,
        loader: false
      }
    }
    case GET_USER_ID_BILLING_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        userBillingInfo: action.payload
      }
    }
    case GET_ACCOUNT_ID_BILLING_SUCCESS:
    {
      return {
        ...state,
        accountBilling: action.payload
      }
    }
    case GET_ALL_PROMO_CODES_SUCCESS:
    {
      return {
        ...state,
        promoCodes: action.payload
      }
    }
    case GET_ACCOUNTID_CUSTOMER_SUCCESS:
    {
      return {
        ...state,
        customer: action.payload
      }
    }
    case DELETE_ACCOUNTID_CUSTOMER_SUCCESS:
    case CLEAR_BILLING:
    {
      return {
        ...state,
        loader: false,
        customer: null
      }
    }
    default:
      return state
  }
}
