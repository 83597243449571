import { all, call, fork, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import { API } from 'aws-amplify'

import {
  GET_SITE_ID_BACKUPS_ERROR,
  GET_SITE_ID_BACKUPS,
  GET_SITE_ID_BACKUP_URL,
  RESTORE_SITE_BACKUP,
  TRIGGER_SITE_BACKUP,
  DELETE_SITE_BACKUP,
  SET_CONTAINER_UPTIME,
  GET_SITE_PUBLISHES,
  BACKUP_FROM_ZIP,
  UPDATE_SITE,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_ERROR,
  UPDATE_SITE_DISTRIBUTION,
  UPDATE_SITE_DISTRIBUTION_ERROR,
  UPDATE_SITE_DISTRIBUTION_SUCCESS,
  REFRESH_STATUSCAKE,
  REFRESH_STATUSCAKE_ERROR,
  REFRESH_STATUSCAKE_SUCCESS,
  UPDATE_STATUSCAKE,
  UPDATE_STATUSCAKE_ERROR,
  UPDATE_STATUSCAKE_SUCCESS,
  GET_BACKUP_URL_ERROR,
  UPDATE_SITE_NOTES,
  UPDATE_SITE_NOTES_ERROR,
  UPDATE_SITE_NOTES_SUCCESS,
  UPDATE_SITE_IP_WHITELIST,
  UPDATE_SITE_IP_WHITELIST_SUCCESS,
  UPDATE_SITE_IP_WHITELIST_ERROR,
  UPDATE_SITE_FIREWALL,
  UPDATE_SITE_FIREWALL_SUCCESS,
  UPDATE_SITE_FIREWALL_ERROR,
  CREATE_API_KEY,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_ERROR,
  PUBLISH,
  PUBLISH_SUCCESS,
  PUBLISH_ERROR, UPDATE_SITE_EXTENDED_SUCCESS, UPDATE_SITE_EXTENDED_ERROR, UPDATE_SITE_EXTENDED, RESET_SITE_PASSWORD, RESET_SITE_PASSWORD_ERROR, RESET_SITE_PASSWORD_SUCCESS
} from 'constants/ActionTypes'
import {
  getSiteIdBackupsSuccess,
  getSitePublishesSuccess,
  restoreBackupFromZipSuccess,
  getSiteIdBackupUrlSuccess,
  updateSite
} from '../actions/Sites'
import { showAuthMessage, siteDataFetch } from '../actions/Auth'
import { downloadResponse } from 'appUtil/downloads'
import { formatQuery } from 'appUtil/utils'
import axios from 'axios'
import {
  CANCEL_SCRAPE_JOB_ID, CANCEL_SCRAPE_JOB_ID_ERROR, CANCEL_SCRAPE_JOB_ID_SUCCESS,
  IS_SITE_UP,
  IS_SITE_UP_SUCCESS,
  START_CONTAINER,
  START_CONTAINER_ERROR,
  STOP_CONTAINER,
  STOP_CONTAINER_ERROR
} from '../../constants/ActionTypes'

async function restoreBackupFromZipCall ({ siteId, zipPath, siteDisplayName }) {
  const body = {
    zipPath,
    siteDisplayName
  }
  return await API.post('stratticAPI', `sites/${siteId}/restore`, { body })
}

function * restoreBackupFromZipRequest ({ payload }) {
  try {
    const success = yield call(restoreBackupFromZipCall, payload)
    yield put(restoreBackupFromZipSuccess(success.result))
  } catch (error) {
    console.log(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}
async function getSiteIdBackupsCall (siteId) {
  return await API.get('stratticAPI', `sites/${siteId}/backups`)
}

function * getSiteIdBackupsRequest ({ payload }) {
  try {
    const backups = yield call(getSiteIdBackupsCall, payload)
    yield put(getSiteIdBackupsSuccess(backups.result))
  } catch (error) {
    console.log(error.response.data.message)
    yield put({ type: GET_SITE_ID_BACKUPS_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

async function publishCall (siteId, payload) {
  return API.post('stratticAPI', `sites/${siteId}/publish`, {
    body: payload
  })
}

async function createApiKeyCall (siteId, params) {
  return API.post('stratticAPI', `sites/${siteId}/api-keys`, {
    body: params
  })
}

export function * watchGetSiteIdBackups () {
  yield takeEvery(GET_SITE_ID_BACKUPS, getSiteIdBackupsRequest)
}

async function updateSiteIpWhitelistCall (siteId, payload) {
  return API.put('stratticAPI', `sites/${siteId}/ip-whitelist`, {
    body: payload
  })
}

async function updateSiteFirewallCall (siteId, payload) {
  return API.put('stratticAPI', `sites/${siteId}/firewall`, {
    body: payload
  })
}
async function resetSitePasswordCall (siteId, payload) {
  return API.post('stratticAPI', `sites/${siteId}/reset-pass`, {
    body: payload
  })
}

async function getSiteIdBackupUrlCall (siteId, backupId) {
  // for now we are hard coding the duration
  const duration = 86400
  return API.get('stratticAPI', `sites/${siteId}/backups/${backupId}/url?urlValidDuration=${duration}`)
}

function * getSiteIdBackupUrlRequest ({ payload }) {
  try {
    const response = yield call(getSiteIdBackupUrlCall, payload.siteId, payload.backupId)
    yield put(getSiteIdBackupUrlSuccess(response.result))
    if (payload.download) {
      downloadResponse(response.result)
    }
  } catch (error) {
    console.error(error.response.data.message)
    yield put({ type: GET_BACKUP_URL_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchGetSiteIdBackupUrl () {
  yield takeLatest(GET_SITE_ID_BACKUP_URL, getSiteIdBackupUrlRequest)
}

async function restoreSiteBackupCall (siteId, body) {
  const url = await API.post('stratticAPI', `sites/${siteId}/restore`, { body })
  return url
}

async function triggerSiteBackupCall (siteId, description, locked, hidden) {
  return await API.post('stratticAPI', `sites/${siteId}/triggerBackup`, {
    body: {
      description,
      locked,
      hidden
    }
  })
}

async function deleteSiteBackupCall (siteId, backupId) {
  return API.del('stratticAPI', `sites/${siteId}/backups/${backupId}`)
}

async function getSitePublishesCall (payload, siteId) {
  const searchQuery = formatQuery(payload)
  let query = `sites/${siteId}/publishes/`
  if (searchQuery !== '') {
    query += `?${searchQuery}`
  }
  return await API.get('stratticAPI', query)
}

// Only send the shutdown minutes if they are being set, for default the call is made without a body
async function setShutdownCall (siteId, shutdownMinutes, shutdownReset = true) {
  const path = `sites/${siteId}/shutdown`
  return API.post('stratticAPI', path, {
    body: {
      shutdownMinutes,
      shutdownReset
    }
  })
}

async function updateSiteCall (siteId, body) {
  const params = {}
  if (body) {
    params.body = body
  }

  return API.put('stratticAPI', `sites/${siteId}`, params)
}

async function updateSiteExtendedCall (siteId, body) {
  const params = {}
  if (body) {
    params.body = body
  }

  return API.post('stratticAPI', `sites/${siteId}/update-extended`, params)
}

async function updateSiteDistributionCall (distributionId, body) {
  const params = {}
  if (body) {
    params.body = body
  }
  return API.put('stratticAPI', `distributions/${distributionId}`, params)
}

async function updateStatusCakeCall (distId, status) {
  return API.post('stratticAPI', `distributions/${distId}/statusCake`, {
    body: status
  })
}

async function refreshStatusCakeCall (distId) {
  return API.post('stratticAPI', `distributions/${distId}/statusCakeRefresh`)
}

async function startContainerCall (siteId) {
  return API.post('stratticAPI', `sites/${siteId}/start`)
}

async function stopContainerCall (siteId) {
  return API.post('stratticAPI', `sites/${siteId}/stop`)
}

async function updateSiteNotesCall (siteId, body) {
  const params = {}
  if (body) {
    params.body = body
  }
  return API.put('stratticAPI', `sites/${siteId}/notes`, params)
}

async function isSiteUpCall (stagingUrl) {
  return axios.get(`https://${stagingUrl}/health.php`)
}

async function cancelScrapeJobIdCall (scrapeJobId) {
  return API.post('stratticAPI', `scrapejob/${scrapeJobId}/cancel`)
}

function * cancelScrapeJobIdRequest ({ scrapeJobId }) {
  try {
    yield call(cancelScrapeJobIdCall, scrapeJobId)
    yield put({ type: CANCEL_SCRAPE_JOB_ID_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: CANCEL_SCRAPE_JOB_ID_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * isSiteUpRequest ({ stagingUrl }) {
  try {
    yield call(isSiteUpCall, stagingUrl)
    yield put({ type: IS_SITE_UP_SUCCESS })
  } catch (error) {
    console.error(error)
    yield delay(1000)
    yield put({ type: IS_SITE_UP, stagingUrl })
  }
}

function * stopContainerRequest ({ siteId }) {
  try {
    yield call(stopContainerCall, siteId)
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error.response.data.message)
    yield put({ type: STOP_CONTAINER_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * publishRequest ({ siteId, payload }) {
  try {
    const response = yield call(publishCall, siteId, payload)
    yield put({ type: PUBLISH_SUCCESS, response })
  } catch (error) {
    console.error(error)
    yield put({ type: PUBLISH_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * createApiKeyRequest ({ siteId, params }) {
  try {
    const response = yield call(createApiKeyCall, siteId, params)
    yield put(siteDataFetch(siteId))
    yield put({ type: CREATE_API_KEY_SUCCESS, response })
  } catch (error) {
    console.error(error)
    yield put({ type: CREATE_API_KEY_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSiteIpWhitelistRequest ({ siteId, payload }) {
  try {
    const response = yield call(updateSiteIpWhitelistCall, siteId, payload)
    yield put({ type: UPDATE_SITE_IP_WHITELIST_SUCCESS, response })
    yield put(updateSite(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_IP_WHITELIST_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}
function * resetSitePasswordRequest ({ siteId, payload }) {
  try {
    const response = yield call(resetSitePasswordCall, siteId, payload)
    yield put({ type: RESET_SITE_PASSWORD_SUCCESS, response })
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: RESET_SITE_PASSWORD_ERROR })
    const messageType = Object.keys(payload)?.[0]
    yield put(showAuthMessage(`${messageType === 'http' ? 'HTTP Auth' : 'SFTP'} password failed to reset. Please try again`))
  }
}
function * updateSiteFirewallRequest ({ siteId, payload }) {
  try {
    const response = yield call(updateSiteFirewallCall, siteId, payload)
    yield put({ type: UPDATE_SITE_FIREWALL_SUCCESS, response })
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_FIREWALL_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * startContainerRequest ({ siteId }) {
  try {
    yield call(startContainerCall, siteId)
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error.response.data.message)
    yield put({ type: START_CONTAINER_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * refreshStatusCakeRequest ({ distId }) {
  try {
    yield call(refreshStatusCakeCall, distId)
    yield put({ type: REFRESH_STATUSCAKE_SUCCESS })
  } catch (error) {
    console.error(error.response.data.message)
    yield put({ type: REFRESH_STATUSCAKE_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateStatusCakeRequest ({ distId, status }) {
  try {
    yield call(updateStatusCakeCall, distId, status)
    yield put({ type: UPDATE_STATUSCAKE_SUCCESS })
  } catch (error) {
    console.error(error.response.data.message)
    yield put({ type: UPDATE_STATUSCAKE_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSiteRequest ({ siteId, body }) {
  try {
    yield call(updateSiteCall, siteId, body)
    yield put(siteDataFetch(siteId))
    yield put({ type: UPDATE_SITE_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSiteExtendedRequest ({ siteId, body }) {
  try {
    yield call(updateSiteExtendedCall, siteId, body)
    yield put(siteDataFetch(siteId))
    yield put({ type: UPDATE_SITE_EXTENDED_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_EXTENDED_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSiteNotesRequest ({ siteId, body }) {
  try {
    yield call(updateSiteNotesCall, siteId, body)
    yield put({ type: UPDATE_SITE_NOTES_SUCCESS })
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_NOTES_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSiteDistributionRequest ({ siteId, distributionId, body }) {
  try {
    yield call(updateSiteDistributionCall, distributionId, body)
    yield put(siteDataFetch(siteId))
    yield put({ type: UPDATE_SITE_DISTRIBUTION_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SITE_DISTRIBUTION_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * setShutdownRequest ({ siteId, shutdownMinutes, shutdownReset }) {
  try {
    yield call(setShutdownCall, siteId, shutdownMinutes, shutdownReset)
  } catch (error) {
    console.log(error)
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * getSitePublishesRequest ({ payload, siteId }) {
  try {
    const publishHistory = yield call(getSitePublishesCall, payload, siteId)
    yield put(getSitePublishesSuccess(publishHistory))
  } catch (error) {
    console.log(error)
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * restoreSiteBackupRequest ({ siteId, body }) {
  try {
    yield call(restoreSiteBackupCall, siteId, body)
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.log(error)
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * triggerSiteBackupRequest ({ payload }) {
  try {
    yield call(triggerSiteBackupCall, payload.siteId, payload.description, payload.locked, payload.hidden)
    yield call(getSiteIdBackupsRequest, { payload: payload.siteId })
    yield put(showAuthMessage('BACKUP_QUEUED'))
  } catch (error) {
    console.log(error)
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * deleteSiteBackupRequest ({ payload }) {
  try {
    yield call(deleteSiteBackupCall, payload.siteId, payload.backupId)
    yield call(getSiteIdBackupsRequest, { payload: payload.siteId })
    yield put(showAuthMessage('BACKUP_DELETED'))
  } catch (error) {
    console.error(error)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchIsSiteUp () {
  yield takeLatest(IS_SITE_UP, isSiteUpRequest)
}

export function * watchStartContainer () {
  yield takeLatest(START_CONTAINER, startContainerRequest)
}

export function * watchStopContainer () {
  yield takeLatest(STOP_CONTAINER, stopContainerRequest)
}

export function * watchRestoreBackupFromZip () {
  yield takeLatest(BACKUP_FROM_ZIP, restoreBackupFromZipRequest)
}

export function * watchGetSitePublishes () {
  yield takeEvery(GET_SITE_PUBLISHES, getSitePublishesRequest)
}

export function * watchRestoreSiteBackup () {
  yield takeLatest(RESTORE_SITE_BACKUP, restoreSiteBackupRequest)
}

export function * watchTriggerSiteBackup () {
  yield takeLatest(TRIGGER_SITE_BACKUP, triggerSiteBackupRequest)
}

export function * watchDeleteSiteBackup () {
  yield takeLatest(DELETE_SITE_BACKUP, deleteSiteBackupRequest)
}

export function * watchSetShutdown () {
  yield takeLatest(SET_CONTAINER_UPTIME, setShutdownRequest)
}

export function * watchUpdateSite () {
  yield takeLatest(UPDATE_SITE, updateSiteRequest)
}

export function * watchUpdateSiteExtended () {
  yield takeLatest(UPDATE_SITE_EXTENDED, updateSiteExtendedRequest)
}

export function * watchUpdateSiteDistribution () {
  yield takeLatest(UPDATE_SITE_DISTRIBUTION, updateSiteDistributionRequest)
}

export function * watchUpdateStatusCake () {
  yield takeLatest(UPDATE_STATUSCAKE, updateStatusCakeRequest)
}

export function * watchRefreshStatusCake () {
  yield takeLatest(REFRESH_STATUSCAKE, refreshStatusCakeRequest)
}

export function * watchUpdateSiteNotes () {
  yield takeLatest(UPDATE_SITE_NOTES, updateSiteNotesRequest)
}

export function * watchUpdateSiteIpWhitelist () {
  yield takeLatest(UPDATE_SITE_IP_WHITELIST, updateSiteIpWhitelistRequest)
}

export function * watchUpdateSiteFirewall () {
  yield takeLatest(UPDATE_SITE_FIREWALL, updateSiteFirewallRequest)
}

export function * watchPublish () {
  yield takeLatest(PUBLISH, publishRequest)
}
export function * watchCreateApiKey () {
  yield takeLatest(CREATE_API_KEY, createApiKeyRequest)
}

export function * watchCancelScrapeJob () {
  yield takeLatest(CANCEL_SCRAPE_JOB_ID, cancelScrapeJobIdRequest)
}
export function * watchResetSitePassword () {
  yield takeLatest(RESET_SITE_PASSWORD, resetSitePasswordRequest)
}
export default function * rootSaga () {
  yield all([
    fork(watchCancelScrapeJob),
    fork(watchPublish),
    fork(watchCreateApiKey),
    fork(watchUpdateSiteIpWhitelist),
    fork(watchUpdateSiteFirewall),
    fork(watchGetSiteIdBackups),
    fork(watchGetSiteIdBackupUrl),
    fork(watchRestoreSiteBackup),
    fork(watchTriggerSiteBackup),
    fork(watchDeleteSiteBackup),
    fork(watchSetShutdown),
    fork(watchGetSitePublishes),
    fork(watchRestoreBackupFromZip),
    fork(watchUpdateSite),
    fork(watchUpdateSiteExtended),
    fork(watchUpdateSiteDistribution),
    fork(watchUpdateStatusCake),
    fork(watchRefreshStatusCake),
    fork(watchStartContainer),
    fork(watchStopContainer),
    fork(watchIsSiteUp),
    fork(watchUpdateSiteNotes),
    fork(watchResetSitePassword)
  ])
}
