import { message } from 'antd'
import { DateTime, Duration } from 'luxon'
import numeral from 'numeral'
import { SUB_TYPES, BILLING_CYCLE_OPTIONS } from 'constants/AppConstants'

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const handleAlertMessage = (messageType, alertMessage, prevMessage, hideMessageDispatch, formatCallback = null) => {
  if (alertMessage && alertMessage !== prevMessage) {
    const formattedMessage = formatCallback ? formatCallback(alertMessage) : alertMessage
    if (messageType === MESSAGE_TYPE.SUCCESS) {
      message.success(formattedMessage)
    } else {
      message.error(formattedMessage)
    }
    setTimeout(() => {
      hideMessageDispatch()
    }, 100)
  }
}

export const billingDisplay = billingCycle => {
  if (billingCycle === BILLING_CYCLE_OPTIONS.ANNUAL) {
    return 'Annually'
  }
  return 'Monthly'
}

export const formatTime = (timeMs) => {
  return Duration.fromMillis(timeMs).toFormat('hh:mm:ss')
}

export const isFree = plan => {
  return plan && plan.name === SUB_TYPES.FREE_TRIAL
}

export const renderUsageThatAllowsUnlimited = (value, format = true) => {
  if (value === null) {
    return 'Unlimited'
  }
  if (format) {
    return numeral(value).format('0a')
  }
  return value
}

export const renderPriceMonthAndYear = priceObj => {
  // if value is null or price === 0.00
  const result = Object.entries(priceObj).filter(([key, value]) => value !== null && parseInt(value, 10) > 0)

  if (result.length) {
    const formatResult = result.map(item => `$ ${item[1]} / ${item[0]}`)
    return formatResult.join(' - ')
  }
  return 'Free'
}

export const capitalizeFirstLetter = string => {
  if (!string) return null
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatNumBytes = (num) => {
  if (!num) {
    return 'No Data!'
  }
  if (num < 1024 * 1024) {
    return '< 1MB'
  }
  return numeral(num).format('0.0 ib')
}

export const formatDateWithTime = timeStamp => {
  if (!timeStamp) return null
  // if timestamp is unix it will be a number otherwise its an iso string
  if (typeof timeStamp === 'number') {
    return DateTime.fromSeconds(timeStamp, { zone: 'utc' }).toFormat('dd MMM yy | HH:mm')
  }
  return DateTime.fromISO(timeStamp, { zone: 'utc' }).toFormat('dd MMM yy | HH:mm')
}

export const formatDate = timeStamp => {
  if (!timeStamp) return null
  // if timestamp is unix it will be a number otherwise its an iso string
  if (typeof timeStamp === 'number') {
    return DateTime.fromSeconds(timeStamp, { zone: 'utc' }).toFormat('dd MMM yy')
  }
  return DateTime.fromISO(timeStamp, { zone: 'utc' }).toFormat('dd MMM yy')
}

export const formatDateWithSlash = timeStamp => {
  if (!timeStamp) return null
  // if timestamp is unix it will be a number otherwise its an iso string
  if (typeof timeStamp === 'number') {
    return DateTime.fromSeconds(timeStamp, { zone: 'utc' }).toFormat('dd/MM/yyyy')
  }
  return DateTime.fromISO(timeStamp, { zone: 'utc' }).toFormat('dd/MM/yyyy')
}

export const luxonFromNow = timeStamp => {
  if (!timeStamp) return null
  if (typeof timeStamp === 'number') {
    return DateTime.fromSeconds(timeStamp).toRelative()
  } else {
    return DateTime.fromISO(timeStamp).toRelative()
  }
}

export const formatSecondsToHrMinSec = seconds => {
  if (!seconds) return 'N/A'
  return DateTime.fromSeconds(seconds, { zone: 'utc' }).toFormat('HH:mm:ss')
}
