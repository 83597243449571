import {
  GET_ACCOUNT_ID_CARDS_SUCCESS,
  ACCOUNT_ID_CHARGE_SUBSCRIPTION,
  ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS,
  GET_ACCOUNT_ID_INVOICE_SUCCESS,
  GET_ACCOUNT_ID_INVOICE,
  HIDE_MESSAGE,
  CLEAR_BILLING
}
from '../../constants/ActionTypes'

const INIT_STATE = {
  paymentLoader: false,
  cards: null,
  invoices: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNT_ID_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.payload
      }
    case ACCOUNT_ID_CHARGE_SUBSCRIPTION:
      return {
        ...state,
        paymentLoader: true
      }
    case ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        paymentLoader: false
      }
    case GET_ACCOUNT_ID_INVOICE:
      return {
        ...state,
        paymentLoader: true
      }
    case GET_ACCOUNT_ID_INVOICE_SUCCESS:
      return {
        ...state,
        paymentLoader: false,
        invoices: action.payload
      }
    case HIDE_MESSAGE: {
      return {
        ...state,
        paymentLoader: false
      }
    }
    case CLEAR_BILLING: {
      return {
        ...state,
        paymentLoader: false,
        invoices: null,
        cards: null
      }
    }
    default:
      return state
  }
}
