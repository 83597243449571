import React, { Component } from 'react'
import { Layout } from 'antd'

import Sidebar from '../Sidebar/index'
import Topbar from '../Topbar/index'
import { footerText } from 'appUtil/config'
import App from 'routes/index'
import { AdminContextProvider } from 'containers/AdminContext'
import AdminWebsocket from 'components/AdminWebsocket'

const { Content, Footer } = Layout

export class AdminApp extends Component {
  render () {
    const { match } = this.props

    return (
      <Layout>
        <Sidebar />

        <Layout>
          <Topbar />
          <Content id='main'>
            <AdminContextProvider>
              <AdminWebsocket />
              <App match={match} />
            </AdminContextProvider>
            <Footer>
              {footerText}
            </Footer>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default AdminApp
