import React, { Component } from 'react'
import { connect } from 'react-redux'

import { onVerticalNavStyleChange, toggleCollapsedSideNav } from 'appRedux/actions/Setting'
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI, TAB_SIZE, THEME_TYPE_LITE } from 'constants/ThemeSetting'

class SidebarLogo extends Component {
  state={ env: process.env.STAGE_INFRA }
  render () {
    const { width, themeType, navCollapsed } = this.props
    const { env } = this.state
    let { verticalNavStyle } = this.props
    if (width < TAB_SIZE && verticalNavStyle === NAV_STYLE_FIXED) {
      verticalNavStyle = NAV_STYLE_DRAWER
    } else if (width > TAB_SIZE && verticalNavStyle === NAV_STYLE_DRAWER) {
      verticalNavStyle = NAV_STYLE_FIXED
    }
    const Environment = () => <div style={{ textTransform: 'capitalize' }}>{env}</div>
    return (
      <div className='gx-layout-sider-header'>

        {verticalNavStyle !== NAV_STYLE_DRAWER &&
          <div className='gx-linebar'>
            <i
              className={`gx-icon-btn icon icon-${verticalNavStyle === NAV_STYLE_MINI ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
              onClick={() => {
                if (verticalNavStyle === NAV_STYLE_DRAWER) {
                  this.props.toggleCollapsedSideNav(!navCollapsed)
                } else if (verticalNavStyle === NAV_STYLE_FIXED) {
                  this.props.onVerticalNavStyleChange(NAV_STYLE_MINI)
                } else {
                  this.props.onVerticalNavStyleChange(NAV_STYLE_FIXED)
                }
              }}
            />
          </div>}

        <div className='gx-site-logo'>
          {themeType === THEME_TYPE_LITE
            ? <img alt='logo' src={require('assets/images/logo.png').default} />
            : <><img alt='logo' src={require('assets/images/logo-white.png').default} />{env !== 'prod' ? <Environment /> : null}</>}
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const { verticalNavStyle, themeType, width, navCollapsed } = settings
  return { verticalNavStyle, themeType, width, navCollapsed }
}

export default connect(mapStateToProps, { onVerticalNavStyleChange, toggleCollapsedSideNav })(SidebarLogo)
