import { API } from 'aws-amplify'
import PubSub from 'pubsub-js'
import { STRATTIC_API } from 'PubSub/Topics'

export const scrapePage = (url) => {
  PubSub.publishSync(STRATTIC_API.SCRAPE_PAGE.START)
  const promise = API.post('stratticAPI', 'sales-tools/scrape-page', { body: { url } })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SCRAPE_PAGE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SCRAPE_PAGE.ERROR, { error })
  })

  return promise
}

export const createCustomDomain = (siteId, data) => {
  PubSub.publishSync(STRATTIC_API.CREATE_CUSTOM_DOMAIN.START)
  const promise = API.post('stratticAPI', `sites/${siteId}/custom-domain`, { body: data })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.CREATE_CUSTOM_DOMAIN.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.CREATE_CUSTOM_DOMAIN.ERROR, { error })
  })

  return promise
}

export const deleteCustomDomain = (siteId, domainName) => {
  PubSub.publishSync(STRATTIC_API.DELETE_CUSTOM_DOMAIN.START)
  const promise = API.del('stratticAPI', `sites/${siteId}/custom-domain/${domainName}`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.DELETE_CUSTOM_DOMAIN.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.DELETE_CUSTOM_DOMAIN.ERROR, { error })
  })

  return promise
}

export const archivePlan = (planId) => {
  PubSub.publishSync(STRATTIC_API.PLAN_ARCHIVE.START)
  const promise = API.post('stratticAPI', `plans/${planId}/archive`, {})
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.PLAN_ARCHIVE.SUCCESS, { result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.PLAN_ARCHIVE.ERROR, { error })
  })

  return promise
}

export const patchSubscription = (id, data) => {
  PubSub.publishSync(STRATTIC_API.SUBSCRIPTION_PATCH.START)
  const promise = API.patch('stratticAPI', `subscriptions/${id}`, { body: data })
  promise.then((result) => {
    PubSub.publishSync(STRATTIC_API.SUBSCRIPTION_PATCH.SUCCESS, { result })
  }, (error) => {
    PubSub.publishSync(STRATTIC_API.SUBSCRIPTION_PATCH.ERROR, { error })
  })

  return promise
}

export const getAllPlans = (filters, pagination, order) => {
  const urlParamsString = getUrlParamsString(filters, pagination, order)

  PubSub.publishSync(STRATTIC_API.PLAN_GET_ALL.START)
  const promise = API.get('stratticAPI', 'plans?' + urlParamsString)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.PLAN_GET_ALL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.PLAN_GET_ALL.ERROR, { error })
  })

  return promise
}

export const customDomainCertificateCreate = (domainId, values) => {
  PubSub.publishSync(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_CREATE.START)
  const promise = API.post('stratticAPI', `distributions/${domainId}/certificate`, { body: values })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_CREATE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_CREATE.ERROR, { error })
  })

  return promise
}

export const customDomainCertificateDelete = (domainId) => {
  PubSub.publishSync(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_DELETE.START)
  const promise = API.del('stratticAPI', `distributions/${domainId}/certificate`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_DELETE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.CUSTOM_DOMAIN_CERTIFICATE_DELETE.ERROR, { error })
  })

  return promise
}

export const getPlansFamilies = () => {
  PubSub.publishSync(STRATTIC_API.PLAN_FAMILIES_GET_ALL.START)
  const promise = API.get('stratticAPI', 'plangroups')
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.PLAN_FAMILIES_GET_ALL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.PLAN_FAMILIES_GET_ALL.ERROR, { error })
  })

  return promise
}

const getUrlParamsString = (filters, pagination, order) => {
  const params = {}

  if (pagination) {
    params.page = pagination.current
    params.records = pagination.pageSize
  }

  if (order?.order) {
    let byColumn = order?.field
    // antd now returns nested column keys as an array
    if (Array.isArray(byColumn)) {
      byColumn = byColumn.join('.')
    }
    params.byColumn = byColumn
    params.order = order.order
  }

  if (filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
        continue
      }
      params[key] = value
    }
  }

  return new URLSearchParams(params).toString()
}

export const cancelCall = (promise) => {
  if (promise === null) {
    return
  }

  API.cancel(promise, 'Manually cancelled')
}

export const getSiteCrons = (siteId) => {
  PubSub.publishSync(STRATTIC_API.SITES_CRON_GET_ALL.START)
  const promise = API.get('stratticAPI', `sites/${siteId}/crons`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_GET_ALL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_GET_ALL.ERROR, { error })
  })

  return promise
}

export const siteCronCreate = ({ siteId, values }) => {
  PubSub.publishSync(STRATTIC_API.SITES_CRON_CREATE.START)
  const promise = API.post('stratticAPI', `sites/${siteId}/crons`, { body: values })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_CREATE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_CREATE.ERROR, { error })
  })

  return promise
}

export const siteCronDelete = (sitesCronId) => {
  PubSub.publishSync(STRATTIC_API.SITES_CRON_DELETE.START)
  const promise = API.del('stratticAPI', `sitesCron/${sitesCronId}`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_DELETE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_DELETE.ERROR, { error })
  })

  return promise
}

export const updateCronById = ({ sitesCronId, values }) => {
  PubSub.publishSync(STRATTIC_API.SITES_CRON_UPDATE.START)
  const promise = API.put('stratticAPI', `sitesCron/${sitesCronId}`, { body: values })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_UPDATE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SITES_CRON_UPDATE.ERROR, { error })
  })

  return promise
}

export const getSiteIdRestores = siteId => {
  PubSub.publishSync(STRATTIC_API.SITES_RESTORE_GET_ALL.START)
  const promise = API.get('stratticAPI', `sites/${siteId}/restores`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.SITES_RESTORE_GET_ALL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.SITES_RESTORE_GET_ALL.ERROR, { error })
  })

  return promise
}

export const getRules = ({ siteId, ruleType, distributionType }) => {
  PubSub.publishSync(STRATTIC_API.RULES_ENGINE_GET_RULES.START)
  const promise = API.get('stratticAPI', `sites/${siteId}/ruleset?ruleType=${ruleType}&distributionType=${distributionType}`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.RULES_ENGINE_GET_RULES.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.RULES_ENGINE_GET_RULES.ERROR, { error })
  })

  return promise
}

export const extendFreeTrial = (accountId) => {
  PubSub.publishSync(STRATTIC_API.EXTEND_FREE_TRIAL.START)
  const promise = API.post('stratticAPI', `accounts/${accountId}/extend-free-trial`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.EXTEND_FREE_TRIAL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.EXTEND_FREE_TRIAL.ERROR, { error })
  })

  return promise
}

export const getCurrentUser = () => {
  PubSub.publishSync(STRATTIC_API.ADMIN_GET_CURRENT_USER.START)
  const promise = API.get('stratticAPI', 'admin/current-user')
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.ADMIN_GET_CURRENT_USER.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.ADMIN_GET_CURRENT_USER.ERROR, { error })
  })

  return promise
}

export const getAdminRoles = () => {
  PubSub.publishSync(STRATTIC_API.ADMIN_GET_ROLES.START)
  const promise = API.get('stratticAPI', 'admin/roles')
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.ADMIN_GET_ROLES.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.ADMIN_GET_ROLES.ERROR, { error })
  })

  return promise
}

export const deleteAdminRole = (roleId) => {
  PubSub.publishSync(STRATTIC_API.ADMIN_DELETE_ROLE.START)
  const promise = API.del('stratticAPI', `admin/roles/${roleId}`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.ADMIN_DELETE_ROLE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.ADMIN_DELETE_ROLE.ERROR, { error })
  })

  return promise
}

export const createAdminRole = (data) => {
  PubSub.publishSync(STRATTIC_API.ADMIN_CREATE_ROLE.START)
  const promise = API.post('stratticAPI', 'admin/roles', { body: data })
  promise.then((result) => {
    PubSub.publishSync(STRATTIC_API.ADMIN_CREATE_ROLE.SUCCESS, { result })
  }, (error) => {
    PubSub.publishSync(STRATTIC_API.ADMIN_CREATE_ROLE.ERROR, { error })
  })

  return promise
}

export const updateAdminRole = (roleId, data) => {
  PubSub.publishSync(STRATTIC_API.ADMIN_UPDATE_ROLE.START)
  const promise = API.put('stratticAPI', `admin/roles/${roleId}`, { body: data })
  promise.then((result) => {
    PubSub.publishSync(STRATTIC_API.ADMIN_UPDATE_ROLE.SUCCESS, { result })
  }, (error) => {
    PubSub.publishSync(STRATTIC_API.ADMIN_UPDATE_ROLE.ERROR, { error })
  })

  return promise
}

export const getAllPermissions = () => {
  PubSub.publishSync(STRATTIC_API.PERMISSIONS_GET_ALL.START)
  const promise = API.get('stratticAPI', 'permissions')
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.PERMISSIONS_GET_ALL.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.PERMISSIONS_GET_ALL.ERROR, { error })
  })

  return promise
}

export const changeAdminRole = (adminUserId, newRoleId) => {
  PubSub.publishSync(STRATTIC_API.ADMIN_CHANGE_ROLE.START)
  const promise = API.post('stratticAPI', `admin/users/${adminUserId}/change-role`, { body: { roleId: newRoleId } })
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.ADMIN_CHANGE_ROLE.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.ADMIN_CHANGE_ROLE.ERROR, { error })
  })

  return promise
}

export const getDefaultPlans = () => {
  PubSub.publishSync(STRATTIC_API.GET_DEFAULT_PLANS.START)
  const promise = API.get('stratticAPI', 'default-plans')
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.GET_DEFAULT_PLANS.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.GET_DEFAULT_PLANS.ERROR, { error })
  })

  return promise
}

export const fetchInvoices = (accountId, filters, pagination, order) => {
  const urlParamsString = getUrlParamsString(filters, pagination, order)

  PubSub.publishSync(STRATTIC_API.INVOICES_FETCH.START)
  const promise = API.get('stratticAPI', `accounts/${accountId}/invoices?${urlParamsString}`)
  promise.then((result) => {
    PubSub.publish(STRATTIC_API.INVOICES_FETCH.SUCCESS, { data: result })
  }, (error) => {
    PubSub.publish(STRATTIC_API.INVOICES_FETCH.ERROR, { error })
  })

  return promise
}
