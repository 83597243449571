import {
  SUBMIT_DOMAIN,
  SUBMIT_DOMAIN_SUCCESS,
  GET_CERTIFICATE_STATUS,
  GET_CERTIFICATE_STATUS_SUCCESS,
  SET_NEW_DOMAIN,
  SET_NEW_DOMAIN_SUCCESS,
  IMPORT_CUSTOM_SSL,
  IMPORT_CUSTOM_SSL_ERROR,
  IMPORT_CUSTOM_SSL_SUCCESS,
  DELETE_CUSTOM_SSL,
  DELETE_CUSTOM_SSL_ERROR,
  DELETE_CUSTOM_SSL_SUCCESS,
  DISTRIBUTION_SET_CUSTOM_SSL,
  DISTRIBUTION_SET_CUSTOM_SSL_ERROR,
  DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  alertMessage: null,
  domainCertificate: false,
  setDomainSuccess: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBMIT_DOMAIN:
    {
      return {
        ...state,
        loader: true
      }
    }
    case SUBMIT_DOMAIN_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        domainCertificate: action.payload
      }
    }
    case GET_CERTIFICATE_STATUS:
    {
      return {
        ...state,
        loader: true
      }
    }
    case GET_CERTIFICATE_STATUS_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        domainCertificate: action.payload
      }
    }
    case SET_NEW_DOMAIN:
    {
      return {
        ...state,
        loader: true
      }
    }
    case SET_NEW_DOMAIN_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        setDomainSuccess: action.payload
      }
    }

    case IMPORT_CUSTOM_SSL: {
      return {
        ...state,
        importCustomSslLoader: true,
        importCustomSslSuccess: false
      }
    }
    case IMPORT_CUSTOM_SSL_SUCCESS: {
      return {
        ...state,
        importCustomSslLoader: false,
        importCustomSslSuccess: true
      }
    }
    case IMPORT_CUSTOM_SSL_ERROR: {
      return {
        ...state,
        importCustomSslLoader: false,
        importCustomSslSuccess: false
      }
    }
    case DELETE_CUSTOM_SSL: {
      return {
        ...state,
        deleteCustomSslLoader: true,
        deleteCustomSslSuccess: false
      }
    }
    case DELETE_CUSTOM_SSL_SUCCESS: {
      return {
        ...state,
        deleteCustomSslLoader: false,
        deleteCustomSslSuccess: true
      }
    }
    case DELETE_CUSTOM_SSL_ERROR: {
      return {
        ...state,
        deleteCustomSslLoader: false,
        deleteCustomSslSuccess: false
      }
    }
    case DISTRIBUTION_SET_CUSTOM_SSL: {
      return {
        ...state,
        distributionSetCustomSslLoader: true,
        distributionSetCustomSslSuccess: false
      }
    }
    case DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS: {
      return {
        ...state,
        distributionSetCustomSslLoader: false,
        distributionSetCustomSslSuccess: true
      }
    }
    case DISTRIBUTION_SET_CUSTOM_SSL_ERROR: {
      return {
        ...state,
        distributionSetCustomSslLoader: false,
        distributionSetCustomSslSuccess: false
      }
    }
    default:
      return state
  }
}
