import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

import 'assets/vendors/style'
import 'styles/flexile.less'
import configureStore, { history } from './appRedux/store'

import App from './containers/App/index'

export const store = configureStore()

const NextApp = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  )
}

export default NextApp
