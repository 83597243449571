import { push } from 'connected-react-router'
import { all, call, fork, put, takeEvery, debounce, throttle, takeLatest } from 'redux-saga/effects'
import { Auth, API } from 'aws-amplify'
import {
  SIGNIN_USER,
  SIGNIN_USER_OKTA,
  SIGNOUT_USER,
  SIGNUP_USER,
  ACCOUNTS_DATA_FETCH_REQUESTED,
  ADD_NEW_ACCOUNT,
  UPDATE_ACCOUNT_REQUEST,
  SITES_DATA_FETCH_REQUESTED,
  SITE_DATA_FETCH_REQUESTED,
  VERIFY_USER,
  RESET_REQUESTED,
  CHANGE_PASSWORD,
  SUBMIT_NEW_PASSWORD,
  ADD_SITE_REQUESTED,
  ADMIN_USER_DATA_REQUESTED,
  DELETE_SITE,
  DELETE_ADMIN_ID,
  ADMIN_SET_SELF_MFA,
  ADMIN_SET_SELF_MFA_SUCCESS
} from 'constants/ActionTypes'
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  accountsDataFetchSuccess,
  addNewAccountSuccess,
  updateAccountSuccess,
  sitesDataFetchSuccess,
  siteDataFetchSuccess,
  verifyUserSuccess,
  newPasswordSuccess,
  addSiteSuccess,
  adminUserDataSuccess,
  deleteSiteSuccess,
  hideAuthLoader,
  userSignOut,
  setCognito2FAUser,
  siteDataFetchEnd,
  showErrorCode
} from '../../appRedux/actions/Auth'
// import { certificateStatusSuccess } from '../actions/Domain';
import { getAccountIdContact } from '../actions/Data'

const createUserWithEmailPasswordRequest = async (payload) => {
  const body = {
    email: payload.username,
    password: payload.password,
    attributes: {
      phone_number: payload.mobileNumber,
      given_name: payload.firstName,
      family_name: payload.lastName
    }
  }
  return await API.post('stratticAPI', 'admin/users', { body })
}
const deleteAdminIdCall = async (adminEmail) => {
  const body = { email: adminEmail }
  return await API.del('stratticAPI', 'admin/users', { body })
}

const adminSetSelfMfaCall = async () => {
  return await API.post('stratticAPI', 'admin/users/setSelfMfa', {})
}

function * adminSetSelfMfaRequest () {
  try {
    yield call(adminSetSelfMfaCall)
    yield put({ type: ADMIN_SET_SELF_MFA_SUCCESS })
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.response.data.message))
      console.error(error)
    }
  }
}

function * deleteAdminIdRequest ({ adminEmail }) {
  try {
    yield call(deleteAdminIdCall, adminEmail)
    yield put({ type: ADMIN_USER_DATA_REQUESTED })
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.response.data.message))
      console.error(error)
    }
  }
}

const verifyWithEmailPasswordRequest = async (payload) => {
  /* const authUser = */ await Auth.confirmSignUp(payload.username, payload.code)
}

const signInUserWithEmailPasswordRequest = async (email, password) => {
  const authUser = await Auth.signIn(email, password)
  if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') { // for users created in console
    await Auth.completeNewPassword(authUser, password)
  }
  return authUser
}

const signOutRequest = async () => {
  await Auth.signOut()
}

const resetWithEmailrequest = async (payload) => {
  await Auth.forgotPassword(payload.username)
}

const resetPasswordWithEmailCodePasswordRequest = async (payload) => {
  await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.new_password)
}

const submitChangePasswordWithOld = async (payload) => {
  const oldPassword = payload.oldpassword
  const newPassword = payload.newpassword
  const currentUser = await Auth.currentAuthenticatedUser()
  await Auth.changePassword(
    currentUser,
    oldPassword,
    newPassword
  )
}

const getSessionRequest = async () => {
  try {
    return await Auth.currentSession()
  } catch (error) {
    console.log('error', error)
  }
}

// const siteAuthentication = async (domain) => {
//   const session = await Auth.currentSession()
//   console.log('session', session)

//   return fetch(`https://${domain}/strattic/authenticate`, {
//     method: 'POST',
//     body: JSON.stringify(session)
//   })
// }

async function accountsDataFetchRequest ({ records, pageNum, order, byColumn, search, planFamily, plans, statuses, subscriptionStatuses, accountTypes }) {
  const byColumnId = byColumn === undefined ? '' : `&byColumn=${byColumn}`
  const orderId = order === undefined ? '' : `&order=${order}`
  const searchQuery = search === undefined ? '' : `&search=${encodeURIComponent(search)}`
  const query = `accounts/?records=${records}&page=${pageNum}${byColumnId}${orderId}${searchQuery}&planGroups=${planFamily}&plans=${plans}&statuses=${statuses}&subscriptionStatuses=${subscriptionStatuses}&accountTypes=${accountTypes}`
  return await API.get('stratticAPI', query)
}

async function getAdminUserDataRequested () {
  return await API.get('stratticAPI', 'admin/users')
}

async function newAccountDatasubmit (data) {
  return await API.post('stratticAPI', 'accounts', { body: data })
}

async function updateAccountSubmit (id, values) {
  const path = `accounts/${id}`
  return await API.put('stratticAPI', path, { body: values })
}

async function sitesDataFetchRequest ({ records, pageNum, order, byColumn, searchText, status, siteType, customDomain }) {
  const byColumnId = byColumn === undefined ? '' : `&byColumn=${byColumn}`
  const orderId = order === undefined ? '' : `&order=${order}`
  const searchQuery = searchText === undefined ? '' : `&search=${encodeURIComponent(searchText)}`
  const statusFilter = !status || status.length === 0 || status === [] || status.includes('all')
    ? '&status=all'
    : `&status=${status}`

  const siteTypeFilter = !siteType || siteType.length === 0 || siteType === [] || siteType.includes('all')
    ? '&siteType=all'
    : `&siteType=${siteType}`

  const customDomainFilter = customDomain ? '&customDomain=true' : ''

  const query = `sites/?records=${records}&page=${pageNum}${byColumnId}${orderId}${searchQuery}${statusFilter}${siteTypeFilter}${customDomainFilter}`
  return await API.get('stratticAPI', query)
}

async function addNewSiteSubmitCall (values) {
  // console.log('accountId:', values.accountId, 'siteName:', values.name)
  const path = `accounts/${values.accountId}/sites`
  const body = {
    name: values.siteUrl,
    displayName: values.siteName,
    siteType: values.siteType
  }
  return await API.post('stratticAPI', path, { body })
}

async function siteDataFetchRequest (siteId) {
  return await API.get('stratticAPI', `sites/${siteId}`)
}

async function deleteSiteRequestCall (siteId, confirm) {
  return await API.del('stratticAPI', `sites/${siteId}`, { body: confirm })
}

// async function getCertificateStatusCall(domainId) {
//   console.log('making api call 2', domainId)
//   return await API.get('stratticAPI', `distributions/${domainId}/certificate`)
// }

function * sitesDataFetchRequests ({ payload }) {
  try {
    const sitesDataFetch = yield call(sitesDataFetchRequest, payload)
    yield put(sitesDataFetchSuccess(sitesDataFetch))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error)
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.message))
      console.error(error)
    }
  }
}

function * siteDataFetchRequests ({ siteId }) {
  try {
    const siteDataResponse = yield call(siteDataFetchRequest, siteId)
    yield put(siteDataFetchSuccess(siteDataResponse))
    yield put(hideAuthLoader())
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error)
      yield put(userSignOut())
    } else {
      if (error.response) {
        yield put(showErrorCode(error?.response.status))
      }
      yield put(showAuthMessage(error.message))
      console.error(error)
    }
  } finally {
    yield put(siteDataFetchEnd())
  }
}

function * deleteSiteRequest ({ siteId, confirm }) {
  try {
    const deleteSiteResponse = yield call(deleteSiteRequestCall, siteId, confirm)
    yield put(deleteSiteSuccess(deleteSiteResponse))
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * accountsDataFetchRequests ({ payload }) {
  try {
    const accountsDataFetch = yield call(accountsDataFetchRequest, payload)
    yield put(accountsDataFetchSuccess(accountsDataFetch))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error)
      yield put({ type: SIGNOUT_USER })
    } else {
      yield put(showAuthMessage(error.message))
      console.error(error.message)
    }
  }
}

function * adminUserDataRequested () {
  try {
    const adminUserData = yield call(getAdminUserDataRequested)
    yield put(adminUserDataSuccess(adminUserData))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.message))
      console.error(error)
    }
  }
}

function * addNewAccountData ({ payload }) {
  try {
    /* const sendNewAcc = */yield call(newAccountDatasubmit, payload)
    yield put(addNewAccountSuccess())
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * createUserWithEmailPassword ({ payload }) {
  try {
    yield call(createUserWithEmailPasswordRequest, payload)
    yield put(userSignUpSuccess())
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * verifyWithEmailPassword ({ payload }) {
  try {
    /* const verifyUser = */ yield call(verifyWithEmailPasswordRequest, payload)
    yield put(verifyUserSuccess())
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * signInUserWithEmailPassword ({ payload }) {
  const { email, password } = payload
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password)
    if (signInUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // pass signInUser to verify page
      yield put(setCognito2FAUser(signInUser))
      yield put(hideAuthLoader())
      yield put(push('/validateMFA'))
    } else {
      window.localStorage.setItem('user_id', signInUser.username)
      const session = yield call(getSessionRequest)
      window.localStorage.setItem('id_token', session.idToken.jwtToken)

      yield put(userSignInSuccess(signInUser.username))
    }
    // console.log(signInUser)
  } catch (error) {
    if (error.message === 'User is not confirmed.') {
      yield put(push('/validate'))
    } else {
      yield put(showAuthMessage(error.message))
    }
  }
}

const federatedSignIn = (provider) => {
  Auth.federatedSignIn({ provider })
}

function * signInUserWithOkta ({ payload }) {
  try {
    yield call(federatedSignIn, 'okta')
  } catch (error) {
    console.error('error', error)
  }
}

function * resetWithEmail ({ payload }) {
  try {
    /* const sendRequest = */ yield call(resetWithEmailrequest, payload)
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * resetPasswordWithEmailCodePassword ({ payload }) {
  try {
    /* const resetRequest = */ yield call(resetPasswordWithEmailCodePasswordRequest, payload)
    yield put(newPasswordSuccess())
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * submitChangePassword ({ payload }) {
  try {
    /* const newPass = */ yield call(submitChangePasswordWithOld, payload)
    yield put(newPasswordSuccess())
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * addNewSiteSubmit ({ payload }) {
  try {
    yield call(addNewSiteSubmitCall, payload)
    yield put(addSiteSuccess())
    yield put(push(`/accounts/${payload.accountId}/sites`))
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * signOut () {
  try {
    const signOutUser = yield call(signOutRequest)
    if (signOutUser === undefined) {
      window.localStorage.clear()
      yield put(userSignOutSuccess(signOutUser))
    }
    //  else {
    //   yield put(showAuthMessage(signOutUser.message));
    // }
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * updateAccountRequest ({ id, values }) {
  try {
    yield call(updateAccountSubmit, id, values)
    yield put(getAccountIdContact(id))
    yield put(updateAccountSuccess())
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * signInUser () {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword)
}

export function * signInUserOkta () {
  yield takeEvery(SIGNIN_USER_OKTA, signInUserWithOkta)
}

export function * signOutUser () {
  yield takeEvery(SIGNOUT_USER, signOut)
}

export function * createUserAccount () {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword)
}

export function * accountsDataFetchRequested () {
  yield debounce(1000, ACCOUNTS_DATA_FETCH_REQUESTED, accountsDataFetchRequests)
}

export function * sitesDataFetchRequested () {
  yield debounce(1000, SITES_DATA_FETCH_REQUESTED, sitesDataFetchRequests)
}

export function * siteDataFetchRequested () {
  yield takeEvery(SITE_DATA_FETCH_REQUESTED, siteDataFetchRequests)
}

export function * addNewAccountCall () {
  yield takeEvery(ADD_NEW_ACCOUNT, addNewAccountData)
}

export function * updateRequest () {
  yield takeEvery(UPDATE_ACCOUNT_REQUEST, updateAccountRequest)
}

export function * verifyRequest () {
  yield takeEvery(VERIFY_USER, verifyWithEmailPassword)
}

export function * resetRequest () {
  yield takeEvery(RESET_REQUESTED, resetWithEmail)
}

export function * forgotPasswordRequest () {
  yield takeEvery(SUBMIT_NEW_PASSWORD, resetPasswordWithEmailCodePassword)
}

export function * changePasswordRequest () {
  yield takeEvery(CHANGE_PASSWORD, submitChangePassword)
}

export function * addNewSite () {
  yield throttle(300, ADD_SITE_REQUESTED, addNewSiteSubmit)
}

export function * adminUserDataRequest () {
  yield takeEvery(ADMIN_USER_DATA_REQUESTED, adminUserDataRequested)
}
export function * watchDeleteSite () {
  yield takeEvery(DELETE_SITE, deleteSiteRequest)
}

export function * watchDeleteAdminId () {
  yield takeLatest(DELETE_ADMIN_ID, deleteAdminIdRequest)
}

export function * watchAdminSetSelfMfa () {
  yield takeLatest(ADMIN_SET_SELF_MFA, adminSetSelfMfaRequest)
}

export default function * rootSaga () {
  yield all([fork(signInUser),
    fork(signInUserOkta),
    fork(watchAdminSetSelfMfa),
    fork(createUserAccount),
    fork(accountsDataFetchRequested),
    fork(sitesDataFetchRequested),
    fork(siteDataFetchRequested),
    fork(addNewAccountCall),
    fork(signOutUser),
    fork(updateRequest),
    fork(verifyRequest),
    fork(resetRequest),
    fork(forgotPasswordRequest),
    fork(changePasswordRequest),
    fork(addNewSite),
    fork(adminUserDataRequest),
    fork(watchDeleteSite),
    fork(watchDeleteAdminId)
  ])
}
