const parseDomain = require('parse-domain')
const _ = require('lodash')

const MINUTES_PER_DAY = 60 * 24
const SECONDS_PER_DAY = 60 * 60 * 24
const SECONDS_PER_HOUR = 60 * 60

const pluralStringIfNeeded = (strVal, length) => {
  if (!length || length !== 1) {
    return strVal + 's'
  }
  return strVal
}

const isValidDomain = (domainName) => {
  const parsedDomain = parseDomain(domainName)
  return parsedDomain !== null
}

const isRootDomain = (domainName) => {
  const parsedDomain = parseDomain(domainName)
  return parsedDomain && !parsedDomain.subdomain
}

const isWwwDomain = (domainName) => {
  return domainName.startsWith('www.')
}

const upperFirst = (string) => {
  if (!string) {
    return null
  }
  return string.slice(0, 1).toUpperCase() + string.slice(1)
}

// takes the time in seconds and formats it in 00d: 00h: 00m
// based on https://stackoverflow.com/questions/47112569/formatting-seconds-duration-into-ddhhmm-format-by-using-moment-js
const formatSecondsToDHM = (secs) => {
  const z = (n) => {
    return ('0' + n).slice(-2)
  }
  return z(Math.floor(secs / SECONDS_PER_DAY)) + 'd: ' +
    z(Math.floor((secs % SECONDS_PER_DAY) / SECONDS_PER_HOUR)) + 'h: ' +
    z(Math.floor(secs % SECONDS_PER_HOUR) / 60) + 'm'
}

const prependUrlProtocol = (url, protocol = 'https') => {
  if (!url) {
    return
  }
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url
  }
  return `${protocol}://${url}`
}

const formatFetchData = (pagination, sorter) => {
  let records
  let page
  if (pagination) {
    records = pagination.pageSize
    page = pagination.current
  }
  if (!records) {
    records = 10
  }
  if (!page) {
    page = 1
  }

  let byColumn
  let order
  if (sorter) {
    byColumn = sorter.columnKey
    order = sorter.order === 'ascend' ? 'asc' : 'desc'
  }
  if (!byColumn) {
    byColumn = 'id'
  }
  if (!order) {
    order = 'desc'
  }

  const params = {
    records,
    page,
    byColumn,
    order
  }

  return params
}

// https://howchoo.com/javascript/how-to-turn-an-object-into-query-string-parameters-in-javascript
// added filter to remove null values
const formatQuery = (queryObj) => {
  if (queryObj) {
    const query = Object.keys(queryObj)
      .filter(key => {
        const value = queryObj[key]
        return value !== null && value !== undefined && value !== ''
      })
      .map(key => key + '=' + queryObj[key]).join('&')
    return query
  }
  return null
}

const returnAllowedValues = (constObj, filterValues) => {
  if (filterValues && constObj) {
    const objectValues = Object.values(constObj)
    return filterValues.filter(val => objectValues.includes(val))
  }
  return null
}

const mapArrayToLowercase = arr => arr && arr.map(val => val.toLowerCase())

const areSearchValuesEqual = (currentSearchValue, newSearchValue) => {
  const validTypeCheck = value => value && Array.isArray(value)
  const getArrayValue = value => _.isString(value) ? value.split(',') : value
  const currentSearchArray = getArrayValue(currentSearchValue)
  const newSearchArray = getArrayValue(newSearchValue)
  if (validTypeCheck(currentSearchArray) && validTypeCheck(newSearchArray)) {
    return _.isEqual(
      mapArrayToLowercase(currentSearchArray.sort()),
      mapArrayToLowercase(newSearchArray.sort())
    )
  }
  return false
}

const removeTrailingSlash = (val) => {
  if (typeof val === 'string' || val instanceof String) {
    return val.endsWith('/') ? val.slice(0, -1) : val
  }

  return val
}

const basicUrl = (val) => {
  const url = new URL(val)
  return url.protocol + '//' + url.hostname + url.pathname
}

const currentPageUrlWithSearch = querySearch => window.location.protocol + '//' + window.location.host + window.location.pathname + querySearch

module.exports = {
  pluralStringIfNeeded,
  isValidDomain,
  isRootDomain,
  upperFirst,
  formatSecondsToDHM,
  prependUrlProtocol,
  MINUTES_PER_DAY,
  formatFetchData,
  formatQuery,
  returnAllowedValues,
  currentPageUrlWithSearch,
  areSearchValuesEqual,
  mapArrayToLowercase,
  removeTrailingSlash,
  basicUrl,
  isWwwDomain
}
