import {
  ACCOUNT_UPDATE_TYPE,
  ACCOUNT_UPDATE_TYPE_SUCCESS,
  ACCOUNT_UPDATE_TYPE_ERROR,
  ACCOUNT_DELETE,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_ERROR,
  UPDATE_ACCOUNT_NOTES,
  UPDATE_ACCOUNT_NOTES_SUCCESS,
  UPDATE_ACCOUNT_NOTES_ERROR
} from 'constants/ActionTypes'

const INIT_STATE = {
  accountUpdateTypeLoader: false,
  accountUpdateTypeSuccess: false,
  accountDeleteLoader: false,
  accountDeleteSuccess: false,
  accountUpdateNotesLoader: false,
  accountUpdateNotesSuccess: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT_NOTES: {
      return {
        ...state,
        accountUpdateNotesLoader: true,
        accountUpdateNotesSuccess: false
      }
    }
    case UPDATE_ACCOUNT_NOTES_SUCCESS: {
      return {
        ...state,
        accountUpdateNotesLoader: false,
        accountUpdateNotesSuccess: true
      }
    }
    case UPDATE_ACCOUNT_NOTES_ERROR: {
      return {
        ...state,
        accountUpdateNotesLoader: false,
        accountUpdateNotesSuccess: false
      }
    }
    case ACCOUNT_UPDATE_TYPE: {
      return {
        ...state,
        accountUpdateTypeLoader: true,
        accountUpdateTypeSuccess: false
      }
    }
    case ACCOUNT_UPDATE_TYPE_SUCCESS: {
      return {
        ...state,
        accountUpdateTypeLoader: false,
        accountUpdateTypeSuccess: true
      }
    }
    case ACCOUNT_UPDATE_TYPE_ERROR: {
      return {
        ...state,
        accountUpdateTypeLoader: false,
        accountUpdateTypeSuccess: false
      }
    }

    case ACCOUNT_DELETE: {
      return {
        ...state,
        accountDeleteLoader: true,
        accountDeleteSuccess: false
      }
    }
    case ACCOUNT_DELETE_SUCCESS: {
      return {
        ...state,
        accountDeleteLoader: false,
        accountDeleteSuccess: true
      }
    }
    case ACCOUNT_DELETE_ERROR: {
      return {
        ...state,
        accountDeleteLoader: false,
        accountDeleteSuccess: false
      }
    }

    default:
      return state
  }
}
