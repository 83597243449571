import {
  GET_ALL_USERS,
  ALL_USERS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_SITES,
  GET_USER_SITES_SUCCESS,
  GET_ACCOUNT_USERS_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_SIGNUP_CODE,
  GET_SIGNUP_CODE_SUCCESS,
  RESET_USER_MFA,
  RESET_USER_MFA_SUCCESS,
  RESET_USER_MFA_ERROR
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  deleteUserLoader: false,
  deleteUserSuccess: false,
  allUsers: [],
  user: [],
  userSites: [],
  signupCode: [],
  resetUserMfaLoader: false,
  resetUserMfaSuccess: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
    case GET_USER:
    case GET_USER_SITES:
    case GET_SIGNUP_CODE:
    {
      return {
        ...state,
        loader: true
      }
    }
    case DELETE_USER:
    {
      return {
        ...state,
        deleteUserLoader: true,
        deleteUserSuccess: false
      }
    }
    case DELETE_USER_SUCCESS:
    {
      return {
        ...state,
        deleteUserLoader: false,
        deleteUserSuccess: true
      }
    }
    case DELETE_USER_ERROR:
    {
      return {
        ...state,
        deleteUserLoader: false,
        deleteUserSuccess: false
      }
    }
    case RESET_USER_MFA:
    {
      return {
        ...state,
        resetUserMfaLoader: true,
        resetUserMfaSuccess: false
      }
    }
    case RESET_USER_MFA_SUCCESS:
    {
      return {
        ...state,
        resetUserMfaLoader: false,
        resetUserMfaSuccess: true
      }
    }
    case RESET_USER_MFA_ERROR:
    {
      return {
        ...state,
        resetUserMfaLoader: false,
        resetUserMfaSuccess: false
      }
    }
    case ALL_USERS_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        allUsers: action.payload
      }
    }
    case GET_USER_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        user: action.payload
      }
    }
    case GET_USER_SITES_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        userSites: action.payload
      }
    }
    case GET_ACCOUNT_USERS_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        accountUsers: action.payload
      }
    }
    case GET_SIGNUP_CODE_SUCCESS:
    {
      return {
        ...state,
        loader: false,
        signupCode: action.payload
      }
    }
    default:
      return state
  }
}
