import {
  SUBMIT_DOMAIN,
  SUBMIT_DOMAIN_SUCCESS,
  GET_CERTIFICATE_STATUS,
  GET_CERTIFICATE_STATUS_SUCCESS,
  SET_NEW_DOMAIN,
  SET_NEW_DOMAIN_SUCCESS,
  IMPORT_CUSTOM_SSL,
  DELETE_CUSTOM_SSL,
  DISTRIBUTION_SET_CUSTOM_SSL
} from 'constants/ActionTypes'

export const submitDomain = (domainId, values, siteId) => {
  return {
    type: SUBMIT_DOMAIN,
    domainId,
    values,
    siteId
  }
}

export const submitDomainSuccess = (payload) => {
  return {
    type: SUBMIT_DOMAIN_SUCCESS,
    payload
  }
}

export const certificateStatus = (domainId, values) => {
  return {
    type: GET_CERTIFICATE_STATUS,
    domainId,
    values
  }
}

export const certificateStatusSuccess = ({ result }) => {
  return {
    type: GET_CERTIFICATE_STATUS_SUCCESS,
    payload: result
  }
}

export const setNewDomain = (domainId) => {
  return {
    type: SET_NEW_DOMAIN,
    domainId
  }
}

export const setNewDomainSuccess = (payload) => {
  return {
    type: SET_NEW_DOMAIN_SUCCESS,
    payload
  }
}

export const importCustomSsl = (siteId, payload) => {
  return {
    type: IMPORT_CUSTOM_SSL,
    payload,
    siteId
  }
}

export const deleteCustomSsl = (siteId, certificateId, payload) => {
  return {
    type: DELETE_CUSTOM_SSL,
    payload,
    siteId,
    certificateId
  }
}

export const distributionSetCustomSsl = (siteId, distributionId, certificateId) => {
  return {
    type: DISTRIBUTION_SET_CUSTOM_SSL,
    siteId,
    distributionId,
    certificateId
  }
}
