import React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'

import CustomScrollbars from 'appUtil/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import { THEME_TYPE_LITE } from 'constants/ThemeSetting' // NAV_STYLE_MINI
import {
  SolutionOutlined,
  GroupOutlined,
  TeamOutlined,
  UserOutlined,
  CreditCardOutlined,
  HddOutlined,
  KeyOutlined,
  DollarOutlined
} from '@ant-design/icons'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}
const items = [
  getItem('Accounts', 'accounts', <SolutionOutlined />),
  getItem('Sites', 'allsites', <GroupOutlined />),
  getItem('Users', 'users', <TeamOutlined />),
  getItem('Admins', 'admins', <UserOutlined />),
  getItem('Pricing', 'pricing', <CreditCardOutlined />, [
    getItem('Plans', 'pricing/plans'),
    getItem('Add-ons', 'pricing/addons'),
    getItem('Promos', 'pricing/promos'),
    getItem('Families', 'pricing/families'),
    getItem('Settings', 'plans/settings')
  ]),
  getItem('Roles', 'roles', <KeyOutlined />),
  getItem('Logs', 'logs', <HddOutlined />),
  getItem('Sales Tools', 'sales-tools', <DollarOutlined />)
]
const SidebarContent = ({ themeType, location }) => {
  const history = useHistory()
  const selectedKeys = location?.pathname?.substr(1)
  const defaultOpenKeys = selectedKeys?.split('/')[1]
  const selectMenuItem = item => history.push(`/${item?.key}`)
  return (
    <>
      <SidebarLogo />
      <CustomScrollbars className='gx-layout-sider-scrollbar' style={{ marginTop: 30 }}>
        <Menu
          onClick={selectMenuItem}
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode='inline'
          items={items}
        />
      </CustomScrollbars>
    </>

  )
}

SidebarContent.propTypes = {}
const mapStateToProps = ({ settings, router }) => {
  const { navStyle, verticalNavStyle, themeType, locale } = settings
  const { location } = router

  return { navStyle, verticalNavStyle, themeType, locale, location }
}
export default connect(mapStateToProps)(SidebarContent)
