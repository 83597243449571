import {
  GET_ACCOUNT_ID_SUBSCRIPTIONS,
  GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS,
  UPDATE_ACCOUNT_ID_SUBSCRIPTION,
  UPDATE_AUTO_RENEW,
  UPDATE_AUTO_BILL,
  CANCEL_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_CDN_EDGE_LOCATIONS,
  UPDATE_SUBSCRIPTION_MAX_SITES,
  UPDATE_SUBSCRIPTION_BY_ID
} from '../../constants/ActionTypes'

export const updateCdnEdgeLocations = (accountId, subscriptionId, body) => ({
  type: UPDATE_CDN_EDGE_LOCATIONS,
  accountId,
  subscriptionId,
  body
})

export const updateSubscriptionMaxSites = (accountId, subscriptionId, body) => ({
  type: UPDATE_SUBSCRIPTION_MAX_SITES,
  accountId,
  subscriptionId,
  body
})

export const updateSubscriptionByID = (accountId, subscriptionId, body) => ({
  type: UPDATE_SUBSCRIPTION_BY_ID,
  accountId,
  subscriptionId,
  body
})

export const getAccountIdSubscriptions = accountId => ({
  type: GET_ACCOUNT_ID_SUBSCRIPTIONS,
  accountId
})

export const getAccountIdSubscriptionsSuccess = payload => ({
  type: GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS,
  payload
})

export const updateSubscription = (accountId, values) => ({
  type: UPDATE_ACCOUNT_ID_SUBSCRIPTION,
  accountId,
  values
})

export const updateAutoRenew = (accountId, subId, values) => ({
  type: UPDATE_AUTO_RENEW,
  accountId,
  subId,
  values
})
export const updateAutoBill = (accountId, subId, values) => ({
  type: UPDATE_AUTO_BILL,
  accountId,
  subId,
  values
})

export const cancelSubscription = (accountId, subId) => ({
  type: CANCEL_SUBSCRIPTION,
  accountId,
  subId
})

export const validateSubscription = (accountId, subscription) => ({
  type: VALIDATE_SUBSCRIPTION,
  accountId,
  subscription
})

export const validateSubscriptionSuccess = payload => ({
  type: VALIDATE_SUBSCRIPTION_SUCCESS,
  payload
})
