import {
  GET_ACCOUNT_ID_SITES,
  GET_ACCOUNT_ID_SITES_SUCCESS,
  GET_ACCOUNT_ID_CONTACT,
  GET_ACCOUNT_ID_CONTACT_SUCCESS,
  GET_METRICS,
  GET_METRICS_SUCCESS,
  SET_ACCOUNT_TYPE,
  ADMIN_MFA_RESET,
  UPDATE_ACCOUNT_SUBSCRIPTION_PROPERTIES
} from 'constants/ActionTypes'

export const updateAccountSubscriptionProperties = (data) => {
  return {
    type: UPDATE_ACCOUNT_SUBSCRIPTION_PROPERTIES,
    data
  }
}

export const getAccountIdSites = (accountId) => {
  return {
    type: GET_ACCOUNT_ID_SITES,
    accountId
  }
}

export const getAccountIdSitesSuccess = ({ result }) => {
  return {
    type: GET_ACCOUNT_ID_SITES_SUCCESS,
    payload: result
  }
}

export const getAccountIdContact = (accountId) => {
  return {
    type: GET_ACCOUNT_ID_CONTACT,
    accountId
  }
}

export const getAccountIdContactSuccess = ({ result }) => {
  return {
    type: GET_ACCOUNT_ID_CONTACT_SUCCESS,
    payload: result
  }
}

export const getMetrics = payload => {
  return {
    type: GET_METRICS,
    payload
  }
}

export const getMetricsSuccess = payload => ({
  type: GET_METRICS_SUCCESS,
  payload
})
export const setAccountType = payload => ({
  type: SET_ACCOUNT_TYPE,
  payload
})

export const setAccountTypeSuccess = payload => ({
  type: SET_ACCOUNT_TYPE,
  payload
})

export const adminMFAReset = body => ({
  type: ADMIN_MFA_RESET,
  body
})
