import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { API } from 'aws-amplify'

import {
  SUBMIT_DOMAIN,
  SET_NEW_DOMAIN,
  IMPORT_CUSTOM_SSL,
  IMPORT_CUSTOM_SSL_ERROR,
  IMPORT_CUSTOM_SSL_SUCCESS,
  DELETE_CUSTOM_SSL,
  DELETE_CUSTOM_SSL_ERROR,
  DELETE_CUSTOM_SSL_SUCCESS,
  DISTRIBUTION_SET_CUSTOM_SSL,
  DISTRIBUTION_SET_CUSTOM_SSL_ERROR,
  DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS
} from '../../constants/ActionTypes'

import { showAuthMessage, siteDataFetch } from '../actions/Auth'
import { submitDomainSuccess, setNewDomainSuccess } from '../actions/Domain'

async function submitDomainCall (domainId, values) {
  return API.post('stratticAPI', `distributions/${domainId}/certificate`, { body: values })
}

async function setNewDomainCall (domainId) {
  return API.post('stratticAPI', `distributions/${domainId}/domain`)
}

async function importCustomSslCall (siteId, payload) {
  return API.post('stratticAPI', `sites/${siteId}/certs`, {
    body: payload
  })
}

async function deleteCustomSslCall (siteId, certificateId) {
  return API.del('stratticAPI', `sites/${siteId}/certs/${certificateId}`)
}

async function distributionSetCustomSslCall (distributionId, certificateId) {
  return API.post('stratticAPI', `distributions/${distributionId}/custom-ssl`, {
    body: {
      customSslId: certificateId
    }
  })
}

function * putSubmitDomain ({ domainId, values, siteId }) {
  try {
    const certificate = yield call(submitDomainCall, domainId, values)
    yield put(submitDomainSuccess(certificate))
    yield put(siteDataFetch(siteId))
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * postSetNewDomain ({ domainId }) {
  try {
    const domain = yield call(setNewDomainCall, domainId)
    yield put(setNewDomainSuccess(domain))
  } catch (error) {
    yield put(showAuthMessage(error.message))
  }
}

function * importCustomSslRequest ({ siteId, payload }) {
  try {
    const response = yield call(importCustomSslCall, siteId, payload)
    yield put({ type: IMPORT_CUSTOM_SSL_SUCCESS, response })
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: IMPORT_CUSTOM_SSL_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * deleteCustomSslRequest ({ siteId, certificateId }) {
  try {
    const response = yield call(deleteCustomSslCall, siteId, certificateId)
    yield put({ type: DELETE_CUSTOM_SSL_SUCCESS, response })
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: DELETE_CUSTOM_SSL_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * distributionSetCustomSsl ({ siteId, distributionId, certificateId }) {
  try {
    const response = yield call(distributionSetCustomSslCall, distributionId, certificateId)
    yield put({ type: DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS, response })
    yield put(siteDataFetch(siteId))
  } catch (error) {
    console.error(error)
    yield put({ type: DISTRIBUTION_SET_CUSTOM_SSL_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchSubmitDomain () {
  yield takeEvery(SUBMIT_DOMAIN, putSubmitDomain)
}
export function * watchSetNewDomain () {
  yield takeEvery(SET_NEW_DOMAIN, postSetNewDomain)
}

export function * watchImportCustomSsl () {
  yield takeLatest(IMPORT_CUSTOM_SSL, importCustomSslRequest)
}

export function * watchDeleteCustomSsl () {
  yield takeLatest(DELETE_CUSTOM_SSL, deleteCustomSslRequest)
}

export function * watchDistributionSetCustomSsl () {
  yield takeLatest(DISTRIBUTION_SET_CUSTOM_SSL, distributionSetCustomSsl)
}

export default function * rootSaga () {
  yield all([
    fork(watchSubmitDomain),
    fork(watchSetNewDomain),
    fork(watchImportCustomSsl),
    fork(watchDeleteCustomSsl),
    fork(watchDistributionSetCustomSsl)
  ])
}
