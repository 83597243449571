import { all, call, fork, put, takeEvery, debounce, delay, takeLatest } from 'redux-saga/effects'
import { API } from 'aws-amplify'
import {
  GET_ACCOUNT_ID_SUBSCRIPTIONS,
  UPDATE_ACCOUNT_ID_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  UPDATE_AUTO_RENEW,
  UPDATE_AUTO_BILL,
  VALIDATE_SUBSCRIPTION,
  UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS,
  UPDATE_CDN_EDGE_LOCATIONS,
  UPDATE_CDN_EDGE_LOCATIONS_ERROR,
  UPDATE_CDN_EDGE_LOCATIONS_SUCCESS,
  UPDATE_SUBSCRIPTION_MAX_SITES,
  UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS,
  UPDATE_SUBSCRIPTION_MAX_SITES_ERROR,
  UPDATE_SUBSCRIPTION_BY_ID,
  UPDATE_SUBSCRIPTION_BY_ID_SUCCESS,
  UPDATE_SUBSCRIPTION_BY_ID_ERROR
} from 'constants/ActionTypes'
import { showAuthMessage, userSignOut } from '../actions/Auth'
import { getAccountIdSubscriptionsSuccess, getAccountIdSubscriptions, validateSubscriptionSuccess } from '../actions/Subscriptions'
import { getAccountIdContact } from '../actions/Data'

async function getAccountIdSubscriptionsCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}/subscriptions`)
}

async function updateSubscriptionMaxSitesCall (subscriptionId, body) {
  return await API.patch('stratticAPI', `subscriptions/${subscriptionId}`, { body })
}

function * updateSubscriptionMaxSitesRequest ({ accountId, subscriptionId, body }) {
  try {
    yield call(updateSubscriptionMaxSitesCall, subscriptionId, body)
    yield put({ type: UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SUBSCRIPTION_MAX_SITES_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

async function updateSubscriptionCall (accountId, values) {
  return await API.post('stratticAPI', `accounts/${accountId}/subscriptions`, { body: values })
}

async function cancelSubscriptionCall (accountId, subId) {
  return await API.post('stratticAPI', `accounts/${accountId}/subscriptions/${subId}/cancel`, { body: {} })
}

async function updateSubscriptionByIdCall (accountId, subId, values) {
  return await API.patch('stratticAPI', `accounts/${accountId}/subscriptions/${subId}`, { body: values })
}

async function updateSubscriptionCdnEdgeLocationsCall (subscriptionId, body) {
  return await API.patch('stratticAPI', `subscriptions/${subscriptionId}`, { body })
}

async function updateSubscriptionPatchCall (subscriptionId, body) {
  return await API.patch('stratticAPI', `subscriptions/${subscriptionId}`, { body })
}

async function validateSubscriptionCall (accountId, subscription) {
  return await API.post('stratticAPI', `accounts/${accountId}/subscriptions/validate`, { body: subscription })
}

function * updateSubscriptionCdnEdgeLocationsRequest ({ accountId, subscriptionId, body }) {
  try {
    yield call(updateSubscriptionCdnEdgeLocationsCall, subscriptionId, body)
    yield put({ type: UPDATE_CDN_EDGE_LOCATIONS_SUCCESS })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_CDN_EDGE_LOCATIONS_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateSubscription ({ accountId, subscriptionId, body }) {
  try {
    yield call(updateSubscriptionPatchCall, subscriptionId, body)
    yield put({ type: UPDATE_SUBSCRIPTION_BY_ID_SUCCESS })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_SUBSCRIPTION_BY_ID_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * validateSubscriptionRequest ({ accountId, subscription }) {
  try {
    const validation = yield call(validateSubscriptionCall, accountId, subscription)
    yield put(validateSubscriptionSuccess(validation))
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
    console.error(error.response)
  }
}

function * getAccountIdSubscriptionsRequest ({ accountId }) {
  try {
    const subscriptions = yield call(getAccountIdSubscriptionsCall, accountId)
    yield put(getAccountIdSubscriptionsSuccess(subscriptions.result))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      yield put(userSignOut())
    } else {
      yield put(showAuthMessage(error.response.data.message))
      console.error(error)
    }
  }
}

function * updateSubscriptionRequest ({ accountId, values }) {
  try {
    yield call(updateSubscriptionCall, accountId, values)
    yield put(getAccountIdContact(accountId))
    yield put(getAccountIdSubscriptions(accountId))

    // After you update the subscription, refresh the current account a few times
    // to give a chance for the latest usage to get populated on the subscription
    const maxIterations = 3
    let i = 0
    while (i < maxIterations) {
      yield delay(5000)
      yield put(getAccountIdContact(accountId))
      i++
    }
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
    yield put({ type: UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS })
    console.error(error)
  }
}

function * cancelSubscriptionRequest ({ accountId, subId }) {
  try {
    yield call(cancelSubscriptionCall, accountId, subId)
    yield put(getAccountIdContact(accountId))
    yield put(getAccountIdSubscriptions(accountId))
    yield put({ type: UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS })
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
    yield put({ type: UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS })
    console.error(error)
  }
}

function * updateSubscriptionByIdRequest ({ accountId, subId, values }) {
  try {
    yield call(updateSubscriptionByIdCall, accountId, subId, values)
    yield put(getAccountIdContact(accountId))
    yield put(getAccountIdSubscriptions(accountId))
  } catch (error) {
    yield put(showAuthMessage(error.response.data.message))
    console.error(error)
  }
}

export function * watchUpdateSubscriptionCdnEdgeLocations () {
  yield takeLatest(UPDATE_CDN_EDGE_LOCATIONS, updateSubscriptionCdnEdgeLocationsRequest)
}

export function * watchUpdateSubscriptionMaxUsers () {
  yield takeLatest(UPDATE_SUBSCRIPTION_BY_ID, updateSubscription)
}

export function * watchGetAccountIdSubscriptions () {
  yield takeEvery(GET_ACCOUNT_ID_SUBSCRIPTIONS, getAccountIdSubscriptionsRequest)
}

export function * watchUpdateSubscription () {
  yield takeEvery(UPDATE_ACCOUNT_ID_SUBSCRIPTION, updateSubscriptionRequest)
}

export function * watchCancelSubscription () {
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscriptionRequest)
}

export function * watchUpdateAutoRenew () {
  yield takeEvery(UPDATE_AUTO_RENEW, updateSubscriptionByIdRequest)
}

export function * watchUpdateAutoBill () {
  yield takeEvery(UPDATE_AUTO_BILL, updateSubscriptionByIdRequest)
}

export function * watchValidateSubscription () {
  yield debounce(600, VALIDATE_SUBSCRIPTION, validateSubscriptionRequest)
}

export function * watchUpdateSubscriptionMaxSites () {
  yield takeLatest(UPDATE_SUBSCRIPTION_MAX_SITES, updateSubscriptionMaxSitesRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchUpdateSubscriptionMaxSites),
    fork(watchUpdateSubscriptionMaxUsers),
    fork(watchUpdateSubscriptionCdnEdgeLocations),
    fork(watchGetAccountIdSubscriptions),
    fork(watchUpdateSubscription),
    fork(watchCancelSubscription),
    fork(watchUpdateAutoRenew),
    fork(watchUpdateAutoBill),
    fork(watchValidateSubscription)
  ])
}
