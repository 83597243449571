import { message } from 'antd'

export const showErrorMessageOnApiError = (topic, msg) => {
  let errorMessage = 'Error occurred'
  // if the api call is manually cancelled do not display error
  if (msg?.error?.message === 'Manually cancelled') return
  if (msg?.error?.response?.status === 400) {
    errorMessage += ': ' + msg.error.response.data.message
  }
  if (msg?.error?.response?.status === 403) {
    errorMessage = 'Forbidden'
  }
  message.error(errorMessage)
}
