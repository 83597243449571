import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import PubSub from 'pubsub-js'

const getWebsocketUrl = async () => {
  const currentSession = await Auth.currentSession()
  return process.env.WEBSOCKET_URL + '?token=' + currentSession.getIdToken().getJwtToken()
}

const AdminWebsocket = () => {
  const { lastJsonMessage, readyState } = useWebSocket(getWebsocketUrl, {
    shouldReconnect: (closeEvent) => true
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  useEffect(() => {
    console.log('websocket connection status:', connectionStatus)
  }, [readyState])

  useEffect(() => {
    if (lastJsonMessage) {
      PubSub.publish(lastJsonMessage.eventType, lastJsonMessage)
      console.log('websocket message', lastJsonMessage)
    }
  }, [lastJsonMessage])

  return null
}

export default AdminWebsocket
