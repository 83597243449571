import React from 'react'
import { MailOutlined } from '@ant-design/icons'
import { Input, Layout, Row, Button, Card, Form } from 'antd'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  showAuthLoader,
  requestReset
} from 'appRedux/actions/Auth'

import CircularProgress from 'components/CircularProgress/index'

const FormItem = Form.Item
const { Content } = Layout

const RequestCode = ({ requestReset, loader, showAuthLoader }) => {
  const handleSubmit = (values) => {
    requestReset(values)
    showAuthLoader()
  }

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 }
    }
  }
  return (
    <div>
      <Layout>

        <Content style={{ background: '#fff' }}>
          <Row type='flex' justify='center'>

            <div style={{ background: '#fff', width: '33%' }}>

              <Card style={{ padding: 10, marginTop: '10%' }}>
                <div style={{ textAlign: 'center' }}>
                  <h2>Forgot your password?</h2>
                  <p>Please enter the email address registered on your account.</p>
                </div>
                <div>
                  <Form onFinish={handleSubmit}>
                    <FormItem
                      {...formItemLayout}
                      name='username'
                      rules={[
                        { type: 'email', message: 'The input is not a valid E-mail!' },
                        { required: true, message: 'Enter a valid email!' }
                      ]}
                    >
                      <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='email' placeholder='Email address' />
                    </FormItem>

                    <FormItem {...formItemLayout}>
                      <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%' }}>
                        RESET PASSWORD
                      </Button>

                    </FormItem>

                  </Form>
                  {loader &&
                    <div className='gx-loader-view'>
                      <CircularProgress />
                    </div>}
                </div>
                <Link to='/'>Back to login</Link>
              </Card>
            </div>

          </Row>
        </Content>
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage } = auth
  return { loader, alertMessage }
}

export default withRouter(connect(mapStateToProps, {
  showAuthLoader,
  requestReset
})(RequestCode))
