const common = {
  strattic_page_title: 'Strattic {{page_title}} - Static WordPress Hosting',
  dashboard: 'Dashboard',
  team: 'Team',
  team_management: 'Team Management',
  account: 'Account',
  support: 'Support',
  site: 'Site',
  backups: 'Backups',
  domain: 'Domain',
  database: 'Database',
  publishing_history: 'Publishing history',
  overview: 'Overview',
  site_details: 'Site details',
  site_details_updating:
    'These site details are updating.  This may take a few minutes.',
  live_site: 'Live Site',
  preview_site: 'Preview Site',
  preview: 'Preview',
  connection_info: 'Connection Info',
  wp_connection_info: 'WordPress Connection Info',
  preview_connection_info: 'Preview Site Connection Info',
  live_connection_info: 'Live Site Connection Info',
  preview_url: 'Preview Site URL',
  site_protection: 'Site Protection',
  password_protect_preview:
    'Password protect your static preview site with http basic authentication',
  password_protect_live:
    'Password protect your static live site with http basic authentication',
  live_url: 'Live URL',
  wp_url: 'WordPress URL',
  copied: 'Copied',
  copied_message: 'Copied!',
  copy: 'Copy',
  copy_to_clipboard: 'Copy to clipboard',
  show_password: 'Show password',
  at: 'At ',
  http_basic_auth: 'HTTP (Basic Auth)',
  http_basic_auth_credentials: 'HTTP (Basic Auth) Credentials',
  est_time: 'Est. time {{time}} sec',
  http_basic_auth_tooltip:
    'This is used as an added layer of protection to access your WordPress install',
  hidden_password_value: '************',
  credentials: 'Credentials',
  sftp_credentials: 'SFTP Credentials',
  sftp_credentials_tooltip:
    'Used for accessing your WordPress files via an SFTP client',
  reset: 'Reset',
  sql_credentials: 'SQL Credentials',
  username: 'Username',
  user_name: 'User Name',
  delete_site: 'Delete this site',
  site_deleted: 'Site has been deleted!',
  site_visitors_last_30_days: 'Site visitors - Last 30 days',
  sftp_address_host: 'SFTP Address/Host',
  sql_host: 'SQL Host',
  port: 'Port',
  my_profile: 'My Profile',
  site_search: 'Search Sites..',
  account_name: 'Account Name',
  create: 'Create',
  site_created: 'Site created',
  last_published: 'Last published',
  site_storage: 'Site storage',
  account_created: 'Account created',
  account_created_success:
    'Account created, you will now be redirected to login!',
  confirming_account: 'Confirming account',
  account_has_been_created:
    'Your account has been created, you can go ahead and login now.',
  check_email_to_confirm_1: 'We just sent a code to {{email}}.',
  check_email_to_confirm_2:
    'Please enter the code or click on the link inside the email.',
  confirm_account: 'Confirm your account',
  congrats: 'Congratulations!',
  please_login: 'Please proceed to login to begin.',
  create_an_account: 'Create an account',
  creating_account: 'Creating account',
  create_account: 'Create account',
  create_account_description:
    'Get started with your 14-day free trial, no credit card required.',
  create_account_description_invited:
    'Finish creating your account to get started!',
  name_please: 'Please input your name',
  field_required: 'This field is required',
  company: 'Company',
  company_please: 'Please input your company name',
  have_account: 'Have an account? ',
  please_accept_terms: 'Please accept Terms of Service',
  accept_our: 'Accept our',
  tos: 'Terms of Service',
  email_please: 'Please input your email',
  code_please: 'Please input the confirmation code',
  resend_code: 'Resend confirmation code',
  code_resent: 'A new code has been sent',
  processing: 'Processing',
  confirmation_code: 'Please input your confirmation code',
  password_please: 'Please input your password',
  new_password_please: 'Please input your new password',
  new_password: 'New Password',
  password_reset_success: 'Your password has been successfully reset!',
  password: 'Password',
  min_max: 'Value must be between 3 and 45 characters long',
  alnum: 'Value must contain alphanumeric characters only',
  min: 'Password must be at least 8 characters',
  uppercase: 'Password must contain an uppercase letter',
  lowercase: 'Password must contain a lowercase letter',
  number: 'Password must contain a number',
  email_invalid: 'Not a valid email',
  email: 'Email',
  submit: 'Submit',
  submitting: 'Submitting',
  logout: 'Logout',
  login: 'Login',
  logging_in: 'Logging In',
  loading: 'Loading',
  details: 'Details',
  edit_in: 'Edit in',
  screenshot: 'Site Screenshot',
  add_new_site: 'Add a New Site',
  new_site: 'New Site',
  site_is_being_created: 'Site is Being Created',
  site_name: 'Site Name',
  create_site_waiting_message:
    'Your site is being created, which may take a few minutes. You can wait here to be taken to your WordPress dashboard, or navigate away and come back later.',
  continue: 'Continue',
  hey_there: 'Hey there!',
  name_site_1: '1. Name Site',
  setup_wp_2: '2. Head to WordPress',
  setup_wp: 'Setup WP Environment',
  open_wp: 'Open WordPress',
  create_site_setup_wp_message:
    'Head to WordPress to install your new environment. At any point you can view your site by clicking “Strattic Publish” and you’re good to go.',
  enter_a_name: 'Enter a name. Ex. My Website',
  verification_code: 'Verification Code',
  code: 'Code',
  gb: 'GB',
  gib: 'GiB',
  invalid_size: 'Invalid Size',
  sites: 'Sites',
  visits: 'Visits',
  storage: 'Storage',
  bandwidth: 'Bandwidth',
  k: 'k',
  kb: 'KiB',
  view_all: 'View All',
  all: 'All',
  full: 'Full',
  quick: 'Quick',
  partial: 'Partial',
  selective: 'Selective',
  error_connecting: 'Error connecting',
  error_occurred:
    'An error has occurred, please try again or contact support for assistance',
  error_loading_account:
    'There has been an issue loading your account information. Please contact support via chat for assistance.',
  error_loading_site: 'Error loading site',
  please_contact_support: 'Please contact support',
  error_loading_sites:
    'There has been an issue loading your sites. Please contact support via chat for assistance.',
  error_getting_payment_details:
    'There has been an issue loading your payment details. Please contact support via chat for assistance.',
  error_loading_invoices:
    'There has been an issue loading your invoices. Please contact support via chat for assistance.',
  copyright_message: '© {{year}}. Strattic by Elementor. All rights reserved.',
  privacy_policy: 'Privacy Policy',
  terms_and_services: 'Terms of Service',
  dmca_policy: 'DMCA Policy',
  legal_stuff: 'Legal Stuff',
  mmmm_cookies: 'Cookie Policy',
  eight_characters: '8 characters',
  contains_lowercase: 'Contains lowercase',
  contains_uppercase: 'Contains uppercase',
  contains_number: 'Contains number',
  contains_special_characters: 'Contains special characters',
  reset_password: 'Reset Your Password',
  verification_code_text:
    'Enter your verification code and new password below to reset your password',
  update_payment_details: 'Update payment details',
  need_new_code: 'Need a new code?',
  back_to_login: 'Back to Login',
  website_unavailable: 'Website temporarily unavailable',
  try_later: 'Please try to visit later.',
  not_found: '404 Not Found',
  error_404: 'Error 404',
  not_found_robot: 'Not found robot',
  error_404_message:
    'This page does not exist or has moved, please reach out to support if you need help!',
  go_home: 'Go to homepage',
  login_text: 'Login to your Strattic account',
  proceed_to_login: 'Proceed to Login',
  forgot_password: 'Forgot your password?',
  please_wait: 'Please wait',
  no_account: "Don't have an account?",
  no_data: 'No Data',
  en: 'En',
  refresh: 'Refresh',
  please_refresh: 'Please Click to Refresh',
  taking_longer_than_usual:
    'Sorry, it looks like this is taking longer than usual.',
  english: 'English',
  hebrew: 'Hebrew',
  chinese: 'Chinese',
  register_here: 'Register here',
  payment_details: 'Payment details',
  usage_statistics: 'Usage statistics',
  change_plan: 'Change plan',
  renews_on: 'Renews on:',
  monthly_amount: 'Monthly amount:',
  annual_amount: 'Annual amount:',
  change_billing_settings: 'Change billing settings',
  most_recent: 'Most recent',
  current_subscription:
    'You’re currently subscribed to the {{name}} plan with {{frequency}} billing.',
  plan_includes: 'Your {{plan}} plan includes:',
  plan_details: 'Plan details',
  business: 'Business',
  invoices: 'Invoices',
  $: '$',
  'Free trial': 'Free Trial',
  'Free trial Expired': 'Free Trial Expired',
  free_trial_description:
    'Your free trial began on {{startedOn}} and {{expires}} on {{expiresOn}}.',
  expired_regular: 'expired',
  expires: 'expires',
  subscription_will_expire:
    'Your subscription will expire in {{daysLeft}} days.',
  subscription_will_expire_in_1_day: 'Your subscription will expire in 1 day',
  subscription_will_expire_today: 'Your subscription will expire today',
  subscription_renew_owner_click: ' To avoid data loss, click to renew',
  to_avoid_loss_contact: ' Contact your account owner to avoid data loss.',
  need_more_time: 'Need more time? Contact us via chat.',
  expired: 'Expired',
  browse_plans: 'Browse Plans',
  plan_not_selected: 'Plan Not Selected',
  return_to_select_plan: 'Return to Select a Plan',
  select_plan: 'Select a plan',
  select_new_plan: 'Select a Plan',
  select_new_plan_description:
    'Simple plans. Simple prices. Only pay for what you really need.',
  upgrade_now: 'Upgrade Now',
  upgrade_to_connect: 'Upgrade to connect your domain',
  head_to_wordpress: 'Head to WordPress',
  head_to_wordpress_txt1: 'Your WordPress site is ready!',
  head_to_wordpress_txt2: 'Time to get started.',
  explore_site_details: 'Explore Site Details',
  explore_site_details_txt:
    'Find your SFTP, backups, publish history and more in your Site Details area in the Strattic Dashboard.',
  go_to_wordpress: 'Go to WordPress',
  go_to_site_details: 'Go to Site Details',
  go_back_to_dashboard: 'Go back to Dashboard',
  upgrade_to_connect_description:
    'Upgrading to a full Strattic account is easy and will allow you to connect your custom domain name.',
  custom_domains: 'Custom Domains',
  connect_custom_domain_description:
    'This process will connect your live static site with a domain of your choice.',
  setup_custom_domain: "It's time to set up your custom domain!",
  connect_your_domain: 'Connect your domain',
  enter_valid_domain: 'Please enter a valid domain name',
  performance: 'Performance',
  workflows: 'Workflows',
  back_to_dashboard: 'Back to Dashboard',
  update: 'Update',
  select: 'Select',
  see_full_comparison: 'See full feature comparison',
  hide_full_comparison: 'See less features',
  for_enterprise:
    'For enterprise, agencies and companies with business-critical websites',
  custom: 'Custom',
  custom_plan: 'Custom Plan',
  custom_storage: 'Custom Storage',
  talk_to_us: 'Talk to us',
  per_site: 'per site',
  for_customized_needs:
    'Dedicated account manager, customized resources tailored to your needs',
  pay_monthly: 'Pay monthly',
  payment: 'Payment',
  cardholder_name: 'Cardholder Name',
  pay: 'Pay',
  tax: 'Tax',
  total: 'Total',
  pay_annually: 'Pay annually',
  two_months_free: 'Get 2 months free with the annual plan',
  your_plan: 'Your plan',
  most_popular: 'Most popular',
  payment_information: 'Payment Information',
  review_order: 'Review Order',
  review_order_description:
    'Change or cancel your subscription at any time. No questions asked.',
  confirm_payment: 'Confirm Payment',
  terms_of_service_agreement: 'By clicking confirm payment, you agree that:',
  terms_of_service_agreement_extended:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam.',
  payment_success: 'Payment Success!',
  plan_upgraded: 'Your plan has been upgrade to the {{ plan }} plan.',
  invoice_info: 'Invoice Info',
  company_name: 'Company Name',
  name: 'Name',
  card_name: 'Name on Card',
  country: 'Country',
  address: 'Address',
  address_line1: 'Address Line 1',
  address_line2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zip_code: 'Zip Code',
  site_type: 'Site Type',
  initiated_by: 'Initiated By',
  publish_type: 'Publish Type',
  initiator_display_name: 'Initiated By',
  date: 'Date',
  result: 'Result',
  publishing_time: 'Publishing Time',
  num_urls: 'Number of URLs',
  no_publishes: 'There are no publishes yet',
  no_backups: 'There are no backups yet',
  payment_information_description:
    'All information is securely submitted. You can pay with Visa, Mastercard, American Express, Discover and Diners Club.',
  plan_doesnt_support_usage: 'This plan doesn’t support your current usage.',
  plan_doesnt_support_usage_users:
    "You have {{ usersNum }} users, which isn't supported in this plan.",
  plan_doesnt_support_usage_sites:
    "You have {{ sitesNum }} sites, which isn't supported in this plan.",
  plan_doesnt_support_usage_sites_and_users:
    "You have {{ usersNum }} users and {{ sitesNum }} sites, which isn't supported in this plan.",
  select_another_plan: 'Please select another plan.',
  serverless_architecture: 'Serverless Architecture',
  fullsite_cdn: 'Fullsite CDN',
  gzip_compression: 'gZIP Compression',
  custom_publishing_optimization: 'Custom Publishing Optimization',
  full_comparison: 'Full Comparison',
  wp_staging_site: 'WordPress Staging Site',
  static_preview_site: 'Static Preview Site',
  custom_domains_static_preview: 'Custom Domains for Static Preview',
  dns_records: 'DNS Records',
  find_dns_below: 'Find your DNS records below',
  prev: 'Prev',
  status: 'Status',
  next: 'Next',
  test_sites: 'Test Sites',
  security: 'Security',
  free_SSL: 'Free SLL Certificates',
  sftp: 'SFTP',
  plans: 'Plans',
  containerized_wp: 'Containerized WordPress',
  auto_container_shutdown: 'Automatic Container Shutdown',
  auto_backup_retention: 'Automatic Backup Retention',
  manual_backups: 'Manual Backups',
  backup_size: 'Backup Size',
  backup_now: 'Backup Now',
  download: 'Download',
  type: 'Type',
  one_month: '1 month',
  email_chat_support: 'Email/Chat Support',
  per_month: 'per month',
  per_year: 'per year',
  dedicated_account_manager: 'Dedicated Account Manager',
  running: 'Running',
  loading_wp: 'Loading WordPress',
  loading_server: 'Loading Your Dedicated Server Container',
  loading_1: 'Aligning the galaxies 💫',
  loading_2: 'Telling the internet gods to spin up your site 👋',
  loading_3: 'Calling all serverless systems ☎️️',
  loading_4: 'Going for a unicorn ride 🦄',
  loading_5: 'Adding security layers 💪',
  loading_6: 'Saving the rainforests 🌳',
  loading_7: 'Waiting for this spinner to end 🤔',
  new_team_member: 'New team member',
  add_team_member: 'Add team member',
  add_site: 'Add site',
  manage_team_member: 'Manage',
  edit_team_member: 'Edit team member',
  role: 'Role',
  site_access: 'Site access',
  admin_desc_title: 'An Admin can',
  view_billing: 'View the billing section',
  access_manage_sites: 'Access and manage all sites',
  add_members_all_sites: 'Add team members to all sites',
  manager_desc_title: 'A Manager can',
  add_new_team_members: 'Add new team members',
  access_credentials: 'Access SFTP & SQL info',
  custom_domain: 'Custom Domain',
  domain_setup: 'Domain Setup',
  domain_being_connected: 'Domain being connected',
  your_domain: 'Your domain',
  your_domain_tooltip:
    'The URL you want people to visit for your site (i.e. businessname.com)',
  go_to_domain_page: 'Go to Domain Page',
  domain_name: 'Domain Name',
  new_domain: 'New domain name',
  name_host: 'Name/Host',
  value_answer: 'Value/Answer',
  site_connecting: 'Site being connected',
  connect_domain: 'Connect a domain',
  enter_domain: 'Enter domain',
  collaborator_desc_title: 'A Collaborator can',
  access_wp: 'Access the WordPress site',
  domain_registrar: 'Domain registrar',
  select_registrar: 'Please select your registrar',
  ssl_cert_confirm:
    'Add these DNS records in your domain registrar so that we can generate your SSL certificate',
  godaddy: 'GoDaddy',
  amazon_route_53: 'Amazon Route 53',
  namedotcom: 'Name.com',
  gandi: 'Gandi.net',
  google: 'Google',
  cloudflare: 'CloudFlare',
  registerdotcom: 'Register.com',
  network_solutions: 'Network Solutions',
  other: 'Other',
  site_being_connected: 'Site being connected',
  www_notice:
    'I understand that in order for my preferred domain to be a root domain (non-www), I would need to use a DNS provider that supports ANAME records and redirects from www to the root domain, such as AWS (Route 53), or Cloudflare.',
  www_learn_link: 'Learn why this works differently on Strattic.',
  aaaa_supported: 'Root Domain Supported',
  aaaa_maybe: 'Check with Provider for Support',
  aaaa_not_supported: 'Root Domain NOT Supported',
  required: 'This field is required',
  all_fields_required: 'All fields are required.',
  owner: 'Owner',
  admin: 'Admin',
  site_manager: 'Site manager',
  site_collaborator: 'Site collaborator',
  all_sites: 'All Sites',
  selected_sites: 'Selected sites',
  delete: 'Delete',
  remove_team_member: 'Remove team member',
  are_you_sure_delete_user: 'Are your sure you want to delete this user?',
  are_you_sure: 'Are you sure?',
  add: 'Add',
  save: 'Save',
  cancel: 'Cancel',
  exceeded_site_limit:
    "Your plan doesn't allow for more sites. Please upgrade to add more sites.",
  site_limit_contact_owner: 'Need another site? Speak to your account owner!',
  upgrade_trial_account_contact_owner: 'Contact the account owner to upgrade',
  switch_plan_with_future_subscription_btn_title:
    'Cancel your future subscription before selecting a new plan',
  upgrade_here: 'Upgrade your plan',
  pending: 'Pending',
  resend_invite: 'Resend invitation',
  your_subscription: 'Your subscription',
  expired_days_ago: ' expired {{daysLeft}} days ago',
  expired_one_day_ago: ' expired 1 day ago',
  expired_today: ' expired today',
  to_avoid_data_loss: ' To avoid data loss,',
  to_reactivate_avoid_data_loss:
    ' To reactivate your service and avoid data loss,',
  click_to_pay: ' click to pay now',
  click_to_renew: ' click to renew',
  users: 'Users',
  user: 'User',
  no_more_sites_allowed_owner:
    'Your plan doesn’t allow for more sites. Please upgrade to add more sites.',
  no_more_sites_allowed:
    'Your plan doesn’t allow for more sites. Please contact your account owner to upgrade and add more sites.',
  add_users_upgrade: 'Add more users by upgrading your account',
  add_sites_upgrade: 'Add more sites by upgrading your account',
  billing_email_warn:
    'Email address is currently set as the billing email address. Please change the billing email address on the account billing page before editing or removing this user.',
  billing_email_warn_trial:
    'This email was used to create the account. Please contact support for help editing or removing this user.',
  billing_email_update_success: 'Billing email was updated successfully!',
  invite_resent: 'Invitation resent!',
  delete_site_header: 'Delete site',
  delete_warning: "Once you delete this site, it's lost forever and ever",
  type_delete: 'Type DELETE to confirm',
  incorrect_input: 'Incorrect text. This field is case-sensitive.',
  trigger_backup_for_site: 'Trigger backup for your site {{siteName}}',
  select_backup_name: 'Please name your backup',
  backup_name_req: 'Backup name is required',
  backup_start_letter: 'Backup name must start with a letter',
  backup_five: 'Backup name must include 5 characters',
  backup_name: 'Backup name',
  backup_queued: 'Your backup has started',
  backup_restore_help: 'If you need help restoring a backup,',
  ready: 'Ready',
  in_progress: 'In Progress',
  failed: 'Failed',
  are_you_sure_del: 'Are you sure you want to delete',
  download_backup_queued: 'Your download(s) will begin shortly',
  yes: 'Yes',
  no: 'No',
  backups_usage_warn:
    'You have used {{backupLimit}}/{{backupLimit}} backups. If you need more ',
  will_be_happy_to_help: ' and we will be happy to help you.',
  complete: 'Complete',
  validate_ssl_info:
    'Once you’ve added the CNAME record to your domain registrar, we’ll validate the SSL and republish your site. Once the site is republished, we’ll send you an email to complete connecting your domain. Please note that this could take up to 30 minutes, depending on the size of your site.',
  success: 'Success',
  you_just_went_live:
    'You just went live on Strattic. We’re thrilled! Please allow for up to 24 hours for your site to fully propagate.',
  go_to_dash: 'Go to Dashboard',
  your_custom_domain: 'Your custom domain',
  updating_custom_domain:
    'Updating your environment. Please wait, this may take several minutes.',
  please_add_records: 'Please add the following records to your register',
  add_dns_records: 'Add DNS Records',
  detach_domain: 'Detach Domain',
  to_detach_domain:
    'To detach your domain and connect a new one, click “Detach Domain”',
  detach_custom_domain: 'Detach Custom Domain',
  detach_confirm_text:
    'Are you sure you want to detach your custom domain from this site?',
  type_detach: 'Type DETACH to confirm',
  detaching_domain: 'Detaching Domain',
  domain_detached: 'Domain was successfully detached!',
  yes_del: 'Yes, Delete!',
  no_thanks: 'No Thanks!',
  you_sure: 'Are you sure?',
  reset_domain_warning:
    'Are you sure you want to reset the custom domain {{pendingDomainName}}',
  expired_title: '{{plan}} expired',
  ready_to_upgrade: 'Ready to upgrade?',
  upgrading_is_easy: 'Upgrading to a full Strattic account is easy.',
  see_plans: 'See Plans',
  contact_sales: 'Contact Sales',
  custom_plan_title:
    'We’ll tailor a custom plan for your organization with a dedicated account manager, customized resources and more.',
  how_many_sites_manage: 'How many sites do you manage?',
  tell_about_needs: 'If you know tell us about your needs for:',
  anything_else: 'Is there anything else you want to tell us?',
  type_here: 'Type here...',
  feature_s3_replication: 'S3 Replication and Redundancy',
  feature_s3_replication_tooltip:
    'S3 cross-region replication for greater resilience',
  number_wp_users: 'Number of WordPress users?',
  send: 'Send',
  public_plan_expired:
    'Your subscription to the {{planName}} plan expired on {{planEndDate}}',
  custom_plan_expired: 'Your subscription expired on {{planEndDate}}',
  to_continue_using: 'To continue using our service',
  renew: 'Renew',
  no_active_sites: 'No active sites',
  no_active_sites_content:
    'It looks like there are no active sites in this account',
  getting_started: 'Getting Started',
  faq: 'FAQ',
  easiest_migrate_question:
    'What is the easiest way to migrate my existing website to Strattic?',
  easiest_migrate:
    'We’re big fans of the Migrate Guru plugin! All you need to do is add the plugin to your original site and enter your Strattic credentials into Migrate Guru.',
  step_by_step: 'Check out our step-by-step guide.',
  how_migrate_copy_question:
    'How do I migrate a local copy of my website to Strattic?',
  we_recommend_wp_migration:
    'We recommend All-in-One WP Migration WordPress plugin when migrating from a local copy of your site to Strattic.',
  wp_migration_tutorial_link: 'Check out our easy-to-follow tutorial',
  for_more_info: 'for more information.',
  can_you_help: 'I’m not sure what I am doing, can you help?',
  dont_panic:
    'Don\'t panic! We\'d be delighted to assist you. You can contact our helpful customer service team by ',
  the_chat_widget: 'starting a direct chat with us ',
  bottom_right_corner: 'or email ',
  support_email: 'support@strattic.com',
  strattic_walkthrough: 'A walkthrough of the Strattic platform',
  how_to_migrate: 'How to Migrate Your WordPress Site to Strattic',
  how_to_publish: 'How to Publish to Your Static Site on Strattic',
  ssl_certificate: 'SSL Certificate',
  site_has_been_validated_and_republished:
    '{{siteName}} has been validated and republished',
  domain_validation_success: 'Domain Validation Success',
  issued: 'Issued',
  acc_expired_content_1:
    'It looks like your account has expired. To continue using Strattic, please contact support.',
  acc_expired_content_2: "We're here if you have any questions",
  the_strattic_team: 'The Strattic Team',
  acc_not_connected_title:
    'It looks like this user isn\'t connected to an existing account.',
  acc_not_connected_content_1:
    'Please contact the account owner to re-invite this user to their account or contact',
  acc_not_connected_content_2:
    ' to create a new account associated with this user.',
  sign_up: 'Sign Up',
  users_not_match:
    'You’ve been invited to join {{accountName}} on Strattic. In order to accept the invitation, you must logout and log back in with your user {{userEmail}}',
  seats: 'Seats',
  seats_tooltip: 'Number of active WordPress users',
  number_of_wp_users: 'Number of active WordPress users',
  websites: 'Websites',
  visits_storage: "At Strattic we don't charge for visits and storage",
  cdn_limits: 'Fast, highly secure CDN & instant cache invalidation',
  cdn_limits_tooltip:
    'Limited regions include United States, Mexico, Canada, Europe and Israel. Enterprise plans include those regions plus South Africa, Kenya, The Middle East, Japan, Hong Kong, Philippines, Singapore, South Korea, Taiwan, Thailand, India, South America, Australia and New Zealand',
  limited_regions: 'Limited regions',
  global_cdn: 'Global (300+  edge locations)',
  preview_staging_site: 'Preview / staging static site',
  preview_tooltip:
    'Make sure your static site looks perfect by first previewing it in your preview static site',
  ddos_mitigation: 'DDoS mitigation',
  high_concurrency_build: 'High concurrency build process',
  high_concurrency_build_tooltip:
    'Sophisticated publishing system spins up additional resources to make the build process even faster',
  s3_replication_redundancy: 'S3 Replication and Redundancy',
  s3_replication_redundancy_tooltip:
    'S3 cross-region replication for greater resilience',
  uptime: 'Uptime',
  additional_staging: 'Additional staging sites',
  additional_staging_tooltip: 'Spin up additional development staging sites',
  aws_ssl: 'AWS SSL',
  aws_ssl_tooltip: 'Imported SSL available on Enterprise plans',
  cdn_bandwidth: 'CDN Bandwidth',
  staging_site_number: '1 per site',
  plan_one_tagline: 'Perfect for small businesses',
  plan_two_tagline: 'Ideal for growing businesses',
  plan_three_tagline: 'Comprehensive security and availability',
  unlimited: 'Unlimited',
  url_limit: 'URL limit',
  full_site_pub: 'Full site publish',
  upto_five_k: 'Up to 5K URLs',
  upto_ten_k: 'Up to 10K URLs',
  upto_twenty_k: 'Up to 20K URLs',
  upto_thirty: 'Up to 30 per month',
  upto_ten: 'Up to 10 per month',
  upto_twenty: 'Up to 20 per month',
  upto_ten_month: 'Up to 10 per month',
  upto_twenty_month: 'Up to 20 per month per site',
  upto_thirty_month: 'Up to 30 per month per site',
  unlimited_storage: 'Unlimited Storage',
  unlimited_visits: 'Unlimited Visits',
  stripe_authorize:
    'I authorize Strattic by Elementor to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.',
  expiration_date: 'Expiration Date',
  billing_details: 'Billing Details',
  updating: 'Updating...',
  cc_processing:
    'Please wait while we process your payment. Please do not refresh or click the back button as you may be charged twice.',
  sorry_upgrade:
    'Sorry, it looks like there is an issue upgrading your account.',
  you_awesome: "You're awesome,",
  thank_you: 'thank you!',
  for_any_questions: 'For any questions, you can always reach us at',
  return_to_strattic: 'Return to Strattic',
  please_contact: 'Please contact',
  happy_to_help: "and we'd be happy to help you out!",
  success_exclamation: 'Success!',
  you_are_now_signed_up_plan:
    "You're now signed up to the {{ planName }} plan.",
  thanks_again: 'Thank you once again and we hope you enjoy Strattic!',
  prorated_adjustment: 'Prorated Adjustment',
  billing_cycle_change_message:
    'You are currently subscribed to the {{currentPlanName}} plan with annual billing.\n\n Your billing cycle will change to monthly on {{dateString}}.',
  billing_change_message:
    'Your plan will change from {{currentPlanName}} (with {{currentSiteAllowance}} {{currentSiteOrSites}}) to {{cartPlanName}} (with {{cartAllowanceSites}} {{siteOrSites}}) on {{startDate}}',
  city_rules: 'Must include a minimum of 2 letters without symbols',
  invalid_zip: 'Please input a valid postal or zip code',
  min_4_char: 'This field requires a minimum of 4 characters',
  sub_change: 'Subscription Change',
  current_plan_details:
    'You are currently subscribed to the {{currentPlanName}} plan with {{aOrAn}} {{billingCycle}} amount of {{planPrice}}.',
  future_sub_plan_name:
    'You have requested to change to the {{futurePlanName}} plan.',
  future_sub_cancel_to_upgrade:
    'You have requested to change to the {{futurePlanName}} plan. To select a plan below, please cancel your request.',
  future_sub_details_1:
    'On {{dateString}}, your subscription will change to the {{futurePlanName}} plan with {{aOrAn}} {{billingCycle}} amount of {{costString}}.',
  future_sub_details_2:
    'The new subscription includes {{futureWebsites}} {{siteOrSites}}.',
  cancel_sub_change: 'Cancel Subscription Change',
  future_sub_change_cycle:
    'You have requested to change to the {{futurePlanName}} Plan with monthly billing on {{dateString}}. You will be charged {{costString}} {{cycle}}.',
  future_sub_change_plan:
    'You have requested to change to the {{futurePlanName}} Plan (with {{futureWebsites}} {{siteOrSites}} on {{dateString}}). You will be charged {{costString}} {{cycle}}.',
  to_select_plan_below: 'To select a plan below',
  cancel_request: 'Cancel request.',
  yes_cancel_future_sub: 'Yes, cancel plan change',
  confirm_cancel_future_sub:
    'Are you sure you want to cancel your request to change to the {{planName}} Plan (with {{numSites}} {{siteOrSites}})?',
  edit: 'Edit',
  change_or_cancel_sub:
    'Change or cancel your subscription at any time. No questions asked.',
  update_lc: 'update',
  credit_card_expired_please: 'Credit Card Expired. Please ',
  start_date: 'Start date',
  exceeded_allowance: 'Exceeding the allowed resources may result with overage',
  please_enter_valid_email: 'Please enter a valid email',
  forgot_your_strattic_password: 'Forgot Your Strattic Password?',
  forgot_your_password: 'Forgot Your Password',
  enter_email_reset_pword: 'Enter your email below to reset your password',
  go_back_to_login: 'Go back to login',
  pub_type: 'Publish Type',
  free_trial_expired_contact_support_for_owner:
    'Your trial has expired. To continue using Strattic, please contact support.',
  free_trial_expired_contact_support:
    'Your trial has expired. To continue using Strattic, please contact your account owner.',
  paid_acct_expired_contact_support_for_owner:
    'Your subscription has expired. To continue using Strattic, please renew your subscription or contact support.',
  paid_acct_expired_contact_support_domain_context:
    'Contact your account owner to renew the subscription to connect your domain.',
  paid_acct_expired_contact_support:
    'Your subscription has expired. To continue using Strattic, please contact your account owner.',
  busy: 'Busy',
  validating: 'Validating',
  sorry_already_taken: "Sorry, that's already taken.",
  Thank_you: 'Thank you!',
  in_touch: 'We’ll be in touch shortly',
  ok: 'OK',
  try_again: 'Try Again',
  delete_backups: 'Delete Backups',
  delete_backup_confirm_text:
    'Are you sure you want to delete {{num}} {{backupOrBackups}}?',
  disabled_strattic_link:
    'To view your {{environment}} static site, please publish to your {{environment}} environment from within your WordPress site',
  live: 'Live',
  back: 'Back',
  sso: 'SSO',
  end_date: 'End date',
  contact_acc_owner: 'Contact the account owner to upgrade',
  to_upgrade_to_latest_php_contact_support:
    'To upgrade to PHP {{version}}, please contact support@strattic.com',
  wp_url_tooltip:
    'Use this URL when migrating your site to Strattic using this ',
  guide: 'guide',
  more_details: 'More details',
  close: 'Close',
  php_ver: 'PHP version',
  wp_ver: 'WP version',
  latest: 'Latest',
  wp_ver_tip:
    'Not urgent on Strattic but recommended for a healthy WordPress site.',
  latest_live: 'Last Live Publish',
  latest_preview: 'Last Preview Publish',
  empty: 'Empty',
  sql_tooltip_one: 'Remote connections to the database are blocked as part of security procedures. However, you can access the database by uploading a tool like ',
  adminer: 'Adminer',
  sql_tooltip_two: 'and then remove it when you’re done making changes.',
  learn_more: 'Learn more',
  general: 'General',
  user_name_update_success: 'Your user name has been successfully updated',
  externally_managed_no_edit:
    'You cannot update your name while authenticated with an SSO/SAML account. You will need your identity provider to update your name.',
  change_password_text:
    'Please enter your current password and then choose a new password.',
  current_password: 'Current password',
  login_with_company_sso: 'Login with company SSO.',
  Your_password_updated: 'Your password has been successfully updated',
  password_confirm_please: 'Please confirm your password!',
  confirm_password: 'Confirm password',
  passwords_dont_match: 'The two passwords that you entered do not match!',
  update_password: 'Update your password',
  two_step_val: 'Two-step verification',
  on: 'ON',
  off: 'OFF',
  enable: 'Enable',
  two_factor_auth: 'Two-factor Authentication',
  please_scan_qr_auth:
    'Please scan the QR code with your preferred authentication app and then enter the provided one-time code below.',
  enter_6_code: 'Enter the 6-digit code',
  enter_your_6_code: 'Enter your 6-digit code',
  six_digits_still: 'Please enter your six digit validation code',
  mfa_set: 'Two-factor Authentication successfully enabled!',
  next_time_login:
    "Next time you log in, you'll need to launch your two-factor app and enter the 6 digit code.",
  verify: 'Verify',
  verify_code: 'Verify Code',
  two_fa: 'Two-factor authentication',
  two_fa_description:
    'Enter the 6-digit verification code from your authenticator app.',
  sso_2fa_warn: '2fa is not supported for SSO users',
  owner_acc_expired_add_site: 'Your account has expired. Please select a plan to add more sites.',
  acc_expired_add_site: 'Your account has expired. Please contact your account owner to select a plan to add more sites.',
  restore_in_progress: 'Restore in Progress',
  restore_in_progress_tooltip: 'This site is currently being restored. Please try again later.',
  restore_message: 'Your site is currently being restored, you will be unable to make certain changes while this is in progress. You’ll receive an email when the restore completes.',
  two_fa_col: '2FA',
  disabled: 'Disabled',
  enabled: 'Enabled',
  restore_complete: 'Restore complete',
  restore: 'Restore',
  restore_now: 'Restore now',
  restore_dialog_header: 'Restore a backup',
  restore_started: 'Restore started',
  trigger_restore_for_site: 'Please ensure that you have created a backup of your site before restoring.',
  anything_not_backed_up: 'Anything not saved and not backed up will be lost forever.',
  static_sites_not_effected: 'The static sites (Preview and Live) will not be affected by restoring a backup until your next publish.',
  type_restore: 'Type RESTORE to confirm',
  site_name_mismatch: 'The site name you entered does not match  the site you are restoring to. Please try again.',
  site_name_update_success: 'Site name has been successfully updated',
  you_are_about_to_restore: 'You are about to restore:',
  from_backup: 'From backup',
  headers_rules: 'Headers Rules',
  deploy_ruleset: 'Deploy',
  add_rule: 'Add Rule',
  need_a_custom_plan: 'Need a custom plan? ',
  ask_about_enter: 'Ask about Enterprise.',
  contact_sale: 'Contact Sales',
  sing_sign_on: 'Single Sign-On (SSO)',
  nine_nine_uptime: '99.999% Uptime',
  tailored_onboard: 'Tailored onboarding',
  priority_support: 'Prioritized technical support',
  enterprise_feat: 'Enterprise feature',
  customizable_headers_csp: 'Customizable headers and CSP',
  up_to: 'Up to ',
  no_posts_page: 'Number of Posts/Pages',
  upto_hundred: 'Up to 100',
  upto_fivehundred: 'Up to 500/site',
  upto_onek: 'Up to 1000/site',
  backup_freq: 'Backup frequency and retention',
  daily_fourteen: 'Daily – stored for 14 days',
  daily_thirty: 'Daily – stored for 30 days',
  enterprise: 'Enterprise',
  check_spam: 'If you didn\'t receive it, please check your Spam.',
  selective_quick_process: 'Selective/quick publish',
  sign_up_with: 'Signup with',
  fill_in_org_name: 'Before we get started, fill in your organization name to access your Strattic dashboard',
  org_name: 'Organization name',
  already_part_of_org: 'Already part of an existing orgranization? Ask for an invitation from your organization owner',
  update_org: 'Update Organization',
  can_not_assign_org: 'I can\'t assign my account to my organization, what should I do?',
  assign_org_response: 'TBC',
  invited_no_org: 'I was invited to Strattic but I don’t see my organization’s website(s). What should I do?',
  invited_no_org_response: 'Log out and sign in with the email address that matches the email address that was invited',
  or: 'Or',
  work_email: 'Work Email',
  reasons_assign_org: 'Here are some reasons why you might not be able to assign an organization to your account:',
  you_were_inv: 'You were invited to this account but registered to Strattic with a different email address. Please make sure you registered with the same email address that was invited.',
  you_were_removed: 'You were removed from the account. Please contact your account owner and ask them to re-add you to the account.',
  registered_no_inv: 'You registered for Strattic but haven’t been invited to this account. You can either ask the account owner to add you to the account or create a new account.',
  creation_tos: 'By creating an account, you agree to receive Strattic by Elementor emails and to our',
  complete_your_acc: 'Complete your account',
  finish_acc_setup: 'Finish setting up your account by filling in your information',
  continue_with_google: 'Continue with Google',
  account_created_logging_in: 'Account created, you will now be logged in',
  add_header_rule: 'Add Header Rule',
  update_header_rule: 'Update Header Rule',
  update_redirect_rule: 'Update Redirect Rule',
  add_redirect_rule: 'Add Redirect Rule',
  header_name: 'Header Name',
  header_value: 'Header Value',
  description: 'Description',
  source_uri: 'Source URI',
  add_new_condition: 'Add New Condition',
  additional_actions: 'Additional Actions',
  conditions: 'Conditions',
  move_up: 'Move Up',
  move_to_first: 'Move To First',
  move_down: 'Move Down',
  move_to_last: 'Move To Last',
  import: 'Import',
  export: 'Export',
  revert: 'Revert',
  operation: 'Operation',
  action: 'Action',
  header: 'Header',
  delete_rule: 'Delete Rule',
  delete_rule_confirm_text: 'Are you sure you want to delete the rule?',
  your_changes_are_not_deployed_yet: 'Your changes are not deployed yet',
  ruleset_deploying_successfully: 'Ruleset is deploying',
  rule_updated_successfully: 'Ruleset updated successfully',
  rule_created_successfully: 'Ruleset created successfully',
  rule_deleted_successfully: 'Ruleset deleted successfully',
  reverted_successfully: 'Reverted successfully',
  redirect_action: 'Redirect Action',
  redirect_target: 'Redirect Target',
  ruleset_reordered_successfully: 'Ruleset reordered successfully',
  forward_query_params: 'Forward Query Parameters',
  last_updated: 'Last Updated',
  last_deployed: 'Last Deployed',
  headers_rule_description: 'Header Rules let you add or override existing HTTP Headers such as request headers, cookies, and arguments in request as well as response headers. ',
  redirect_rule_description: 'Create rules to redirect visitors from a Source URL to a Target URL. ',
  copy_rules_confirmation_text: 'This will overwrite any existing rules on the {{distribution}} site',
  import_rules: 'Import rules',
  insufficent_permissions_rules_engine: 'Insuficient permissions, please contact your account manager',
  yes_proceed: 'Yes, proceed',
  last: 'Last',
  import_csv: 'Import CSV',
  export_csv: 'Export CSV',
  sync_from: 'Sync from',
  sync_to: 'Sync to',
  your_site_is_busy_updating: 'Your site is busy updating...',
  deploy_changes_to_site: 'Deploy your changes to the {{distribution}} site. No need to publish.',
  excecute_last: 'Execute this redirect rule last'
}
export default common
