import { useEffect } from 'react'
import { message } from 'antd'

const useAlertMessage = (messageType = 'error', alertMessage, hideMessageDispatch, handler = null) => {
  useEffect(() => {
    if (alertMessage && handler) {
      const handlerObject = handler(alertMessage)
      // check the handler is correctly structured
      if (typeof handlerObject !== 'object' ||
        'type' in handlerObject === false ||
        'messageText' in handlerObject === false) {
        throw new Error('handler function must return an object with type and messageText fields')
      }
      // if there is a handler set values else default messageType and message
      const type = handlerObject?.type || messageType
      const text = handlerObject?.messageText || alertMessage
      message[type](text)
    } else if (alertMessage) {
      message[messageType](alertMessage)
    }
    setTimeout(() => {
      hideMessageDispatch()
    }, 100)
  }, [alertMessage])
}

export default useAlertMessage

// sample code for handler function strucutre
//   (alertMessage) => {
//   if (alertMessage === 'USER_VERIFIED') {
//     return {
//       type: MESSAGE_TYPE.SUCCESS,
//       messageText: 'Successfully Verified'
//     }
//   }
//   return {
//     type: MESSAGE_TYPE.ERROR,
//     messageText: alertMessage
//   }
// })
