import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import PubSub from 'pubsub-js'

import AdminApp from './AdminApp'

import { STRATTIC_API } from 'PubSub/Topics'
import SignIn from '../SignIn'
import Validate from '../Validate'
import ValidateMFA from '../ValidateMFA/index'
import SetupMFA from '../SetupMFA/index'
import ForgotPassword from '../ResetPassword'
import OauthCallback from '../OauthCallback'
import '../../i18n'

import { setInitUrl, userSignOut } from 'appRedux/actions/Auth'
import { LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL } from 'constants/ThemeSetting'
import { showErrorMessageOnApiError } from 'StratticApi/listeners'

const RestrictedRoute = ({ component: Component, required, redirectPath = '/signin', ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        required
          ? <Component {...props} />
          : <Redirect
              to={{
                pathname: redirectPath,
                state: { from: props.location }
              }}
            />}
    />
  )
}

class App extends Component {
  componentDidMount () {
    this.subscriptionForUnauthorizedCheck = PubSub.subscribe(STRATTIC_API.ALL.ERROR, this.unauthorizedSubscriber)
    this.errMsgShow = PubSub.subscribe(STRATTIC_API.ALL.ERROR, showErrorMessageOnApiError)
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname)
    }
  }

  componentWillUnmount () {
    PubSub.unsubscribe(this.subscriptionForUnauthorizedCheck)
    PubSub.unsubscribe(this.errMsgShow)
  }

  /**
   * PubSub subscriber.
   * Checks for StratticApi server errors.
   * If error was thrown because of auth problem - sign the user out
   * @param topic
   * @param msg
   * @returns {Promise<void>}
   */
  unauthorizedSubscriber = async (topic, msg) => {
    const { error } = msg
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      this.props.userSignOut()
    }
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('full-layout')
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('boxed-layout')
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('full-layout')
      document.body.classList.add('framed-layout')
    }
  };

  render () {
    const { match, location, /* layoutType, */ authUser, cognito2faUser, initURL } = this.props
    console.log('initURL', initURL)
    if (location.pathname === '/') {
      if (authUser === null) {
        return (<Redirect to='/signin' />)
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/callback' || initURL.toLowerCase() === '/validatemfa') {
        return (<Redirect to='/accounts' />)
      } else {
        return (<Redirect to={initURL} />)
      }
    }
    return (
      <Switch>
        <Route exact path='/signin'>
          <SignIn />
        </Route>
        <Route exact path='/signout'>
          <Redirect to='/signin' />
        </Route>
        <Route exact path='/validate'>
          <Validate />
        </Route>
        <Route exact path='/callback'>
          <OauthCallback />
        </Route>
        <RestrictedRoute exact path='/validatemfa' required={cognito2faUser} redirectPath='/' component={ValidateMFA} />
        <Route exact path='/setupmfa'>
          <SetupMFA />
        </Route>
        <Route exact path='/resetpassword'>
          <ForgotPassword />
        </Route>
        <RestrictedRoute
          path={`${match?.url}`} required={authUser}
          component={AdminApp}
        />
      </Switch>
    )
  }
}

const mapStateToProps = ({ settings, auth, router }) => {
  const { location } = router
  const { locale, layoutType } = settings
  const { authUser, cognito2faUser, initURL } = auth
  return { locale, layoutType, authUser, cognito2faUser, initURL, location }
}
export default withRouter(connect(mapStateToProps, { setInitUrl, userSignOut })(App))
