// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU'
export const WINDOW_WIDTH = 'WINDOW-WIDTH'
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE'

// Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const SHOW_ERROR_CODE = 'SHOW_ERROR_CODE'
export const HIDE_ERROR_CODE = 'HIDE_ERROR_CODE'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_OKTA = 'SIGNIN_USER_OKTA'
export const VERIFY_USER = 'VERIFY_USER'
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const INIT_URL = 'INIT_URL'
export const RESET_REQUESTED = 'RESET_REQUESTED'
export const SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const RESET_CODE_SEND = 'RESET_CODE_SEND'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const SET_COGNITO_2FA_USER = 'SET_COGNITO_2FA_USER'
export const ADMIN_MFA_RESET = 'ADMIN_MFA_RESET'
export const ADMIN_SET_SELF_MFA = 'ADMIN_SET_SELF_MFA'
export const ADMIN_SET_SELF_MFA_SUCCESS = 'ADMIN_SET_SELF_MFA_SUCCESS'
export const ADMIN_MFA_RESET_SUCCESS = 'ADMIN_MFA_RESET_SUCCESS'

// data consts
export const ACCOUNTS_DATA_FETCH_REQUESTED = 'ACCOUNTS_DATA_FETCH_REQUESTED'
export const ACCOUNTS_DATA_FETCH_SUCCESS = 'ACCOUNTS_DATA_FETCH_SUCCESS'
export const ACCOUNTS_DATA_FETCH_FAIL = 'ACCOUNTS_DATA_FETCH_FAIL'
export const ADD_NEW_ACCOUNT = 'ADD_NEW_ACCOUNT'
export const ADD_NEW_ACCOUNT_SUCCESS = 'ADD_NEW_ACCOUNT_SUCCESS'
export const LOG_SINGLE_ACCOUNT = 'LOG_SINGLE_ACCOUNT'
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const SITES_DATA_FETCH_REQUESTED = 'SITES_DATA_FETCH_REQUESTED'
export const SITES_DATA_FETCH_SUCCESS = 'SITES_DATA_FETCH_SUCCESS'
export const SITE_DATA_FETCH_REQUESTED = 'SITE_DATA_FETCH_REQUESTED'
export const SITE_DATA_FETCH_SUCCESS = 'SITE_DATA_FETCH_SUCCESS'
export const SITE_DATA_FETCH_END = 'SITE_DATA_FETCH_END'
export const ADD_SITE_REQUESTED = 'ADD_SITE_REQUESTED'
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS'
export const GET_ACCOUNT_ID_CONTACT = 'GET_ACCOUNT_ID_CONTACT'
export const GET_ACCOUNT_ID_CONTACT_SUCCESS = 'GET_ACCOUNT_ID_CONTACT_SUCCESS'
export const GET_METRICS = 'GET_METRICS'
export const GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS'
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE'
export const SET_ACCOUNT_TYPE_SUCCESS = 'SET_ACCOUNT_TYPE_SUCCESS'

export const ACCOUNT_UPDATE_TYPE = 'ACCOUNT_UPDATE_TYPE'
export const ACCOUNT_UPDATE_TYPE_SUCCESS = 'ACCOUNT_UPDATE_TYPE_SUCCESS'
export const ACCOUNT_UPDATE_TYPE_ERROR = 'ACCOUNT_UPDATE_TYPE_ERROR'

export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS'
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR'

export const UPDATE_ACCOUNT_NOTES = 'UPDATE_ACCOUNT_NOTES'
export const UPDATE_ACCOUNT_NOTES_SUCCESS = 'UPDATE_ACCOUNT_NOTES_SUCCESS'
export const UPDATE_ACCOUNT_NOTES_ERROR = 'UPDATE_ACCOUNT_NOTES_ERROR'

export const UPDATE_ACCOUNT_SUBSCRIPTION_PROPERTIES = 'UPDATE_SUBSCRIPTION_PROPERTIES'

// admin
export const ADMIN_USER_DATA_REQUESTED = 'ADMIN_USER_DATA_REQUESTED'
export const ADMIN_USER_DATA_SUCCESS = 'ADMIN_USER_DATA_SUCCESS'
export const DELETE_ADMIN_ID = 'DELETE_ADMIN_ID'

// sites
export const SUBMIT_DOMAIN = 'SUBMIT_DOMAIN'
export const SUBMIT_DOMAIN_SUCCESS = 'SUBMIT_DOMAIN_SUCCESS'
export const GET_CERTIFICATE_STATUS_SUCCESS = 'GET_CERTIFICATE_STATUS_SUCCESS'
export const GET_CERTIFICATE_STATUS = 'GET_CERTIFICATE_STATUS'
export const SET_NEW_DOMAIN = 'SET_NEW_DOMAIN'
export const SET_NEW_DOMAIN_SUCCESS = 'SET_NEW_DOMAIN_SUCCESS'
export const DELETE_SITE = 'DELETE_SITE'
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS'
export const UPDATE_SITE_NOTES = 'UPDATE_SITE_NOTES'
export const UPDATE_SITE_NOTES_SUCCESS = 'UPDATE_SITE_NOTES_SUCCESS'
export const UPDATE_SITE_NOTES_ERROR = 'UPDATE_SITE_NOTES_ERROR'
export const GET_ACCOUNT_ID_SITES = 'GET_ACCOUNT_ID_SITES'
export const GET_ACCOUNT_ID_SITES_SUCCESS = 'GET_ACCOUNT_ID_SITES_SUCCESS'
export const GET_SITE_ID_BACKUPS = 'GET_SITE_ID_BACKUPS'
export const GET_SITE_ID_BACKUPS_SUCCESS = 'GET_SITE_ID_BACKUPS_SUCCESS'
export const GET_SITE_ID_BACKUPS_ERROR = 'GET_SITE_ID_BACKUPS_ERROR'
export const GET_SITE_ID_BACKUP_URL = 'GET_SITE_ID_BACKUP_URL'
export const GET_SITE_ID_BACKUP_URL_SUCCESS = 'GET_SITE_ID_BACKUP_URL_SUCCESS'
export const RESTORE_SITE_BACKUP = 'RESTORE_SITE_BACKUP'
export const TRIGGER_SITE_BACKUP = 'TRIGGER_SITE_BACKUP'
export const DELETE_SITE_BACKUP = 'DELETE_SITE_BACKUP'
export const SET_CONTAINER_UPTIME = 'SET_CONTAINER_UPTIME'
export const GET_SITE_PUBLISHES = 'GET_SITE_PUBLISHES'
export const GET_SITE_PUBLISHES_SUCCESS = 'GET_SITE_PUBLISHES_SUCCESS'
export const BACKUP_FROM_ZIP = 'BACKUP_FROM_ZIP'
export const BACKUP_FROM_ZIP_SUCCESS = 'BACKUP_FROM_ZIP_SUCCESS'
export const UPDATE_SITE = 'UPDATE_SITE'
export const UPDATE_SITE_SUCCESS = 'UPDATE_SITE_SUCCESS'
export const UPDATE_SITE_EXTENDED = 'UPDATE_SITE_EXTENDED'
export const UPDATE_SITE_EXTENDED_SUCCESS = 'UPDATE_SITE_EXTENDED_SUCCESS'
export const UPDATE_SITE_EXTENDED_ERROR = 'UPDATE_SITE_EXTENDED_ERROR'
export const UPDATE_SITE_DISTRIBUTION = 'UPDATE_SITE_DISTRIBUTION'
export const UPDATE_SITE_DISTRIBUTION_SUCCESS = 'UPDATE_SITE_DISTRIBUTION_SUCCESS'
export const UPDATE_SITE_DISTRIBUTION_ERROR = 'UPDATE_SITE_DISTRIBUTION_ERROR'
export const UPDATE_SITE_ERROR = 'UPDATE_SITE_ERROR'
export const UPDATE_STATUSCAKE = 'UPDATE_STATUSCAKE'
export const UPDATE_STATUSCAKE_SUCCESS = 'UPDATE_STATUSCAKE_SUCCESS'
export const UPDATE_STATUSCAKE_ERROR = 'UPDATE_STATUSCAKE_ERROR'
export const REFRESH_STATUSCAKE = 'REFRESH_STATUSCAKE'
export const REFRESH_STATUSCAKE_SUCCESS = 'REFRESH_STATUSCAKE_SUCCESS'
export const REFRESH_STATUSCAKE_ERROR = 'REFRESH_STATUSCAKE_ERROR'
export const GET_BACKUP_URL_ERROR = 'GET_BACKUP_URL_ERROR'
export const START_CONTAINER = 'START_CONTAINER'
export const START_CONTAINER_SUCCESS = 'START_CONTAINER_SUCCESS'
export const START_CONTAINER_ERROR = 'START_CONTAINER_ERROR'
export const STOP_CONTAINER = 'STOP_CONTAINER'
export const STOP_CONTAINER_SUCCESS = 'STOP_CONTAINER_SUCCESS'
export const STOP_CONTAINER_ERROR = 'STOP_CONTAINER_ERROR'
export const IS_SITE_UP = 'IS_SITE_UP'
export const IS_SITE_UP_SUCCESS = 'IS_SITE_UP_SUCCESS'
export const UPDATE_SITE_IP_WHITELIST = 'UPDATE_SITE_IP_WHITELIST'
export const UPDATE_SITE_IP_WHITELIST_SUCCESS = 'UPDATE_SITE_IP_WHITELIST_SUCCESS'
export const UPDATE_SITE_IP_WHITELIST_ERROR = 'UPDATE_SITE_IP_WHITELIST_ERROR'
export const UPDATE_SITE_FIREWALL = 'UPDATE_SITE_FIREWALL'
export const UPDATE_SITE_FIREWALL_SUCCESS = 'UPDATE_SITE_FIREWALL_SUCCESS'
export const UPDATE_SITE_FIREWALL_ERROR = 'UPDATE_SITE_FIREWALL_ERROR'
export const CREATE_API_KEY = 'CREATE_API_KEY'
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS'
export const CREATE_API_KEY_ERROR = 'CREATE_API_KEY_ERROR'
export const PUBLISH = 'PUBLISH'
export const PUBLISH_SUCCESS = 'PUBLISH_SUCCESS'
export const PUBLISH_ERROR = 'PUBLISH_ERROR'
export const IMPORT_CUSTOM_SSL = 'IMPORT_CUSTOM_SSL'
export const IMPORT_CUSTOM_SSL_SUCCESS = 'IMPORT_CUSTOM_SSL_SUCCESS'
export const IMPORT_CUSTOM_SSL_ERROR = 'IMPORT_CUSTOM_SSL_ERROR'
export const DELETE_CUSTOM_SSL = 'DELETE_CUSTOM_SSL'
export const DELETE_CUSTOM_SSL_SUCCESS = 'DELETE_CUSTOM_SSL_SUCCESS'
export const DELETE_CUSTOM_SSL_ERROR = 'DELETE_CUSTOM_SSL_ERROR'
export const DISTRIBUTION_SET_CUSTOM_SSL = 'DISTRIBUTION_SET_CUSTOM_SSL'
export const DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS = 'DISTRIBUTION_SET_CUSTOM_SSL_SUCCESS'
export const DISTRIBUTION_SET_CUSTOM_SSL_ERROR = 'DISTRIBUTION_SET_CUSTOM_SSL_ERROR'
export const CANCEL_SCRAPE_JOB_ID = 'CANCEL_SCRAPE_JOB_ID'
export const CANCEL_SCRAPE_JOB_ID_SUCCESS = 'CANCEL_SCRAPE_JOB_ID_SUCCESS'
export const CANCEL_SCRAPE_JOB_ID_ERROR = 'CANCEL_SCRAPE_JOB_ID_ERROR'
export const RESET_SITE_PASSWORD = 'RESET_SITE_PASSWORD'
export const RESET_SITE_PASSWORD_SUCCESS = 'RESET_SITE_PASSWORD_SUCCESS'
export const RESET_SITE_PASSWORD_ERROR = 'RESET_SITE_PASSWORD_ERROR'

// users constants
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS'
export const GET_ACCOUNT_USERS_SUCCESS = 'GET_ACCOUNT_USERS_SUCCESS'
export const GET_USER_SITES = 'GET_USER_SITES'
export const GET_USER_SITES_SUCCESS = 'GET_USER_SITES_SUCCESS'
export const GET_SIGNUP_CODE = 'GET_SIGNUP_CODE'
export const GET_SIGNUP_CODE_SUCCESS = 'GET_SIGNUP_CODE_SUCCESS'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const RESET_USER_MFA = 'RESET_USER_MFA'
export const RESET_USER_MFA_SUCCESS = 'RESET_USER_MFA_SUCCESS'
export const RESET_USER_MFA_ERROR = 'RESET_USER_MFA_ERROR'

// billing constants
export const GET_ALL_PLANS = 'GET_ALL_PLANS'
export const GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS'
export const SET_NEW_PLAN = 'SET_NEW_PLAN'
export const SET_NEW_PLAN_SUCCESS = 'SET_NEW_PLAN_SUCCESS'
export const GET_ALL_ADD_ONS = 'GET_ALL_ADD_ONS'
export const GET_ALL_ADD_ONS_SUCCESS = 'GET_ALL_ADD_ONS_SUCCESS'
export const SET_NEW_ADD_ON = 'SET_NEW_ADD_ON'
export const SET_NEW_ADD_ON_SUCCESS = 'SET_NEW_ADD_ON_SUCCESS'
export const GET_USER_ID_BILLING = 'GET_USER_ID_BILLING'
export const GET_USER_ID_BILLING_SUCCESS = 'GET_USER_ID_BILLING_SUCCESS'
export const SET_ID_SUBSCRIPTION = 'SET_ID_SUBSCRIPTION'
export const SET_ID_SUBSCRIPTION_SUCCESS = 'SET_ID_SUBSCRIPTION_SUCCESS'
export const DELETE_PLAN_ID = 'DELETE_PLAN_ID'
export const DELETE_PLAN_FAMILY_BY_ID = 'DELETE_PLAN_FAMILY_BY_ID'
export const EDIT_PLAN_FAMILY_BY_ID = 'EDIT_PLAN_FAMILY_BY_ID'
export const GET_ACCOUNT_ID_BILLING = 'GET_ACCOUNT_ID_BILLING'
export const GET_ACCOUNT_ID_BILLING_SUCCESS = 'GET_ACCOUNT_ID_BILLING_SUCCESS'
export const GET_ALL_PLAN_FAMILIES = 'GET_ALL_PLAN_FAMILIES'
export const GET_ALL_PLAN_FAMILIES_SUCCESS = 'GET_ALL_PLAN_FAMILISUCCESS'
export const SET_NEW_PLAN_FAMILY = 'SET_NEW_PLAN_FAMILY'
export const EDIT_PLAN_BY_ID = 'EDIT_PLAN_BY_ID'
export const GET_ALL_PROMO_CODES = 'GET_ALL_PROMO_CODES'
export const GET_ALL_PROMO_CODES_SUCCESS = 'GET_ALL_PROMO_CODES_SUCCESS'
export const DELETE_PROMO_BY_ID = 'DELETE_PROMO_BY_ID'
export const SET_NEW_PROMO = 'SET_NEW_PROMO'
export const EDIT_PROMO_BY_ID = 'EDIT_PROMO_BY_ID'

// settings
export const GET_DEFAULT_PLANS = 'GET_DEFAULT_PLANS'
export const GET_DEFAULT_PLANS_SUCCESS = 'GET_DEFAULT_PLANS_SUCCESS'
export const UPDATE_DEFAULT_PLANS = 'UPDATE_DEFAULT_PLANS'
export const UPDATE_DEFAULT_PLANS_SUCCESS = 'UPDATE_DEFAULT_PLANS_SUCCESS'
export const UPDATE_DEFAULT_PLANS_ERROR = 'UPDATE_DEFAULT_PLANS_ERROR'

// subscriptions constants
export const GET_ACCOUNT_ID_SUBSCRIPTIONS = 'GET_ACCOUNT_ID_SUBSCRIPTIONS'
export const GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS = 'GET_ACCOUNT_ID_SUBSCRIPTIONS_SUCCESS'
export const UPDATE_ACCOUNT_ID_SUBSCRIPTION = 'UPDATE_ACCOUNT_ID_SUBSCRIPTION'
export const UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS = 'UPDATE_ACCOUNT_ID_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const UPDATE_AUTO_RENEW = 'UPDATE_AUTO_RENEW'
export const UPDATE_AUTO_BILL = 'UPDATE_AUTO_BILL'
export const VALIDATE_SUBSCRIPTION = 'VALIDATE_SUBSCRIPTION'
export const VALIDATE_SUBSCRIPTION_SUCCESS = 'VALIDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_CDN_EDGE_LOCATIONS = 'UPDATE_CDN_EDGE_LOCATIONS'
export const UPDATE_CDN_EDGE_LOCATIONS_SUCCESS = 'UPDATE_CDN_EDGE_LOCATIONS_SUCCESS'
export const UPDATE_CDN_EDGE_LOCATIONS_ERROR = 'UPDATE_CDN_EDGE_LOCATIONS_ERROR'
export const UPDATE_SUBSCRIPTION_MAX_SITES = 'UPDATE_SUBSCRIPTION_MAX_SITES'
export const UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS = 'UPDATE_SUBSCRIPTION_MAX_SITES_SUCCESS'
export const UPDATE_SUBSCRIPTION_MAX_SITES_ERROR = 'UPDATE_SUBSCRIPTION_MAX_SITES_ERROR'
export const UPDATE_SUBSCRIPTION_BY_ID = 'UPDATE_SUBSCRIPTION_BY_ID'
export const UPDATE_SUBSCRIPTION_BY_ID_SUCCESS = 'UPDATE_SUBSCRIPTION_BY_ID_SUCCESS'
export const UPDATE_SUBSCRIPTION_BY_ID_ERROR = 'UPDATE_SUBSCRIPTION_BY_ID_ERROR'

// billing
export const GET_ACCOUNTID_CUSTOMER = 'GET_ACCOUNTID_CUSTOMER'
export const GET_ACCOUNTID_CUSTOMER_SUCCESS = 'GET_ACCOUNTID_CUSTOMER_SUCCESS'
export const SET_ACCOUNTID_CUSTOMER = 'SET_ACCOUNTID_CUSTOMER'
export const DELETE_ACCOUNTID_CUSTOMER = 'DELETE_ACCOUNTID_CUSTOMER'
export const DELETE_ACCOUNTID_CUSTOMER_SUCCESS = 'DELETE_ACCOUNTID_CUSTOMER_SUCCESS'
export const NO_STRIPE = 'NO_STRIPE'
export const NO_CUSTOMER_ID = 'NO_CUSTOMER_ID'
export const CLEAR_BILLING = 'CLEAR_BILLING'

// payments
export const GET_ACCOUNT_ID_CARDS = 'GET_ACCOUNT_ID_CARDS'
export const GET_ACCOUNT_ID_CARDS_SUCCESS = 'GET_ACCOUNT_ID_CARDS_SUCCESS'
export const ACCOUNT_ID_CHARGE_SUBSCRIPTION = 'ACCOUNT_ID_CHARGE_SUBSCRIPTION'
export const ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS = 'ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS'
export const GET_ACCOUNT_ID_INVOICE = 'GET_ACCOUNT_ID_INVOICE'
export const GET_ACCOUNT_ID_INVOICE_SUCCESS = 'GET_ACCOUNT_ID_INVOICE_SUCCESS'
