export const BASE_URL = 'https://api.dev.strattic.com/'
export const ID_TOKEN = window.localStorage.getItem('id_token')

export const SUB_TYPES = {
  FREE: 'Free',
  FREE_TRIAL: 'Free trial'
}

export const BILLING_CYCLE_OPTIONS = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly'
}

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  FUTURE: 'future',
  CANCELLED: 'cancelled'
}

export const SELF_SERVICE_CHANGE_PLAN_DESCRIPTIONS = {
  ALLOWED: 'Allowed',
  BLOCKED: 'Blocked',
  ALLOWED_PLAN_DEFAULT: 'Allowed (Plan default)',
  BLOCKED_PLAN_DEFAULT: 'Blocked (Plan default)'
}

export const MANAGED_BY = {
  ELEMENTOR: 'elementor',
  STRATTIC: 'strattic'
}
