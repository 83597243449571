import { all } from 'redux-saga/effects'
import authSagas from './Auth'
import customDomainSagas from './Domain'
import dataSagas from './Data'
import userSagas from './Users'
import billingSagas from './Billing'
import subscriptions from './Subscriptions'
import sites from './Sites'
import payments from './Payments'
import account from './Account'

export default function * rootSaga (getState) {
  yield all([
    authSagas(),
    customDomainSagas(),
    dataSagas(),
    userSagas(),
    billingSagas(),
    subscriptions(),
    sites(),
    payments(),
    account()
  ])
}
