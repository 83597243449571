import React from 'react'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Input, Layout, Row, Button, Card, Form } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  hideMessage,
  showAuthLoader,
  submitNewPassword
} from 'appRedux/actions/Auth'
import CircularProgress from 'components/CircularProgress/index'

const FormItem = Form.Item
const { Content } = Layout

const SubmitResetPassword = ({ submitNewPassword, loader }) => {
  const handleSubmit = (values) => {
    submitNewPassword(values)
  }

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 }
    }
  }
  return (
    <div>
      <Layout>

        <Content style={{ background: '#fff' }}>
          <Row type='flex' justify='center'>

            <div style={{ background: '#fff', width: '33%' }}>

              <Card style={{ padding: 10, marginTop: '10%' }}>
                <div style={{ textAlign: 'center' }}>
                  <h2>Password Reset</h2>
                  <p>Please enter your new password and the verification code that was sent to you.</p>
                </div>
                <div>
                  <Form onFinish={handleSubmit}>
                    <FormItem
                      {...formItemLayout}
                      name='username'
                      rules={
                        [{
                          type: 'email', message: 'The input is not a valid E-mail!'
                        },
                        {
                          required: true, message: 'Enter a valid email!'
                        }]
                      }
                    >
                      <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='email' placeholder='Email address' />
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      name='code'
                      rules={[
                        {
                          required: true, message: 'Please input your Confirmation Code!'
                        },
                        {
                          pattern: (/[0-9]{6}/),
                          message: 'Confirmation code must be 6 numbers'
                        }
                      ]}
                    >
                      <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='interger' placeholder='Confirmation Code' />
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      name='new_password'
                      rules={[
                        {
                          required: true, message: 'Please enter a password'
                        },
                        {
                          pattern: (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                          message: 'Password must be at least 8 characters long and have numbers, lowercase and uppercase characters.'
                        }
                      ]}
                    >
                      <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='New Password' />
                    </FormItem>

                    <FormItem {...formItemLayout}>
                      <Button type='primary' htmlType='submit' className='login-form-button' style={{ width: '100%' }}>
                        SUBMIT NEW PASSWORD
                      </Button>

                    </FormItem>

                  </Form>
                  {loader &&
                    <div className='gx-loader-view'>
                      <CircularProgress />
                    </div>}
                </div>
              </Card>
            </div>

          </Row>
        </Content>
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage } = auth
  return { loader, alertMessage }
}

export default withRouter(connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  submitNewPassword
})(SubmitResetPassword))
