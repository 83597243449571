import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { API } from 'aws-amplify'

import {
  ACCOUNT_UPDATE_TYPE,
  ACCOUNT_UPDATE_TYPE_SUCCESS,
  ACCOUNT_UPDATE_TYPE_ERROR,

  ACCOUNT_DELETE,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_ERROR,

  UPDATE_ACCOUNT_NOTES,
  UPDATE_ACCOUNT_NOTES_SUCCESS,
  UPDATE_ACCOUNT_NOTES_ERROR
} from 'constants/ActionTypes'
import { showAuthMessage, getAccountIdContact } from '../actions'

async function updateAccountNotesCall (accountId, payload) {
  return API.put('stratticAPI', `accounts/${accountId}/notes`, {
    body: payload
  })
}

async function updateAccountTypeCall (accountId, payload) {
  return API.patch('stratticAPI', `accounts/${accountId}/accountType`, {
    body: payload
  })
}

async function deleteAccountCall (accountId, payload) {
  return API.del('stratticAPI', `accounts/${accountId}`, {})
}

function * updateAccountNotesRequest ({ accountId, payload }) {
  try {
    yield call(updateAccountNotesCall, accountId, payload)
    yield put({ type: UPDATE_ACCOUNT_NOTES_SUCCESS })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: UPDATE_ACCOUNT_NOTES_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * updateAccountTypeRequest ({ accountId, payload }) {
  try {
    const response = yield call(updateAccountTypeCall, accountId, payload)
    yield put({ type: ACCOUNT_UPDATE_TYPE_SUCCESS, response })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: ACCOUNT_UPDATE_TYPE_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * deleteAccountRequest ({ accountId, payload }) {
  try {
    const response = yield call(deleteAccountCall, accountId, payload)
    yield put({ type: ACCOUNT_DELETE_SUCCESS, response })
    yield put(getAccountIdContact(accountId))
  } catch (error) {
    console.error(error)
    yield put({ type: ACCOUNT_DELETE_ERROR })
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchUpdateAccountNotes () {
  yield takeLatest(UPDATE_ACCOUNT_NOTES, updateAccountNotesRequest)
}

export function * watchUpdateAccountType () {
  yield takeLatest(ACCOUNT_UPDATE_TYPE, updateAccountTypeRequest)
}

export function * watchDeleteAccount () {
  yield takeLatest(ACCOUNT_DELETE, deleteAccountRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchUpdateAccountType),
    fork(watchDeleteAccount),
    fork(watchUpdateAccountNotes)
  ])
}
