import {
  all, call, fork, put, takeEvery
} from 'redux-saga/effects'
import { API } from 'aws-amplify'
import {
  GET_ACCOUNT_ID_CARDS, ACCOUNT_ID_CHARGE_SUBSCRIPTION,
  ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS,
  GET_ACCOUNT_ID_INVOICE
} from '../../constants/ActionTypes'
import { showAuthMessage, userSignOut } from '../actions/Auth'
import { getAccountIdCardsSuccess, getAccountIdInvoiceSuccess } from '../actions/Payments'

async function getAccountIdCardsCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}/cards`)
}

async function accountIdChargeSubscriptionCall (accountId) {
  return await API.post('stratticAPI', `accounts/${accountId}/chargeSubscription`)
}

async function getAccountIdInvoiceCall (accountId) {
  let page = 1
  const records = 50
  const data = { results: [] }
  while (true) {
    const response = await API.get('stratticAPI', `accounts/${accountId}/invoices?records=${records}&page=${page}`)
    data.results = data.results.concat(response.results)
    if (!response.hasMore) {
      data.totalCount = response.totalCount
      data.hasMore = false
      return Promise.resolve(data)
    }
    page++
  }
}

function * accountIdChargeSubscriptionRequest ({ accountId }) {
  try {
    yield call(accountIdChargeSubscriptionCall, accountId)
    yield put({ type: ACCOUNT_ID_CHARGE_SUBSCRIPTION_SUCCESS })
    yield put({ type: GET_ACCOUNT_ID_INVOICE, accountId })
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

function * getAccountIdCardsRequest ({ accountId }) {
  try {
    const cards = yield call(getAccountIdCardsCall, accountId)
    yield put(getAccountIdCardsSuccess(cards[0]))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

function * getAccountIdInvoiceRequest ({ accountId }) {
  try {
    const response = yield call(getAccountIdInvoiceCall, accountId)
    yield put(getAccountIdInvoiceSuccess(response))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchGetAccountIdCards () {
  yield takeEvery(GET_ACCOUNT_ID_CARDS, getAccountIdCardsRequest)
}

export function * watchAccountIdChargeSubscription () {
  yield takeEvery(ACCOUNT_ID_CHARGE_SUBSCRIPTION, accountIdChargeSubscriptionRequest)
}
export function * watchGetAccountIdInvoice () {
  yield takeEvery(GET_ACCOUNT_ID_INVOICE, getAccountIdInvoiceRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchGetAccountIdCards),
    fork(watchAccountIdChargeSubscription),
    fork(watchGetAccountIdInvoice)
  ])
}
