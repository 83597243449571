import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  ACCOUNTS_DATA_FETCH_REQUESTED,
  ACCOUNTS_DATA_FETCH_SUCCESS,
  SITES_DATA_FETCH_REQUESTED,
  SITE_DATA_FETCH_SUCCESS,
  SITE_DATA_FETCH_REQUESTED,
  SITE_DATA_FETCH_END,
  SITES_DATA_FETCH_SUCCESS,
  ADD_NEW_ACCOUNT_SUCCESS,
  LOG_SINGLE_ACCOUNT,
  ADD_SITE_REQUESTED,
  ADD_SITE_SUCCESS,
  ADMIN_USER_DATA_REQUESTED,
  ADMIN_USER_DATA_SUCCESS,
  DELETE_SITE,
  DELETE_SITE_SUCCESS,
  GET_ACCOUNT_ID_SITES,
  GET_ACCOUNT_ID_SITES_SUCCESS,
  GET_ACCOUNT_ID_CONTACT,
  GET_ACCOUNT_ID_CONTACT_SUCCESS,
  GET_METRICS_SUCCESS, GET_METRICS,
  ADMIN_MFA_RESET,
  ADMIN_MFA_RESET_SUCCESS,
  UPDATE_ACCOUNT_SUBSCRIPTION_PROPERTIES,
  SHOW_ERROR_CODE
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  alertMessage: null,
  accountsData: {
    result: []
  },
  singleAccount: {},
  sitesData: {
    result: []
  },
  siteData: null,
  siteDataLoading: false,
  singleSite: {},
  adminData: {
    result: []
  },
  accountSites: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_MFA_RESET:
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ADMIN_MFA_RESET_SUCCESS:
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ACCOUNTS_DATA_FETCH_REQUESTED: {
      return {
        ...state,
        loader: true,
        alertMessage: '',
        singleAccount: {}
      }
    }

    case ACCOUNTS_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loader: false,
        accountsData: action.payload
      }
    }

    case GET_ACCOUNT_ID_SITES: {
      return {
        ...state,
        loader: true
      }
    }

    case GET_ACCOUNT_ID_SITES_SUCCESS: {
      return {
        ...state,
        loader: false,
        accountSites: action.payload
      }
    }

    case GET_ACCOUNT_ID_CONTACT: {
      return {
        ...state,
        loader: true
      }
    }

    case GET_ACCOUNT_ID_CONTACT_SUCCESS: {
      return {
        ...state,
        loader: false,
        singleAccount: action.payload
      }
    }

    case UPDATE_ACCOUNT_SUBSCRIPTION_PROPERTIES: {
      return {
        ...state,
        singleAccount: {
          ...state.singleAccount,
          currentSubscription: {
            ...state.singleAccount.currentSubscription,
            ...action.data
          }
        }
      }
    }

    case SITES_DATA_FETCH_REQUESTED: {
      return {
        ...state,
        loader: true,
        alertMessage: ''

      }
    }

    case SITES_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        loader: false,
        sitesData: action.payload
      }
    }

    case SITE_DATA_FETCH_REQUESTED: {
      return {
        ...state,
        siteDataLoading: true,
        alertMessage: ''
      }
    }

    case SITE_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        siteData: action.payload
      }
    }

    case SITE_DATA_FETCH_END: {
      return {
        ...state,
        siteDataLoading: false
      }
    }

    case DELETE_SITE: {
      return {
        ...state,
        loader: true
      }
    }

    case DELETE_SITE_SUCCESS: {
      return {
        ...state,
        loader: false,
        siteData: action.payload
      }
    }

    case LOG_SINGLE_ACCOUNT: {
      return {
        ...state,
        singleAccount: action.payload
      }
    }

    case ADD_NEW_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'ACCOUNT ADDED'

      }
    }

    case ADD_SITE_REQUESTED: {
      return {
        ...state,
        loader: true
      }
    }

    case ADD_SITE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'SITE ADDED'
      }
    }

    case ADMIN_USER_DATA_REQUESTED: {
      return {
        ...state,
        loader: true,
        alertMessage: ''

      }
    }

    case ADMIN_USER_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        adminData: action.payload
      }
    }

    case GET_METRICS: {
      return {
        ...state,
        metricsDate: action.payload.body
      }
    }

    case GET_METRICS_SUCCESS: {
      return {
        ...state,
        loader: false,
        metricsData: action.payload
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        loader: false
      }
    }
    case SHOW_ERROR_CODE: {
      return {
        ...state,
        errorCode: action.payload,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: null,
        loader: false
      }
    }

    default:
      return state
  }
}
