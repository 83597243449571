import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover, Button } from 'antd'
import { userSignOut } from 'appRedux/actions/Auth'

class UserInfo extends Component {
  render () {
    const userMenuOptions = (
      <ul className='gx-user-popover'>
        <li><a href='/changepassword'>Change my Password</a></li>
        <li>
          <a onClick={() => this.props.userSignOut()}>
            Logout
          </a>
        </li>
      </ul>
    )

    return (
      <Popover placement='bottomLeft' content={userMenuOptions} trigger='click'>
        <Button type='primary'>My Account</Button>
      </Popover>
    )
  }
}

export default connect(null, { userSignOut })(UserInfo)
