import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  RESET_REQUESTED,
  SUBMIT_NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  RESET_CODE_SEND,
  SET_COGNITO_2FA_USER,
  SET_NEW_PLAN_FAMILY,
  DELETE_PLAN_FAMILY_BY_ID,
  EDIT_PLAN_FAMILY_BY_ID,
  EDIT_PLAN_BY_ID,
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  SET_NEW_PLAN,
  SET_NEW_PLAN_SUCCESS,
  GET_ALL_ADD_ONS,
  GET_ALL_ADD_ONS_SUCCESS,
  SET_NEW_ADD_ON,
  SET_NEW_ADD_ON_SUCCESS,
  GET_USER_ID_BILLING,
  GET_USER_ID_BILLING_SUCCESS,
  SET_ID_SUBSCRIPTION,
  SET_ID_SUBSCRIPTION_SUCCESS,
  DELETE_PLAN_ID,
  GET_ACCOUNT_ID_BILLING,
  GET_ACCOUNT_ID_BILLING_SUCCESS,
  GET_ALL_PLAN_FAMILIES_SUCCESS,
  GET_ALL_PLAN_FAMILIES,
  GET_ALL_PROMO_CODES,
  SET_NEW_PROMO,
  EDIT_PROMO_BY_ID,
  DELETE_PROMO_BY_ID,
  GET_ALL_PROMO_CODES_SUCCESS,
  SET_ACCOUNTID_CUSTOMER,
  GET_ACCOUNTID_CUSTOMER,
  GET_ACCOUNTID_CUSTOMER_SUCCESS,
  DELETE_ACCOUNTID_CUSTOMER,
  NO_STRIPE,
  DELETE_SITE,
  DELETE_SITE_SUCCESS,
  ADMIN_SET_SELF_MFA,
  ADMIN_SET_SELF_MFA_SUCCESS
} from 'constants/ActionTypes'

const INIT_STATE = {
  loader: false,
  alertMessage: null,
  initURL: '',
  authUser: window.localStorage.getItem('user_id'),
  codeSent: false,
  confirmed: false,
  cognito2faUser: null,
  deleteLoader: false,
  adminSetSelfMfaSuccess: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ADMIN_SET_SELF_MFA: {
      return {
        ...state,
        adminSetSelfMfaSuccess: false
      }
    }
    case ADMIN_SET_SELF_MFA_SUCCESS: {
      return {
        ...state,
        adminSetSelfMfaSuccess: true
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        newUser: action.payload,
        alertMessage: 'USER ADDED'
      }
    }
    case VERIFY_USER: {
      return {
        ...state,
        loader: true
      }
    }

    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'USER VERIFIED'
      }
    }

    case SET_COGNITO_2FA_USER: {
      return {
        ...state,
        cognito2faUser: action.payload
      }
    }

    case RESET_REQUESTED: {
      return {
        ...state,
        loader: false,
        codeSent: true
      }
    }

    case SUBMIT_NEW_PASSWORD: {
      return {
        ...state,
        loader: true
      }
    }

    case NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        confirmed: true
      }
    }

    case RESET_CODE_SEND: {
      return {
        ...state,
        confirmed: false,
        codeSent: false
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        loader: false,
        deleteLoader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: null,
        loader: false
      }
    }
    case DELETE_SITE: {
      return {
        ...state,
        deleteLoader: true
      }
    }
    case DELETE_SITE_SUCCESS: {
      return {
        ...state,
        deleteLoader: false
      }
    }
    case GET_ALL_PLAN_FAMILIES:
    case GET_ALL_PLANS:
    case SET_NEW_PLAN:
    case SET_NEW_PLAN_FAMILY:
    case GET_ALL_ADD_ONS:
    case SET_NEW_ADD_ON:
    case GET_USER_ID_BILLING:
    case SET_ID_SUBSCRIPTION:
    case DELETE_PLAN_ID:
    case DELETE_PLAN_FAMILY_BY_ID:
    case DELETE_PROMO_BY_ID:
    case EDIT_PLAN_FAMILY_BY_ID:
    case EDIT_PLAN_BY_ID:
    case GET_ACCOUNT_ID_BILLING:
    case ON_SHOW_LOADER:
    case GET_ALL_PROMO_CODES:
    case SET_NEW_PROMO:
    case EDIT_PROMO_BY_ID:
    case SET_ACCOUNTID_CUSTOMER:
    case GET_ACCOUNTID_CUSTOMER:
    case DELETE_ACCOUNTID_CUSTOMER:
    {
      return {
        ...state,
        loader: true
      }
    }
    case SET_NEW_PLAN_SUCCESS:
    case GET_USER_ID_BILLING_SUCCESS:
    case SET_ID_SUBSCRIPTION_SUCCESS:
    case GET_ALL_PLANS_SUCCESS:
    case GET_ALL_ADD_ONS_SUCCESS:
    case SET_NEW_ADD_ON_SUCCESS:
    case GET_ACCOUNT_ID_BILLING_SUCCESS:
    case GET_ALL_PLAN_FAMILIES_SUCCESS:
    case GET_ALL_PROMO_CODES_SUCCESS:
    case ON_HIDE_LOADER:
    case GET_ACCOUNTID_CUSTOMER_SUCCESS:
    case NO_STRIPE:
    {
      return {
        ...state,
        loader: false
      }
    }

    default:
      return state
  }
}
