import React from 'react'
import { Route, Switch } from 'react-router-dom'

import asyncComponent from 'appUtil/asyncComponent'

const App = ({ match }) => {
  return (
    <div className='gx-main-content-wrapper'>
      <Switch>
        <Route path={`${match.url}accounts/:accountId/site/:siteId`} component={asyncComponent(() => import('./Accounts/Sites/Singlesite'))} />
        <Route path={`${match.url}accounts/:accountId/users`} component={asyncComponent(() => import('./Accounts/Users'))} />
        <Route path={`${match.url}accounts/:accountId/sites`} component={asyncComponent(() => import('./Accounts/Sites'))} />
        <Route path={`${match.url}accounts/:accountId/billing`} component={asyncComponent(() => import('./Accounts/Billing'))} />
        <Route path={`${match.url}accounts/:accountId/subscriptions`} component={asyncComponent(() => import('./Accounts/Subscriptions'))} />
        <Route path={`${match.url}accounts`} component={asyncComponent(() => import('./Accounts'))} />
        <Route path={`${match.url}sites`} component={asyncComponent(() => import('./Accounts/Sites'))} />
        <Route path={`${match.url}logs`} component={asyncComponent(() => import('./Logs'))} />
        <Route path={`${match.url}allsites`} component={asyncComponent(() => import('./Sites'))} />
        <Route path={`${match.url}admins/add`} component={asyncComponent(() => import('./Admins/Add'))} />
        <Route path={`${match.url}admins`} component={asyncComponent(() => import('./Admins'))} />
        <Route path={`${match.url}roles`} component={asyncComponent(() => import('./Roles'))} />
        <Route path={`${match.url}users/:userId/contactinfo`} component={asyncComponent(() => import('./Users/ContactInfo'))} />
        <Route path={`${match.url}users/:userId/sites`} component={asyncComponent(() => import('./Users/Sites'))} />
        <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))} />
        {/* <Route path={`${match.url}editaccount`} component={asyncComponent(() => import('./Accounts/Editaccount'))} /> */}
        <Route path={`${match.url}site/:siteId/publish`} component={asyncComponent(() => import('./Accounts/Sites/PublishHistory'))} />
        <Route path={`${match.url}site/:siteId/backups`} component={asyncComponent(() => import('./Accounts/Sites/SiteBackups'))} />
        <Route path={`${match.url}site/:siteId/metrics`} component={asyncComponent(() => import('./Accounts/Sites/SiteMetrics'))} />
        <Route path={`${match.url}site/:siteId/crons`} component={asyncComponent(() => import('./Accounts/Sites/SitesCron'))} />
        <Route path={`${match.url}site/:siteId/headers-rules`} component={asyncComponent(() => import('./Accounts/Sites/HeadersRules'))} />
        <Route path={`${match.url}site/:siteId/redirect-rules`} component={asyncComponent(() => import('./Accounts/Sites/RedirectRules'))} />
        <Route path={`${match.url}site/:siteId`} component={asyncComponent(() => import('./Accounts/Sites/Singlesite'))} />
        {/* <Route path={`${match.url}addnewaccount`} component={asyncComponent(() => import('./Accounts/Addaccount'))} /> */}
        <Route path={`${match.url}editaccount`} component={asyncComponent(() => import('./Accounts/Editaccount'))} />
        <Route path={`${match.url}changepassword`} component={asyncComponent(() => import('./ChangePassword'))} />
        <Route path={`${match.url}pricing/families`} component={asyncComponent(() => import('./Plans/Families'))} />
        <Route path={`${match.url}pricing/promos`} component={asyncComponent(() => import('./Plans/Promos'))} />
        <Route path={`${match.url}pricing/addons`} component={asyncComponent(() => import('./AddOns'))} />
        <Route path={`${match.url}pricing/plans`} component={asyncComponent(() => import('./Plans'))} />
        <Route path={`${match.url}plans/settings`} component={asyncComponent(() => import('./Plans/Settings'))} />
        <Route path={`${match.url}sales-tools`} component={asyncComponent(() => import('./SalesTools'))} />
        <Route component={asyncComponent(() => import('./NotFound'))} />
      </Switch>
    </div>
  )
}
export default App
