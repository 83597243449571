/**
 * The key format should be:
 * STRATTIC_API.RESOURCE.EVENT_TYPE
 *
 * The value format should be:
 * STRATTIC_API.EVENT_TYPE.RESOURCE
 */
export const STRATTIC_API = {
  ALL: {
    START: 'STRATTIC_API.START',
    SUCCESS: 'STRATTIC_API.SUCCESS',
    ERROR: 'STRATTIC_API.ERROR'
  },
  PLAN_ARCHIVE: {
    START: 'STRATTIC_API.START.PLAN_ARCHIVE',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_ARCHIVE',
    ERROR: 'STRATTIC_API.ERROR.PLAN_ARCHIVE'
  },
  PLAN_GET_ALL: {
    START: 'STRATTIC_API.START.PLAN_GET_ALL',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_GET_ALL',
    ERROR: 'STRATTIC_API.ERROR.PLAN_GET_ALL'
  },
  SUBSCRIPTION_PATCH: {
    START: 'STRATTIC_API.START.SUBSCRIPTION_PATCH',
    SUCCESS: 'STRATTIC_API.SUCCESS.SUBSCRIPTION_PATCH',
    ERROR: 'STRATTIC_API.ERROR.SUBSCRIPTION_PATCH'
  },
  PLAN_CREATE: {
    START: 'STRATTIC_API.START.PLAN_CREATE',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_CREATE',
    ERROR: 'STRATTIC_API.ERROR.PLAN_CREATE'
  },
  PLAN_UPDATE: {
    START: 'STRATTIC_API.START.PLAN_UPDATE',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_UPDATE',
    ERROR: 'STRATTIC_API.ERROR.PLAN_UPDATE'
  },
  PLAN_DELETE: {
    START: 'STRATTIC_API.START.PLAN_DELETE',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_DELETE',
    ERROR: 'STRATTIC_API.ERROR.PLAN_DELETE'
  },
  PLAN_FAMILIES_GET_ALL: {
    START: 'STRATTIC_API.START.PLAN_FAMILIES_GET_ALL',
    SUCCESS: 'STRATTIC_API.SUCCESS.PLAN_FAMILIES_GET_ALL',
    ERROR: 'STRATTIC_API.ERROR.PLAN_FAMILIES_GET_ALL'
  },
  SITES_CRON_GET_ALL: {
    START: 'STRATTIC_API.START.SITES_CRON_GET_ALL',
    SUCCESS: 'STRATTIC_API.SUCCESS.SITES_CRON_GET_ALL',
    ERROR: 'STRATTIC_API.ERROR.SITES_CRON_GET_ALL'
  },
  SITES_CRON_CREATE: {
    START: 'STRATTIC_API.START.SITES_CRON_CREATE',
    SUCCESS: 'STRATTIC_API.SUCCESS.SITES_CRON_CREATE',
    ERROR: 'STRATTIC_API.ERROR.SITES_CRON_CREATE'
  },
  SITES_CRON_UPDATE: {
    START: 'STRATTIC_API.START.SITES_CRON_UPDATE',
    SUCCESS: 'STRATTIC_API.SUCCESS.SITES_CRON_UPDATE',
    ERROR: 'STRATTIC_API.ERROR.SITES_CRON_UPDATE'
  },
  SITES_CRON_DELETE: {
    START: 'STRATTIC_API.START.SITES_CRON_DELETE',
    SUCCESS: 'STRATTIC_API.SUCCESS.SITES_CRON_DELETE',
    ERROR: 'STRATTIC_API.ERROR.SITES_CRON_DELETE'
  },
  SITES_RESTORE_GET_ALL: {
    START: 'STRATTIC_API.START.SITES_RESTORE_GET_ALL',
    SUCCESS: 'STRATTIC_API.SUCCESS.SITES_RESTORE_GET_ALL',
    ERROR: 'STRATTIC_API.ERROR.SITES_RESTORE_GET_ALL'
  },
  RULES_ENGINE_GET_RULES: {
    START: 'STRATTIC_API.START.RULES_ENGINE_GET_RULES',
    SUCCESS: 'STRATTIC_API.SUCCESS.RULES_ENGINE_GET_RULES',
    ERROR: 'STRATTIC_API.ERROR.RULES_ENGINE_GET_RULES'
  },
  EXTEND_FREE_TRIAL: {
    START: 'STRATTIC_API.START.EXTEND_FREE_TRIAL',
    SUCCESS: 'STRATTIC_API.SUCCESS.EXTEND_FREE_TRIAL',
    ERROR: 'STRATTIC_API.ERROR.EXTEND_FREE_TRIAL'
  },
  ADMIN_GET_CURRENT_USER: {
    START: 'STRATTIC_API.START.ADMIN_GET_CURRENT_USER',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_GET_CURRENT_USER',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_GET_CURRENT_USER'
  },
  ADMIN_GET_ROLES: {
    START: 'STRATTIC_API.START.ADMIN_GET_ROLES',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_GET_ROLES',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_GET_ROLES'
  },
  ADMIN_DELETE_ROLE: {
    START: 'STRATTIC_API.START.ADMIN_DELETE_ROLE',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_DELETE_ROLE',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_DELETE_ROLE'
  },
  ADMIN_CREATE_ROLE: {
    START: 'STRATTIC_API.START.ADMIN_CREATE_ROLE',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_CREATE_ROLE',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_CREATE_ROLE'
  },
  ADMIN_UPDATE_ROLE: {
    START: 'STRATTIC_API.START.ADMIN_UPDATE_ROLE',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_UPDATE_ROLE',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_UPDATE_ROLE'
  },
  PERMISSIONS_GET_ALL: {
    START: 'STRATTIC_API.START.PERMISSIONS_GET_ALL',
    SUCCESS: 'STRATTIC_API.SUCCESS.PERMISSIONS_GET_ALL',
    ERROR: 'STRATTIC_API.ERROR.PERMISSIONS_GET_ALL'
  },
  ADMIN_CHANGE_ROLE: {
    START: 'STRATTIC_API.START.ADMIN_CHANGE_ROLE',
    SUCCESS: 'STRATTIC_API.SUCCESS.ADMIN_CHANGE_ROLE',
    ERROR: 'STRATTIC_API.ERROR.ADMIN_CHANGE_ROLE'
  },
  GET_DEFAULT_PLANS: {
    START: 'STRATTIC_API.START.GET_DEFAULT_PLANS',
    SUCCESS: 'STRATTIC_API.SUCCESS.GET_DEFAULT_PLANS',
    ERROR: 'STRATTIC_API.ERROR.GET_DEFAULT_PLANS'
  },
  INVOICES_FETCH: {
    START: 'STRATTIC_API.START.INVOICES_FETCH',
    SUCCESS: 'STRATTIC_API.SUCCESS.INVOICES_FETCH',
    ERROR: 'STRATTIC_API.ERROR.INVOICES_FETCH'
  },
  CUSTOM_DOMAIN_CERTIFICATE_CREATE: {
    START: 'STRATTIC_API.START.CUSTOM_DOMAIN_CERTIFICATE_CREATE',
    SUCCESS: 'STRATTIC_API.SUCCESS.CUSTOM_DOMAIN_CERTIFICATE_CREATE',
    ERROR: 'STRATTIC_API.ERROR.CUSTOM_DOMAIN_CERTIFICATE_CREATE'
  },
  CUSTOM_DOMAIN_CERTIFICATE_DELETE: {
    START: 'STRATTIC_API.START.CUSTOM_DOMAIN_CERTIFICATE_DELETE',
    SUCCESS: 'STRATTIC_API.SUCCESS.CUSTOM_DOMAIN_CERTIFICATE_DELETE',
    ERROR: 'STRATTIC_API.ERROR.CUSTOM_DOMAIN_CERTIFICATE_DELETE'
  },
  SCRAPE_PAGE: {
    START: 'STRATTIC_API.START.SCRAPE_PAGE',
    SUCCESS: 'STRATTIC_API.SUCCESS.SCRAPE_PAGE',
    ERROR: 'STRATTIC_API.ERROR.SCRAPE_PAGE'
  },
  CREATE_CUSTOM_DOMAIN: {
    START: 'STRATTIC_API.START.CREATE_CUSTOM_DOMAIN',
    SUCCESS: 'STRATTIC_API.SUCCESS.CREATE_CUSTOM_DOMAIN',
    ERROR: 'STRATTIC_API.ERROR.CREATE_CUSTOM_DOMAIN'
  },
  DELETE_CUSTOM_DOMAIN: {
    START: 'STRATTIC_API.START.DELETE_CUSTOM_DOMAIN',
    SUCCESS: 'STRATTIC_API.SUCCESS.DELETE_CUSTOM_DOMAIN',
    ERROR: 'STRATTIC_API.ERROR.DELETE_CUSTOM_DOMAIN'
  }
}

export const WEBSOCKET_EVENTS = {
  SITE: {
    SITE_STATUS_CHANGED: 'site_status_changed'
  }
}
