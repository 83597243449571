import React from 'react'
import { connect } from 'react-redux'

import RequestCodeForm from './requestCodeForm.js'
import WrappedSubmitResetPasswordForm from './submitNewPassword.js'
import Success from './Success.js'

import { MESSAGE_TYPE } from 'appUtil/adminHelpers'
import useAlertMessage from 'src/hooks/useAlertMessage'
import { hideMessage } from 'appRedux/actions/Auth.js'

const ForgotPassword = ({ alertMessage, hideMessage, codeSent, confirmed }) => {
  useAlertMessage(MESSAGE_TYPE.ERROR, alertMessage, hideMessage)

  return (
    <div>
      {!codeSent
        ? <RequestCodeForm />
        : !confirmed
            ? <WrappedSubmitResetPasswordForm />
            : <Success />}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { codeSent, confirmed, alertMessage } = auth
  return { codeSent, confirmed, alertMessage }
}

export default connect(mapStateToProps, { hideMessage })(ForgotPassword)
