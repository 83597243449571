import {
  all, call, fork, put, takeEvery, debounce
} from 'redux-saga/effects'
import { API } from 'aws-amplify'
import PubSub from 'pubsub-js'
import { STRATTIC_API } from 'PubSub/Topics'

import {
  GET_ALL_PLANS,
  SET_NEW_PLAN,
  GET_USER_ID_BILLING,
  SET_ID_SUBSCRIPTION,
  DELETE_PLAN_ID,
  GET_ACCOUNT_ID_BILLING,
  GET_ALL_PLAN_FAMILIES,
  SET_NEW_PLAN_FAMILY,
  DELETE_PLAN_FAMILY_BY_ID,
  EDIT_PLAN_FAMILY_BY_ID,
  EDIT_PLAN_BY_ID,
  GET_ALL_PROMO_CODES,
  GET_ALL_ADD_ONS,
  SET_NEW_ADD_ON,
  DELETE_PROMO_BY_ID,
  SET_NEW_PROMO,
  EDIT_PROMO_BY_ID,
  SET_ACCOUNTID_CUSTOMER,
  GET_ACCOUNTID_CUSTOMER,
  DELETE_ACCOUNTID_CUSTOMER,
  NO_STRIPE,
  GET_DEFAULT_PLANS,
  UPDATE_DEFAULT_PLANS,
  UPDATE_DEFAULT_PLANS_SUCCESS,
  UPDATE_DEFAULT_PLANS_ERROR
} from 'constants/ActionTypes'
import {
  getAllPlansSuccess,
  getUserIdBillingSuccess,
  getUserIdBilling,
  setIdSubscriptionSuccess,
  getAccountIdBillingSuccess,
  getAllPlansFamiliesSuccess,
  getAllPromoCodesSuccess,
  getAllPlansFamilies,
  getAccountIdCustomerSuccess,
  getAccountIdCustomer,
  getAllAddOns,
  getAllAddOnsSuccess,
  setNewAddOnSuccess,
  getDefaultPlansSuccess
} from '../actions/Billing'
import { userSignOut, showAuthMessage } from '../actions/Auth'
import { formatQuery } from 'appUtil/utils'

async function getDefaultPlansCall () {
  return await API.get('stratticAPI', 'default-plans')
}
function * getDefaultPlansRequest () {
  try {
    const response = yield call(getDefaultPlansCall)
    yield put(getDefaultPlansSuccess(response))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

async function updateDefaultPlansCall (payload) {
  return await API.put('stratticAPI', 'default-plans', { body: payload })
}
function * updateDefaultPlansRequest ({ payload }) {
  try {
    yield call(updateDefaultPlansCall, payload)
    yield put({ type: UPDATE_DEFAULT_PLANS_SUCCESS })
  } catch (error) {
    yield put({ type: UPDATE_DEFAULT_PLANS_ERROR })
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

async function getAllPlansCall () {
  return await API.get('stratticAPI', 'plans')
}

async function getAllPlanFamiliesCall () {
  return await API.get('stratticAPI', 'plangroups')
}

async function setNewPlanCall (planObj) {
  return await API.post('stratticAPI', 'plans', { body: planObj })
}

async function setNewPromoCall (payload) {
  return await API.post('stratticAPI', 'promo-codes', { body: payload })
}

async function setNewPlanFamilyCall (planObj) {
  return await API.post('stratticAPI', 'plangroups', { body: planObj })
}

async function getAllAddOnsCall (payload) {
  // If you do not have a payload it means you're trying to load all add-ons. Set a really high limit
  const searchQuery = formatQuery(payload)
  let query = 'add-ons/'
  if (searchQuery && searchQuery !== '') {
    query += `?${searchQuery}`
  } else {
    query = 'add-ons/?records=1000&page=1&byColumn=id&order=desc'
  }
  return await API.get('stratticAPI', query)
}

async function setNewAddOnCall (addOnObj) {
  return await API.post('stratticAPI', 'add-ons', { body: addOnObj })
}

async function getUserIdBillingCall (userId) {
  return await API.get('stratticAPI', `accounts/${userId}/subscriptions`)
}

async function setIdSubscriptionCall (payload) {
  return await API.post('stratticAPI', `accounts/${payload.accountId}/subscriptions`, { body: payload.subscriptions })
}

async function deletePlanIdCall (payload) {
  return await API.del('stratticAPI', `plans/${payload.id}`)
}

async function deletePlanFamilyByIdCall (payload) {
  return await API.del('stratticAPI', `plangroups/${payload}`)
}

async function editPromoByIdCall ({ row, key }) {
  return await API.patch('stratticAPI', `promo-codes/${key}`, { body: row })
}

async function editPlanFamilyByIdCall ({ row, key }) {
  return await API.patch('stratticAPI', `plangroups/${key}`, { body: row })
}

async function editPlanByIdCall ({ row, key }) {
  return await API.patch('stratticAPI', `plans/${key}`, { body: row })
}

async function getAccountIdBillingCall (payload) {
  return await API.get('stratticAPI', `accounts/${payload}/billing`)
}

async function setAccountIdCustomerCall (accountId) {
  return await API.post('stratticAPI', `accounts/${accountId}/customer`)
}

async function getAccountIdCustomerCall (accountId) {
  return await API.get('stratticAPI', `accounts/${accountId}/customer`)
}

async function deleteAccountIdCustomerCall (accountId) {
  return await API.del('stratticAPI', `accounts/${accountId}/customer`)
}

async function getAllPromoCodesCall (payload) {
  const { records, pageNum, order, byColumn, search } = payload
  const byColumnId = byColumn === undefined ? '' : `&byColumn=${byColumn}`
  const orderId = order === undefined ? '' : `&order=${order}`
  const searchQuery = search === undefined ? '' : `&search=${search}`
  const query = `promo-codes/?records=${records}&page=${pageNum}${byColumnId}${orderId}${searchQuery}`
  return await API.get('stratticAPI', query)
}

async function deletePromoByIdCall (payload) {
  return await API.del('stratticAPI', `promo-codes/${payload}`)
}

export function * deletePromoByIdRequest ({ payload }) {
  try {
    yield call(deletePromoByIdCall, payload)
    yield put({ type: 'GET_ALL_PROMO_CODES', payload: { records: 10, pageNum: 1 } })
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getAllPromoCodesRequest ({ payload }) {
  try {
    const promoCodes = yield call(getAllPromoCodesCall, payload)
    yield put(getAllPromoCodesSuccess(promoCodes))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

export function * editPromoByIdRequest ({ payload }) {
  try {
    yield call(editPromoByIdCall, payload)
    yield put({ type: 'GET_ALL_PROMO_CODES', payload: { records: 10, pageNum: 1 } })
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * editPlanByIdRequest ({ payload }) {
  try {
    if (!payload.visitsAmount) {
      payload.visitsAmount = null
    }
    if (!payload.usersAmount) {
      payload.usersAmount = null
    }
    PubSub.publishSync(STRATTIC_API.PLAN_UPDATE.START)
    yield call(editPlanByIdCall, payload)
    PubSub.publishSync(STRATTIC_API.PLAN_UPDATE.SUCCESS, {})
  } catch (error) {
    PubSub.publishSync(STRATTIC_API.PLAN_UPDATE.ERROR, { error })
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * editPlanFamilyByIdRequest ({ payload }) {
  try {
    yield call(editPlanFamilyByIdCall, payload)
    yield put(getAllPlansFamilies())
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * deletePlanIdRequest ({ payload }) {
  try {
    PubSub.publishSync(STRATTIC_API.PLAN_DELETE.START)
    yield call(deletePlanIdCall, payload)
    PubSub.publishSync(STRATTIC_API.PLAN_DELETE.SUCCESS, {})
  } catch (error) {
    PubSub.publishSync(STRATTIC_API.PLAN_DELETE.ERROR, { error })
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * deletePlanFamilyByIdRequest ({ payload }) {
  try {
    yield call(deletePlanFamilyByIdCall, payload)
    yield put(getAllPlansFamilies())
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * setIdSubscriptionRequest ({ payload }) {
  try {
    const subscriptions = yield call(setIdSubscriptionCall, payload)
    yield put(setIdSubscriptionSuccess(subscriptions.result))
    yield put(getUserIdBilling(payload.accountId))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * setNewPlanRequest ({ payload: planObj }) {
  try {
    if (!planObj.visitsAmount) {
      planObj.visitsAmount = null
    }
    if (!planObj.usersAmount) {
      planObj.usersAmount = null
    }
    PubSub.publishSync(STRATTIC_API.PLAN_CREATE.START)
    const plan = yield call(setNewPlanCall, planObj)
    PubSub.publishSync(STRATTIC_API.PLAN_CREATE.SUCCESS, { data: plan })
  } catch (error) {
    PubSub.publishSync(STRATTIC_API.PLAN_CREATE.ERROR, { error })
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * setNewPlanFamilyRequest ({ payload: planObj }) {
  try {
    yield call(setNewPlanFamilyCall, planObj)
    yield put(getAllPlansFamilies())
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * setNewPromoRequest ({ payload }) {
  try {
    yield call(setNewPromoCall, payload)
    yield put({ type: 'GET_ALL_PROMO_CODES', payload: { records: 10, pageNum: 1 } })
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getUserIdBillingRequest ({ payload: userId }) {
  try {
    const userBillingInfo = yield call(getUserIdBillingCall, userId)
    yield put(getUserIdBillingSuccess(userBillingInfo.result))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getAllPlansRequest () {
  try {
    PubSub.publishSync(STRATTIC_API.PLAN_GET_ALL.START)
    const plansList = yield call(getAllPlansCall)
    yield put(getAllPlansSuccess(plansList))
    PubSub.publish(STRATTIC_API.PLAN_GET_ALL.SUCCESS, { data: plansList })
  } catch (error) {
    PubSub.publish(STRATTIC_API.PLAN_GET_ALL.ERROR, { error })
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

export function * getAllPlanFamiliesRequest () {
  try {
    const planFamilies = yield call(getAllPlanFamiliesCall)
    yield put(getAllPlansFamiliesSuccess(planFamilies))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

export function * setAccountIdCustomerRequest ({ payload }) {
  try {
    yield call(setAccountIdCustomerCall, payload)
    yield put(getAccountIdCustomer(payload))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getAccountIdCustomerRequest ({ payload }) {
  try {
    const customer = yield call(getAccountIdCustomerCall, payload)
    yield put(getAccountIdCustomerSuccess(customer))
  } catch (error) {
    if (error.response.data.message === 'no processorCustomerId for this account') {
      yield put({ type: NO_STRIPE })
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

export function * deleteAccountIdCustomerRequest ({ payload }) {
  try {
    yield call(deleteAccountIdCustomerCall, payload)
    yield put({ type: 'DELETE_ACCOUNTID_CUSTOMER_SUCCESS' })
    yield put(getAccountIdCustomer(payload))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getAccountIdBillingRequest ({ payload }) {
  try {
    const accountBilling = yield call(getAccountIdBillingCall, payload)
    yield put(getAccountIdBillingSuccess(accountBilling.result))
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * getAllAddOnsRequest ({ payload }) {
  try {
    const addOnsResult = yield call(getAllAddOnsCall, payload)
    yield put(getAllAddOnsSuccess(addOnsResult))
  } catch (error) {
    if (error.message === 'Unauthorized' || error.message === 'The incoming token has expired' || error === 'No current user' || error.message === 'Refresh Token has expired') {
      console.error(error.message)
      yield put(userSignOut())
    } else {
      console.error(error.response.data.message)
      yield put(showAuthMessage(error.response.data.message))
    }
  }
}

export function * setNewAddOnRequest ({ payload: addOnObj }) {
  try {
    const addOn = yield call(setNewAddOnCall, addOnObj)
    yield put(setNewAddOnSuccess(addOn.result))
    yield put(getAllAddOns())
  } catch (error) {
    console.error(error.response.data.message)
    yield put(showAuthMessage(error.response.data.message))
  }
}

export function * watchGetAccountIdBilling () {
  yield takeEvery(GET_ACCOUNT_ID_BILLING, getAccountIdBillingRequest)
}

export function * watchEditPlanFamilyById () {
  yield takeEvery(EDIT_PLAN_FAMILY_BY_ID, editPlanFamilyByIdRequest)
}

export function * watchEditPlanById () {
  yield takeEvery(EDIT_PLAN_BY_ID, editPlanByIdRequest)
}

export function * watchDeletePlanId () {
  yield takeEvery(DELETE_PLAN_ID, deletePlanIdRequest)
}

export function * watchDeletePlanFamilyById () {
  yield takeEvery(DELETE_PLAN_FAMILY_BY_ID, deletePlanFamilyByIdRequest)
}

export function * watchAssignIdSubscription () {
  yield takeEvery(SET_ID_SUBSCRIPTION, setIdSubscriptionRequest)
}

export function * watchGetUserIdBilling () {
  yield takeEvery(GET_USER_ID_BILLING, getUserIdBillingRequest)
}

export function * watchSetNewPlan () {
  yield takeEvery(SET_NEW_PLAN, setNewPlanRequest)
}

export function * watchSetNewPlanFamily () {
  yield takeEvery(SET_NEW_PLAN_FAMILY, setNewPlanFamilyRequest)
}

export function * watchGetAllPlans () {
  yield takeEvery(GET_ALL_PLANS, getAllPlansRequest)
}

export function * watchGetAllPlanFamilies () {
  yield takeEvery(GET_ALL_PLAN_FAMILIES, getAllPlanFamiliesRequest)
}

export function * watchGetAllPromoCodes () {
  yield debounce(300, GET_ALL_PROMO_CODES, getAllPromoCodesRequest)
}

export function * watchDeletePromoById () {
  yield takeEvery(DELETE_PROMO_BY_ID, deletePromoByIdRequest)
}

export function * watchSetNewPromo () {
  yield takeEvery(SET_NEW_PROMO, setNewPromoRequest)
}

export function * watchEditPromoById () {
  yield takeEvery(EDIT_PROMO_BY_ID, editPromoByIdRequest)
}

export function * watchSetAccountIdCustomer () {
  yield takeEvery(SET_ACCOUNTID_CUSTOMER, setAccountIdCustomerRequest)
}

export function * watchGetAccountIdCustomer () {
  yield takeEvery(GET_ACCOUNTID_CUSTOMER, getAccountIdCustomerRequest)
}

export function * watchDeleteAccountIdCustomer () {
  yield takeEvery(DELETE_ACCOUNTID_CUSTOMER, deleteAccountIdCustomerRequest)
}

export function * watchGetAllAddOns () {
  yield takeEvery(GET_ALL_ADD_ONS, getAllAddOnsRequest)
}

export function * watchSetNewAddOn () {
  yield debounce(300, SET_NEW_ADD_ON, setNewAddOnRequest)
}

export function * watchGetDefaultPlans () {
  yield takeEvery(GET_DEFAULT_PLANS, getDefaultPlansRequest)
}

export function * watchUpdateDefaultPlans () {
  yield takeEvery(UPDATE_DEFAULT_PLANS, updateDefaultPlansRequest)
}

export default function * rootSaga () {
  yield all([
    fork(watchGetAllPlans),
    fork(watchGetAllPlanFamilies),
    fork(watchSetNewPlan),
    fork(watchSetNewPlanFamily),
    fork(watchGetUserIdBilling),
    fork(watchAssignIdSubscription),
    fork(watchDeletePlanId),
    fork(watchDeletePlanFamilyById),
    fork(watchEditPlanFamilyById),
    fork(watchGetAccountIdBilling),
    fork(watchEditPlanById),
    fork(watchGetAllPromoCodes),
    fork(watchDeletePromoById),
    fork(watchSetNewPromo),
    fork(watchEditPromoById),
    fork(watchSetAccountIdCustomer),
    fork(watchGetAccountIdCustomer),
    fork(watchDeleteAccountIdCustomer),
    fork(watchGetAllAddOns),
    fork(watchSetNewAddOn),
    fork(watchGetDefaultPlans),
    fork(watchUpdateDefaultPlans)
  ])
}
