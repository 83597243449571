import {
  GET_ALL_PLANS,
  GET_ALL_PLANS_SUCCESS,
  SET_NEW_PLAN,
  SET_NEW_PLAN_SUCCESS,
  GET_ALL_PLAN_FAMILIES,
  GET_ALL_PLAN_FAMILIES_SUCCESS,
  SET_NEW_PLAN_FAMILY,
  GET_ALL_ADD_ONS,
  GET_ALL_ADD_ONS_SUCCESS,
  SET_NEW_ADD_ON,
  SET_NEW_ADD_ON_SUCCESS,
  GET_USER_ID_BILLING,
  GET_USER_ID_BILLING_SUCCESS,
  SET_ID_SUBSCRIPTION,
  SET_ID_SUBSCRIPTION_SUCCESS,
  DELETE_PLAN_ID,
  DELETE_PLAN_FAMILY_BY_ID,
  EDIT_PLAN_FAMILY_BY_ID,
  GET_ACCOUNT_ID_BILLING,
  GET_ACCOUNT_ID_BILLING_SUCCESS,
  EDIT_PLAN_BY_ID,
  GET_ALL_PROMO_CODES,
  GET_ALL_PROMO_CODES_SUCCESS,
  SET_NEW_PROMO,
  DELETE_PROMO_BY_ID,
  EDIT_PROMO_BY_ID,
  SET_ACCOUNTID_CUSTOMER,
  GET_ACCOUNTID_CUSTOMER,
  GET_ACCOUNTID_CUSTOMER_SUCCESS,
  DELETE_ACCOUNTID_CUSTOMER,
  CLEAR_BILLING,
  GET_DEFAULT_PLANS,
  GET_DEFAULT_PLANS_SUCCESS,
  UPDATE_DEFAULT_PLANS
} from 'constants/ActionTypes'

// Plans
export const getAllPlans = () => ({
  type: GET_ALL_PLANS
})

export const getDefaultPlans = () => ({
  type: GET_DEFAULT_PLANS
})

export const getDefaultPlansSuccess = payload => ({
  type: GET_DEFAULT_PLANS_SUCCESS,
  payload
})

export const updateDefaultPlans = payload => ({
  type: UPDATE_DEFAULT_PLANS,
  payload
})

export const getAllPlansSuccess = plansList => ({
  type: GET_ALL_PLANS_SUCCESS,
  payload: plansList
})

export const setNewPlan = planObj => ({
  type: SET_NEW_PLAN,
  payload: planObj
})

export const setNewPlanSuccess = () => ({
  type: SET_NEW_PLAN_SUCCESS
})

// Plan Families
export const getAllPlansFamilies = () => ({
  type: GET_ALL_PLAN_FAMILIES
})

export const getAllPlansFamiliesSuccess = payload => ({
  type: GET_ALL_PLAN_FAMILIES_SUCCESS,
  payload
})

export const setNewPlanFamily = planObj => ({
  type: SET_NEW_PLAN_FAMILY,
  payload: planObj
})

// Add Ons
export const getAllAddOns = payload => ({
  type: GET_ALL_ADD_ONS,
  payload
})

export const getAllAddOnsSuccess = addOnsResult => ({
  type: GET_ALL_ADD_ONS_SUCCESS,
  payload: addOnsResult
})

export const setNewAddOn = addOnObj => ({
  type: SET_NEW_ADD_ON,
  payload: addOnObj
})

export const setNewAddOnSuccess = () => ({
  type: SET_NEW_ADD_ON_SUCCESS
})

// More billing functions below - need better separation
export const getUserIdBilling = userId => ({
  type: GET_USER_ID_BILLING,
  payload: userId
})

export const getUserIdBillingSuccess = userBillingInfo => ({
  type: GET_USER_ID_BILLING_SUCCESS,
  payload: userBillingInfo
})

export const setIdSubscription = values => ({
  type: SET_ID_SUBSCRIPTION,
  payload: values
})

export const setIdSubscriptionSuccess = values => ({
  type: SET_ID_SUBSCRIPTION_SUCCESS,
  payload: values
})

export const deletePlanId = values => ({
  type: DELETE_PLAN_ID,
  payload: values
})

export const deletePlanFamilyById = values => ({
  type: DELETE_PLAN_FAMILY_BY_ID,
  payload: values
})

export const deletePromoById = values => ({
  type: DELETE_PROMO_BY_ID,
  payload: values
})

export const editPlanFamilyById = values => ({
  type: EDIT_PLAN_FAMILY_BY_ID,
  payload: values
})

export const editPlanById = values => ({
  type: EDIT_PLAN_BY_ID,
  payload: values
})

export const getAccountIdBilling = payload => ({
  type: GET_ACCOUNT_ID_BILLING,
  payload
})

export const getAccountIdBillingSuccess = payload => ({
  type: GET_ACCOUNT_ID_BILLING_SUCCESS,
  payload
})

export const getAllPromoCodes = payload => ({
  type: GET_ALL_PROMO_CODES,
  payload
})

export const getAllPromoCodesSuccess = payload => ({
  type: GET_ALL_PROMO_CODES_SUCCESS,
  payload
})

export const setNewPromo = payload => ({
  type: SET_NEW_PROMO,
  payload
})

export const editPromoById = payload => ({
  type: EDIT_PROMO_BY_ID,
  payload
})

export const setAccountIdCustomer = payload => ({
  type: SET_ACCOUNTID_CUSTOMER,
  payload
})

export const getAccountIdCustomer = payload => ({
  type: GET_ACCOUNTID_CUSTOMER,
  payload
})

export const getAccountIdCustomerSuccess = payload => ({
  type: GET_ACCOUNTID_CUSTOMER_SUCCESS,
  payload
})

export const deleteAccountIdCustomer = payload => ({
  type: DELETE_ACCOUNTID_CUSTOMER,
  payload
})

export const clearBilling = () => ({
  type: CLEAR_BILLING
})
